import React from 'react';
import './AmenitiesSection.css';  // Optional CSS file for custom styling
import { Link } from 'react-router-dom';
const AmenitiesSection = () => {
  const amenities = [
    {
      name: 'High-Speed Wi-Fi',
      description: 'Stay connected during your stay with complimentary high-speed wireless internet, accessible from all corners of the hotel.',
      icon: 'fas fa-wifi',
    },
    {
      name: 'Coffee Maker',
      description: 'Enjoy the convenience of brewing your own coffee or tea in the comfort of your room, any time of day.',
      icon: 'fas fa-coffee',
    },
    {
      name: 'Air Conditioning/Heating',
      description: 'Stay comfortable year-round with individually controlled air conditioning and heating in your room.',
      icon: 'fas fa-thermometer-half',
    },
    {
      name: 'Bathrobes and Slippers',
      description: 'Relax in comfort with plush bathrobes and soft slippers, provided for added luxury during your stay.',
      icon: 'fas fa-bath',
    },
    {
      name: 'Balcony or Terrace',
      description: 'Unwind on your private balcony or terrace with breathtaking views, a perfect retreat after a long day.',
      icon: 'fas fa-tree',
    },
    {
      name: 'Complimentary Bottled Water',
      description: 'Stay refreshed with daily complimentary bottled water provided in every room.',
      icon: 'fas fa-water',
    },
    {
      name: 'Elevators',
      description: "Our  Grand Legacy inn hotels  features modern, high-speed elevators for your convenience. Whether you’re heading to your room or exploring our amenities, our elevators ensure quick and easy access to all floors.",
      icon: 'fas fa-sort',
    },
    {
      name: 'Spa Services',
      description: 'Indulge in relaxing spa treatments, including massages, facials, and more, designed to rejuvenate your body and mind.',
      icon: 'fas fa-spa',
    },
  ];

  return (
    <>
          {/* <!-- Page Banner Start --> */}
          <div
        class="page__banner"
        data-background="./img/banner/page-banner-1.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Amenities</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Amenities
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}
      <div className="amenities-container">
      <h2 className="amenities-title">Amenities at Grand Legacy inn hotels </h2>
      <div className="amenities-grid">
        {amenities.map((amenity, index) => (
          <div key={index} className="amenity-card">
            <div className="amenity-icon">
              <i className={amenity.icon} amenity-icon></i>
            </div>
            <h3 className="amenity-name">{amenity.name}</h3>
            <p className="amenity-description">{amenity.description}</p>
          </div>
        ))}
      </div>
    </div>
    </>
   
  );
};

export default AmenitiesSection;
