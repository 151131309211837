import "./App.css";
import { useState } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function HotelVimanNagarStay() {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const faqs = [
    {
      question:
        "What makes Grand Legacy inn hotels  a top choice among hotels in Viman Nagar for stay?",
      answer:
        "Grand Legacy inn hotels  is a top choice among hotels in Viman Nagar for stay due to our prime location, luxurious accommodations, and exceptional service. Our hotel is centrally situated, offering easy access to major business centers, shopping areas, and local attractions. We provide a blend of modern comfort and traditional hospitality, with amenities such as high-speed Wi-Fi, a fully equipped business center, and an on-site restaurant that caters to a variety of tastes.",
    },
    {
      question:
        "Are there budget-friendly options available at Grand Legacy inn hotels ?",
      answer:
        "Yes, Grand Legacy inn hotels  offers budget-friendly options without compromising on quality. As a 3-star hotel, we provide affordable luxury with well-appointed rooms and essential amenities. Our competitive rates ensure that you receive excellent value for your stay while enjoying top-notch comfort and service.",
    },
    {
      question: "Is Grand Legacy inn hotels  a good option for couples?",
      answer:
        "Absolutely. Grand Legacy inn hotels  is a great choice for couples seeking a romantic getaway. Our elegant rooms and serene environment create the perfect setting for a romantic stay. We also offer special amenities and packages designed to enhance your experience, making it an ideal destination for a memorable couples' retreat.",
    },
    {
      question: "What dining options are available at Grand Legacy inn hotels ?",
      answer:
        "Grand Legacy inn hotels  features an on-site restaurant that offers a diverse menu with both local and international cuisines. Whether you're enjoying a hearty breakfast, a leisurely lunch, or a gourmet dinner, our restaurant provides a range of delicious options to suit all tastes. Room service is also available for those who prefer dining in the comfort of their own room.",
    },
    {
      question: "Does Grand Legacy inn hotels  cater to families?",
      answer:
        "Yes, Grand Legacy inn hotels  is well-suited for families. Our spacious rooms and family-friendly amenities make us a great choice for guests traveling with children. We offer a comfortable and welcoming environment, and our on-site dining options are suitable for all ages. Our staff is also happy to assist with any special requests to ensure a pleasant stay for the whole family.",
    },
    {
      question: "What business facilities does Grand Legacy inn hotels  provide?",
      answer:
        "Grand Legacy inn hotels  is equipped with excellent business facilities for corporate travelers. We offer a fully equipped business center with high-speed internet, printing, and copying services. Additionally, our meeting and conference rooms are available for business events and presentations, ensuring that you have the resources you need for a successful work trip.",
    },
    {
      question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
       answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
   },
    {
      question: "Is parking available at Grand Legacy inn hotels ?",
      answer:
        "Yes, Grand Legacy inn hotels  offers complimentary parking for all guests. Our secure parking area ensures that your vehicle is safe while you enjoy your stay with us.",
    },
    {
      question: "Are pets allowed at Grand Legacy inn hotels ?",
      answer:
        "At this time, Grand Legacy inn hotels  does not allow pets on the property. We strive to maintain a comfortable environment for all guests and ask that you make alternative arrangements for your pets during your stay.",
    },
  ];

  const testimonials = [
    {
      quote:
        "I recently stayed at Grand Legacy inn hotels  and was thoroughly impressed by the entire experience. The hotel’s location in Viman Nagar is incredibly convenient, with easy access to shopping, dining, and business centers. The room was spacious, clean, and elegantly decorated, providing a perfect retreat after a busy day. The staff went above and beyond to ensure that every aspect of my stay was comfortable. The on-site restaurant served delicious meals, making dining a breeze. Whether you're visiting for business or leisure, Grand Legacy inn hotels  is a top-notch choice that combines luxury with affordability.",
      author: "Mr. Ravi Mehta",
    },
    {
      quote:
        "Grand Legacy inn hotels  is an excellent choice for families visiting Viman Nagar. From the moment we arrived, the friendly and attentive staff made us feel welcome. Our room was perfect for our needs—spacious, clean, and well-equipped. The hotel’s location allowed us to explore the local area with ease, and the on-site restaurant was a hit with the whole family. The amenities and comfort provided made our stay enjoyable and hassle-free. If you're looking for a family-friendly hotel in Viman Nagar, Grand Legacy inn hotels  is definitely the place to stay!",
      author: "Mrs & Mr. Nisha and Anil K",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image:"https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

        <title>
          Contact +91 7058638153 | Grand Legacy inn hotels  | Best Hotels in Viman
          Nagar for Stay, Budget, Cheap, Couples & Family Hotels
        </title>
        <meta
          name="description"
          content="Grand Legacy inn hotels  offers the best hotels in Viman Nagar for stay. Explore budget-friendly, cheap hotels, and accommodations for couples and families. Enjoy top-rated hotels with restaurants and family-friendly amenities. Contact us at +91 7058638153 for bookings."
        />
        <meta
          name="keywords"
          content="hotels in Viman Nagar to stay, budget hotels in Viman Nagar, cheap hotels in Viman Nagar, hotel for couples in Viman Nagar, Viman Nagar hotels for stay, hotels in Viman Nagar to stay, best hotels in Viman Nagar with restaurant for family, best stay hotel in Viman Nagar for family"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Grand Legacy inn hotels  - Contact +91 7058638153 | Best Hotels in Viman Nagar for Stay"
        />
        <meta
          property="og:description"
          content="Discover Grand Legacy inn hotels  in Viman Nagar for the best stay. Find budget, cheap, and family-friendly hotels with restaurant options. Contact us at +91 7058638153 for more information and bookings."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotel In viman nagar for stay.jpg"
        />
        <meta
          property="og:url"
          content="https://www.grandlegacyinnhotels.com/viman-nagar-hotels-for-stay"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Hotels in Viman Nagar for Stay</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Hotels in Viman Nagar for Stay
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}
      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Hotel In viman nagar for stay.jpg" className="img-fluid" alt="Hotel In viman nagar for stay" />
              </div>

              <div>
                <h2>
                  Discover the Best Hotels in Viman Nagar for Stay:
                  Grand Legacy Inn Hotels
                </h2>
                <p>
                  When searching for hotels in Viman Nagar for stay, 
                  Grand Legacy Inn Hotels emerges as a top choice for travelers seeking a
                  blend of luxury, convenience, and exceptional service. Nestled
                  in one of Pune’s most vibrant neighborhoods, our hotel
                  provides an unparalleled experience for both business and
                  leisure guests. Here’s why Grand Legacy inn hotels  should be at
                  the top of your list.
                </p>

                <h2>Prime Location in Viman Nagar</h2>
                <p>
                  Grand Legacy inn hotels  is ideally situated in the heart of
                  Viman Nagar, offering guests easy access to a range of local
                  attractions, business centers, shopping districts, and dining
                  options. Whether you’re here for a quick business trip or a
                  relaxing vacation, our central location ensures you are always
                  close to what you need.
                </p>

                <h2>Luxurious Accommodations</h2>
                <p>
                  When choosing hotels in Viman Nagar for stay, comfort and
                  quality are paramount. At Grand Legacy inn hotels , we pride
                  ourselves on providing luxurious accommodations that cater to
                  every need. Our rooms are:
                </p>
                <ul>
                  <li>
                    <strong>Elegant and Spacious:</strong> Designed with modern
                    aesthetics and ample space for relaxation.
                  </li>
                  <li>
                    <strong>Well-Equipped:</strong> Featuring high-speed Wi-Fi,
                    flat-screen TVs, minibars, and comfortable bedding.
                  </li>
                  <li>
                    <strong>Thoughtfully Designed:</strong> With attention to
                    detail to ensure a restful and enjoyable stay.
                  </li>
                </ul>

                <h2>Exceptional Dining Experiences</h2>
                <p>
                  Enjoy an array of dining options without leaving the hotel.
                  Our on-site restaurant offers a diverse menu that includes
                  both local and international cuisines, ensuring there’s
                  something to suit every palate. Start your day with a
                  delicious breakfast or unwind with a gourmet dinner in a
                  stylish setting. Room service is also available for those who
                  prefer dining in the comfort of their own room.
                </p>

                <h2>Business-Friendly Facilities</h2>
                <p>
                  For those visiting for work, Grand Legacy inn hotels  provides a
                  range of facilities designed to meet your business needs. We
                  offer:
                </p>
                <ul>
                  <li>
                    <strong>A Fully Equipped Business Center:</strong> With the
                    latest technology and high-speed internet.
                  </li>
                  <li>
                    <strong>Meeting and Conference Rooms:</strong> Perfect for
                    corporate events, presentations, and meetings.
                  </li>
                  <li>
                    <strong>Support Services:</strong> Including printing,
                    copying, and secretarial services to assist with your
                    professional needs.
                  </li>
                </ul>

                <h2>Outstanding Service and Hospitality</h2>
                <p>
                  When looking for hotels in Viman Nagar for stay, exceptional
                  service can make all the difference. Our dedicated staff is
                  committed to providing personalized service and ensuring that
                  every guest has a memorable experience. From the moment you
                  check in until your departure, our team is here to assist with
                  any requests or special needs you may have.
                </p>

                <h2>Amenities for Relaxation and Recreation</h2>
                <p>
                  Grand Legacy inn hotels  offers a range of amenities designed to
                  enhance your stay:
                </p>
                <ul>
                  <li>
                    <strong>Fitness Center:</strong> Stay active with our
                    well-equipped gym.
                  </li>
                  <li>
                    <strong>Spa Services:</strong> Enjoy a range of relaxing
                    treatments to rejuvenate your body and mind.
                  </li>
                  <li>
                    <strong>Leisure Areas:</strong> Relax in our comfortable
                    lounges or explore local attractions with ease.
                  </li>
                </ul>

                <h2>Affordable Luxury</h2>
                <p>
                  As one of the top 3-star hotels in Viman Nagar, 
                  Grand Legacy Inn Hotels provides a perfect balance of luxury and
                  affordability. Experience high-quality amenities and
                  exceptional service without the premium cost, making it an
                  excellent choice for both budget-conscious and luxury-seeking
                  travelers.
                </p>

                <h2>Hotels in Viman Nagar to Stay</h2>
                <p>
                  If you're searching for hotels in Viman Nagar to stay, look no
                  further than Grand Legacy inn hotels . Our central location
                  ensures easy access to major business centers, shopping areas,
                  and local attractions. Enjoy a stay that combines comfort,
                  convenience, and exceptional service.
                </p>

                <h2>Budget Hotels in Viman Nagar</h2>
                <p>
                  For those seeking budget hotels in Viman Nagar, 
                  Grand Legacy Inn Hotels offers the perfect balance of cost and comfort.
                  As a 3-star hotel, we provide high-quality accommodations and
                  amenities at competitive rates. Experience luxury without
                  breaking the bank and enjoy a stay that exceeds expectations.
                </p>

                <h2>Cheap Hotels in Viman Nagar</h2>
                <p>
                  Finding cheap hotels in Viman Nagar doesn’t mean compromising
                  on quality. Grand Legacy inn hotels  provides an affordable yet
                  luxurious experience. Our rooms are designed for comfort and
                  relaxation, ensuring you get the best value for your money
                  while enjoying premium amenities and service.
                </p>

                <h2>Hotel for Couples in Viman Nagar</h2>
                <p>
                  Looking for a hotel for couples in Viman Nagar? 
                  Grand Legacy Inn Hotels is the perfect choice for a romantic escape. Our
                  elegant rooms, serene atmosphere, and special amenities create
                  an ideal setting for couples. Enjoy a romantic dinner at our
                  in-house restaurant, relax with spa services, and make the
                  most of your time together.
                </p>

                <h2>Viman Nagar Hotels for Stay</h2>
                <p>
                  When it comes to Viman Nagar hotels for stay,  Grand
                  Legacy Inn Hotels offers a comprehensive experience. Our luxurious
                  accommodations, exceptional dining options, and top-notch
                  service make us a standout choice. Whether you're here for
                  business or leisure, we provide everything you need for a
                  memorable stay.
                </p>

                <h2>Best Hotels in Viman Nagar with Restaurant for Family</h2>
                <p>
                  For families seeking the best hotels in Viman Nagar with a
                  restaurant, Grand Legacy inn hotels  is an excellent option. Our
                  on-site restaurant offers a variety of dishes suitable for all
                  tastes, from local favorites to international cuisine. Enjoy a
                  convenient and enjoyable dining experience right at your
                  hotel.
                </p>

                <h2>Best Stay Hotel in Viman Nagar for Family</h2>
                <p>
                  Looking for the best stay hotel in Viman Nagar for family?
                  Grand Legacy inn hotels  is designed with families in mind. Our
                  spacious rooms, family-friendly amenities, and attentive
                  service ensure a comfortable and enjoyable stay for guests of
                  all ages. Relax in our well-equipped rooms, enjoy our dining
                  options, and explore local attractions with ease.
                </p>

                <h2>Book Your Stay at Grand Legacy inn hotels </h2>
                <p>
                  Experience the best of Viman Nagar with a stay at 
                  Grand Legacy Inn Hotels. Our combination of luxury, affordability, and
                  outstanding service makes us the top choice for travelers
                  seeking a memorable stay in Pune.
                </p>
              </div>

              <div>
                <h2>
                  Why Choose Grand Legacy inn hotels  for Hotels in Viman Nagar
                  for Stay?
                </h2>
                <p>
                  When it comes to selecting hotels in Viman Nagar for stay,
                  Grand Legacy inn hotels  stands out as the premier choice for
                  discerning travelers. Here’s why Grand Legacy inn hotels  should
                  be your top pick when visiting Viman Nagar, Pune:
                </p>

                <h2>1. Prime Location</h2>
                <p>
                  Grand Legacy inn hotels  is strategically located in the heart
                  of Viman Nagar, making it an ideal base for exploring Pune.
                  Whether you’re here for business or leisure, our central
                  location provides easy access to major business hubs, shopping
                  centers, and a variety of dining options. Enjoy the
                  convenience of being close to key attractions while
                  experiencing the vibrant energy of Viman Nagar.
                </p>

                <h2>2. Luxurious and Comfortable Accommodations</h2>
                <p>
                  Our hotel offers a range of luxurious accommodations designed
                  to ensure maximum comfort and relaxation. Each room is
                  elegantly furnished and equipped with modern amenities such as
                  high-speed Wi-Fi, flat-screen TVs, and comfortable bedding.
                  Whether you're traveling for work or pleasure, our
                  well-appointed rooms offer a tranquil retreat after a busy
                  day.
                </p>

                <h2>3. Exceptional Dining Options</h2>
                <p>
                  At Grand Legacy inn hotels , we understand the importance of
                  great food. Our on-site restaurant provides a diverse menu
                  featuring both local and international cuisines, ensuring a
                  delightful dining experience. Start your day with a delicious
                  breakfast, enjoy a leisurely lunch, or unwind with a gourmet
                  dinner. We also offer room service for those who prefer to
                  dine in the comfort of their room.
                </p>

                <h2>4. Comprehensive Business Facilities</h2>
                <p>
                  For business travelers, Grand Legacy inn hotels  offers
                  top-notch facilities to support your professional needs. Our
                  business center is equipped with the latest technology and
                  high-speed internet, while our meeting and conference rooms
                  are ideal for hosting corporate events. Our attentive staff is
                  also available to assist with any business-related requests,
                  ensuring a productive stay.
                </p>

                <h2>5. Family-Friendly Environment</h2>
                <p>
                  Grand Legacy inn hotels  is well-suited for families. Our
                  spacious rooms and family-friendly amenities cater to guests
                  of all ages. The hotel’s location allows easy access to local
                  attractions, and our on-site dining options are suitable for
                  children and adults alike. We strive to create a welcoming
                  environment where families can enjoy their stay in Pune.
                </p>

                <h2>6. Affordable Luxury</h2>
                <p>
                  As one of the top 3-star hotels in Viman Nagar,
                  Grand Legacy Inn Hotels offers a perfect blend of luxury and
                  affordability. Enjoy high-quality amenities and exceptional
                  service at competitive rates, making it a great value for your
                  money. Experience the comfort and elegance of a luxury hotel
                  without the premium cost.
                </p>

                <h2>7. Outstanding Service and Hospitality</h2>
                <p>
                  At Grand Legacy inn hotels , we are committed to providing
                  outstanding service and personalized hospitality. From the
                  moment you arrive, our friendly and professional staff is
                  dedicated to ensuring your stay is enjoyable and stress-free.
                  We take pride in offering attentive service that meets your
                  every need.
                </p>

                <h2>8. Amenities for Relaxation and Wellness</h2>
                <p>
                  Our hotel is designed with your relaxation in mind. Enjoy our
                  well-equipped fitness center to stay active, or unwind with a
                  visit to our spa services. Additionally, our comfortable
                  lounges provide a serene environment to relax and rejuvenate.
                </p>
              </div>

              <div className="">
                <h2>
                  FAQs for Hotel in Viman Nagar, Pune - Grand Legacy inn hotels 
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

              {/* ======================== */}
              <div className="">
                <h2>
                  Testimonials for Hotel in Viman Nagar Pune -  Grand
                  Legacy Inn Hotels: -{" "}
                </h2>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-slide">
                      <blockquote>
                        <p>{testimonial.quote}</p>
                        <footer>— {testimonial.author}</footer>
                      </blockquote>
                    </div>
                  ))}
                </Slider>
              </div>

              <div className=" contact-section">
                <h2>Hotels in Viman Nagar for Stay Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========================== */}
    </>
  );
}
