import { useState,React } from "react";
import './App.css';
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
export default function HotelNearVimanNagar() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs =[
        {
            question: "What makes Grand Legacy inn hotels  a top choice among hotels near Viman Nagar?",
            answer: "Grand Legacy inn hotels  is ideally located just minutes away from Viman Nagar, offering guests a perfect blend of convenience and comfort. Our hotel stands out due to its luxurious accommodations, exceptional service, and proximity to major business hubs, shopping centers, and dining options in Viman Nagar. We provide an ideal base for exploring the area while enjoying a peaceful retreat."
          },
          {
            question: "Are there any special amenities for couples at Grand Legacy inn hotels  ?",
            answer: "Yes, Grand Legacy inn hotels  is a couple-friendly hotel that offers a romantic and comfortable environment for couples. Our elegantly designed rooms, serene atmosphere, and thoughtful amenities create a perfect setting for a romantic getaway. We also offer special packages and services to enhance your stay, ensuring a memorable experience for you and your partner."
          },
          {
            question: "Can I book a room at Grand Legacy inn hotels  on an hourly basis?",
            answer: "Yes, Grand Legacy inn hotels  offers hourly basis bookings to accommodate short-term stays. This option is ideal for travelers who need a comfortable and private space for just a few hours. Please contact our reservations team to inquire about availability and rates for hourly stays."
          },
          {
            question: "What dining options are available at Grand Legacy inn hotels  ?",
            answer: "Grand Legacy inn hotels  features an on-site restaurant that offers a diverse menu, including both local and international cuisines. Whether you're looking for a hearty breakfast, a leisurely lunch, or a gourmet dinner, our restaurant has something to suit every taste. We also provide room service for those who prefer to dine in the comfort of their own room."
          },
          {
            question: "Is Grand Legacy inn hotels  suitable for unmarried couples?",
            answer: "Yes, Grand Legacy inn hotels  welcomes unmarried couples and provides a comfortable and private setting for all guests. We are committed to offering a welcoming environment where you can enjoy your stay with complete privacy and comfort. Our staff is dedicated to ensuring a pleasant and respectful experience for everyone."
          },
          {
            question: "How close is Grand Legacy inn hotels  to Viman Nagar?",
            answer: "Grand Legacy inn hotels  is located just a short distance from Viman Nagar, making it a convenient choice for those who want to be close to the area’s attractions, business centers, and dining options. Our proximity to Viman Nagar allows guests to easily explore the vibrant neighborhood while enjoying the comfort of our hotel."
          },
          {
            question: "What types of rooms are available at Grand Legacy inn hotels  ?",
            answer: "Grand Legacy inn hotels  offers a variety of room types to suit different needs, including standard rooms, deluxe rooms, and suites. All rooms are elegantly furnished and equipped with modern amenities such as high-speed Wi-Fi, flat-screen TVs, and comfortable bedding, ensuring a relaxing and enjoyable stay."
          }
    ]
    const testimonials = [
        {
          quote: "I recently stayed at Grand Legacy inn hotels  during a business trip to Pune, and I couldn't have been more pleased with my choice. The hotel’s proximity to Viman Nagar was perfect, allowing me to easily access local business hubs and dining options. The room was immaculate and well-furnished, providing a comfortable retreat after a long day. The on-site restaurant served fantastic meals, and the staff was incredibly accommodating. Whether you're in town for business or leisure, this hotel offers both convenience and luxury. Highly recommended!",
          author: "Mrs. Anita Sharma"
        },
        {
          quote: "My partner and I recently spent a weekend at Grand Legacy inn hotels  , and it was the ideal choice for our romantic escape near Viman Nagar. The hotel’s location was perfect for exploring the vibrant neighborhood while offering a peaceful and relaxing environment. Our room was beautifully decorated and provided all the comforts we could ask for. The dining experience at the hotel was exceptional, with a variety of delicious dishes to choose from. The staff was warm and attentive, making our stay truly memorable. If you're looking for a couple-friendly hotel near Viman Nagar, this is the place to stay!",
          author: "Mr. Rohan and Priya Patel"
        }
      ];

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
      };

      const hotelImages = [
        {
          place: "Hotel-Images/Hotel In Viman Nagar.jpg",
          text: "Hotel In Viman Nagar",
          path:'hotel-in-viman-nagar'
        },
        {
          place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
          text: "Hotels in Viman Nagar for Stay",
          path:'viman-nagar-hotels-for-stay'
        },
        {
          place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
          text: "Lodge in Viman Nagar Pune",
          path:'lodge-in-viman-nagar-pune'
        },
        {
          place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
          text: "Hotels Near Viman Nagar Airport Pune",
          path:'hotels-near-viman-nagar-airport-pune'
        },
        {
          place: "Hotel-Images/Pune Airport Hotel.jpg",
          text: "Pune Airport Hotel",
          path:'pune-airport-hotel'
        },
        {
          place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
          text: "Hotel in Wadgaon Sheri",
          path:'hotel-wadgaon-sheri'
        },
        {
          place: "Hotel-Images/Online hotel booking in pune.jpg",
          text: "Online Hotel Booking in Pune",
          path:'online-hotel-booking-pune'
        },
        {
          place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
          text: "Hotel For Stay in Lohegaon Airport",
          path:'hotel-for-stay-lohegaon-airport'
        }
    
      
      ];
    

      const schemaData = {
        "@context": "https://schema.org",
        "@type": "Hotel",
        "name": "Grand Legacy inn hotels ",
        "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
          "addressLocality": "Pune,Viman Nagar",
          "addressRegion": "MH",
          "postalCode": "411014",
          "addressCountry": "IN"
        },
        "telephone": "+91 7058638153",
        "image": "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
        "starRating": {
          "@type": "Rating",
          "bestRating": "5",
          "ratingValue": "3",
          "worstRating": "1"
        },
       
        "sameAs": [
          "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
          "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
          "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
          "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
          "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
          "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
          "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
          "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
          "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
          "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
          "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
          "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
        ]
      };
    
    return(
        <>
            <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
            <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>

        <title>
           Contact +91 7058638153 | Grand Legacy inn hotels  | Hotels Near Viman Nagar: Couple Friendly, Hourly Basis, Best with Restaurant
        </title>
        <meta name="description" content="Grand Legacy inn hotels  offers top hotels near Viman Nagar. Find couple-friendly options, hotels available on an hourly basis, and the best accommodations with restaurants. Contact us at +91 7058638153 for bookings and more information." />
        <meta name="keywords" content="couple friendly hotels in Viman Nagar, hotel in Viman Nagar, hourly basis hotels in Viman Nagar, hotels in Viman Nagar for unmarried couples, best hotels in Viman Nagar with restaurant, hotels in Viman Nagar for stay" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Grand Legacy inn hotels  - Contact +91 7058638153 | Hotels Near Viman Nagar: Couple Friendly, Hourly Basis, Best with Restaurant" />
        <meta property="og:description" content="Discover Grand Legacy inn hotels  near Viman Nagar. Explore couple-friendly hotels, hourly basis options, and the best hotels with restaurants. Contact +91 7058638153 for more details and bookings." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotels Near viman nagar.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Hotels Near Viman Nagar</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Hotels Near Viman Nagar
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Hotels Near viman nagar.jpg" className="img-fluid" alt="Hotels Near viman nagar" />
              </div>
              <h2>Your Premier Choice Among Hotels Near Viman Nagar</h2>
              <p>
                If you're searching for hotels near Viman Nagar, Grand
                Legacy Inn Hotels stands out as the top choice for travelers seeking
                comfort, convenience, and exceptional service. Located just a
                stone's throw away from the vibrant Viman Nagar area in Pune,
                our hotel offers an ideal blend of luxury and accessibility.
                Here’s why Grand Legacy inn hotels  is your best option when
                looking for hotels near Viman Nagar.
              </p>

              <h2>Prime Location Near Viman Nagar</h2>
              <p>
                Grand Legacy inn hotels  is strategically positioned near Viman
                Nagar, offering guests the perfect balance between proximity and
                tranquility. Our location provides easy access to the bustling
                business districts, shopping centers, and diverse dining options
                that Viman Nagar is known for. Whether you're visiting for
                business or pleasure, our hotel’s proximity to Viman Nagar
                ensures that you can experience the best of the area while
                enjoying a peaceful retreat.
              </p>

              <h2>Luxurious Accommodations</h2>
              <p>
                When choosing among hotels near Viman Nagar, comfort is key. At
                Hotel Grand Legacy, we offer a range of luxurious accommodations
                designed to make your stay as comfortable as possible. Our rooms
                are elegantly furnished and equipped with modern amenities,
                including:
              </p>
              <ul>
                <li>High-Speed Wi-Fi: Stay connected with ease.</li>
                <li>Flat-Screen TVs: Enjoy your favorite shows and movies.</li>
                <li>
                  Comfortable Bedding: Relax and rejuvenate in plush,
                  high-quality linens.
                </li>
              </ul>

              <h2>Exceptional Dining Experience</h2>
              <p>
                One of the advantages of staying at Grand Legacy inn hotels  is
                our on-site dining options. Our restaurant offers a diverse menu
                featuring both local and international cuisines, ensuring a
                delightful culinary experience. Enjoy a hearty breakfast, a
                leisurely lunch, or a gourmet dinner without having to leave the
                hotel. Room service is also available for those who prefer to
                dine in the privacy of their room.
              </p>

              <h2>Comprehensive Business Facilities</h2>
              <p>
                For business travelers seeking hotels near Viman Nagar,
                Grand Legacy Inn Hotels provides excellent facilities to support your
                professional needs. Our business center is fully equipped with
                the latest technology and high-speed internet, and our meeting
                and conference rooms are ideal for corporate events and
                presentations. Our dedicated staff is always on hand to assist
                with any business-related requirements.
              </p>

              <h2>Family-Friendly Atmosphere</h2>
              <p>
                Grand Legacy inn hotels  is also an excellent choice for families.
                Our spacious rooms and family-friendly amenities make it easy
                for guests of all ages to enjoy their stay. The hotel’s location
                near Viman Nagar allows families to explore local attractions
                and enjoy nearby dining options, while our on-site facilities
                ensure a comfortable and enjoyable experience for everyone.
              </p>

              <h2>Affordable Luxury</h2>
              <p>
                When looking for hotels near Viman Nagar, Grand Legacy inn hotels 
                offers a perfect blend of luxury and affordability. As a top
                3-star hotel, we provide high-quality accommodations and
                exceptional service at competitive rates. Experience the
                elegance and comfort of a luxury hotel without the premium cost.
              </p>

              <h2>Outstanding Service and Hospitality</h2>
              <p>
                At Grand Legacy inn hotels  , our commitment to outstanding service
                and hospitality sets us apart. From the moment you arrive, our
                friendly and professional staff is dedicated to ensuring that
                your stay is both enjoyable and stress-free. We pride ourselves
                on providing personalized service that meets your every need.
              </p>

              <h2>Amenities for Relaxation and Wellness</h2>
              <p>
                To enhance your stay, Grand Legacy inn hotels  offers a range of
                amenities designed for relaxation and wellness. Take advantage
                of our well-equipped fitness center, enjoy a soothing spa
                treatment, or relax in our comfortable lounges and outdoor
                spaces. Our facilities are designed to help you unwind and
                rejuvenate during your stay.
              </p>

              <h2>
                Explore Grand Legacy inn hotels  : Your Premier Choice for Hotels
                in Viman Nagar
              </h2>
              <p>
                When searching for hotels in Viman Nagar for stay,
                Grand Legacy Inn Hotels stands out as a top choice. Our hotel offers a
                range of accommodations and amenities designed to meet the
                diverse needs of travelers. Whether you're looking for a
                couple-friendly hotel in Viman Nagar, a place to stay on an
                hourly basis, or a hotel with an exceptional restaurant, Grand Legacy Inn Hotels
                 has something for everyone.
              </p>

              <h2>Couple-Friendly Hotels in Viman Nagar</h2>
              <p>
                Looking for a couple-friendly hotel in Viman Nagar? 
                Grand Legacy Inn Hotels provides a romantic and comfortable environment
                perfect for couples. Our elegantly designed rooms and serene
                atmosphere make it an ideal choice for a romantic getaway. Enjoy
                a private and luxurious stay with thoughtful amenities and
                excellent service tailored to enhance your experience.
              </p>

              <h2>Hotel in Viman Nagar: Comfort and Convenience</h2>
              <p>
                As a top hotel in Viman Nagar, Grand Legacy inn hotels  combines
                comfort with convenience. Located in the heart of Viman Nagar,
                our hotel offers easy access to local attractions, shopping
                areas, and dining options. Our well-appointed rooms and modern
                amenities ensure a pleasant stay, whether you’re visiting for
                business or leisure.
              </p>

              <h2>Hourly Basis Hotels in Viman Nagar</h2>
              <p>
                If you're in need of hourly basis hotels in Viman Nagar, 
                Grand Legacy Inn Hotels offers flexible accommodation options. Ideal
                for travelers who need a short-term stay, our hourly booking
                service allows you to enjoy a comfortable and private space for
                just a few hours. Contact us to inquire about availability and
                rates for hourly stays.
              </p>

              <h2>Hotels in Viman Nagar for Unmarried Couples</h2>
              <p>
                Grand Legacy inn hotels  welcomes all guests, including unmarried
                couples. We understand the diverse needs of modern travelers and
                provide a comfortable and private setting for your stay. Our
                hotel is committed to offering a welcoming environment where you
                can enjoy your time together with the utmost privacy and
                comfort.
              </p>

              <h2>Best Hotels in Viman Nagar with Restaurant</h2>
              <p>
                For those seeking the best hotels in Viman Nagar with a
                restaurant, Grand Legacy inn hotels  delivers an exceptional
                dining experience. Our on-site restaurant offers a diverse menu
                featuring both local and international cuisines. Enjoy a
                delicious meal without leaving the hotel, with options for
                breakfast, lunch, and dinner. Room service is also available for
                added convenience.
              </p>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- couple friendly hotels in viman nagar </p>
                      <p>- Hotel in Vimannagar</p>
                      <p>- hourly basis hotels in viman nagar </p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Hotels in Viman Nagar for Unmarried Couples</p>
                      <p>- Best Hotels in Viman Nagar with Restaurant </p>
                      <p>- Hotels in Viman Nagar for stay</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div>
                <h2>
                  Why Choose Grand Legacy inn hotels  for Your Stay in Viman
                  Nagar, Pune?
                </h2>
                <p>
                  When searching for hotels near Viman Nagar in Pune,
                  Grand Legacy Inn Hotels offers a distinctive blend of luxury,
                  convenience, and exceptional service. Here’s why our hotel
                  stands out as the premier choice for your stay:
                </p>

                <h2>Prime Location with Easy Access</h2>
                <p>
                  Grand Legacy inn hotels  is ideally located just a short
                  distance from Viman Nagar, placing you in close proximity to
                  Pune’s key business districts, shopping areas, and dining
                  options. Our strategic location ensures that you can easily
                  explore the vibrant local scene while enjoying the tranquility
                  and comfort of our hotel.
                </p>

                <h2>Elegant and Comfortable Accommodations</h2>
                <p>
                  We understand that comfort is essential when choosing hotels
                  near Viman Nagar. Our rooms are elegantly designed with modern
                  amenities to provide a relaxing retreat. Enjoy spacious
                  accommodations featuring:
                </p>
                <ul>
                  <li>High-Speed Wi-Fi: Stay connected with ease.</li>
                  <li>
                    Flat-Screen TVs: Relax with your favorite entertainment.
                  </li>
                  <li>
                    Comfortable Bedding: Experience a restful night’s sleep.
                  </li>
                </ul>

                <h2>Exceptional Dining Experience</h2>
                <p>
                  At Grand Legacy inn hotels  , we believe that great food is a key
                  part of a memorable stay. Our on-site restaurant offers a
                  diverse menu with both local and international dishes,
                  catering to all tastes. Whether you’re enjoying a hearty
                  breakfast, a casual lunch, or a gourmet dinner, our restaurant
                  provides a delightful dining experience right within the
                  hotel. Room service is also available for those who prefer to
                  dine in.
                </p>

                <h2>Ideal for Couples</h2>
                <p>
                  Looking for a couple-friendly hotel near Viman Nagar?
                  Grand Legacy Inn Hotels provides a romantic and intimate setting
                  perfect for couples. Our thoughtfully designed rooms and
                  serene atmosphere create an ideal environment for a romantic
                  getaway or special occasion. We also offer special packages
                  and amenities to enhance your experience.
                </p>

                <h2>Flexible Hourly Stays</h2>
                <p>
                  For those needing hourly basis bookings, Grand
                  Legacy Inn Hotels offers flexible accommodation options. This is ideal
                  for travelers who need a short-term stay or a private space
                  for just a few hours. Contact us to inquire about availability
                  and rates for hourly stays.
                </p>

                <h2>Family-Friendly Environment</h2>
                <p>
                  Grand Legacy inn hotels  is also well-suited for families. Our
                  spacious rooms and family-friendly amenities ensure that
                  guests of all ages can enjoy a comfortable stay. With our
                  convenient location near Viman Nagar, families can easily
                  explore local attractions and enjoy nearby dining options.
                </p>

                <h2>Top-Notch Business Facilities</h2>
                <p>
                  For business travelers seeking hotels near Viman Nagar, 
                   Grand Legacy Inn Hotels provides excellent facilities to support your
                  professional needs. Our business center is equipped with the
                  latest technology and high-speed internet, and our meeting
                  rooms are perfect for corporate events and conferences. Our
                  dedicated staff is always ready to assist with any
                  business-related requirements.
                </p>

                <h2>Affordable Luxury</h2>
                <p>
                  As one of the best 3-star hotels near Viman Nagar,
                  Grand Legacy Inn Hotels offers a perfect balance of luxury and
                  affordability. Enjoy high-quality accommodations and
                  exceptional service at competitive rates, making it a great
                  value for your money. Experience the elegance of a luxury
                  hotel without the premium cost.
                </p>

                <h2>Outstanding Service and Hospitality</h2>
                <p>
                  At Grand Legacy inn hotels  , we are committed to providing
                  outstanding service and hospitality. Our friendly and
                  professional staff is dedicated to ensuring that every aspect
                  of your stay is enjoyable and stress-free. We pride ourselves
                  on our personalized service that meets your needs and exceeds
                  your expectations.
                </p>

                <h2>Amenities for Relaxation and Wellness</h2>
                <p>
                  To enhance your stay, Grand Legacy inn hotels  offers a range of
                  amenities designed for relaxation and wellness. Enjoy our
                  well-equipped fitness center, unwind with spa services, or
                  relax in our comfortable lounges and outdoor spaces. We strive
                  to create a rejuvenating experience for all our guests.
                </p>
              </div>


              <div className="">
                  <h2>
                    FAQs for Hotels Near Viman Nagar , Pune - Grand Legacy inn hotels 
                  </h2>
                  {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className={`faq-question ${
                          activeIndex === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {index + 1}. {faq.question}
                      </div>
                      {activeIndex === index && (
                        <div className="faq-answer">{faq.answer}</div>
                      )}
                    </div>
                  ))}
                </div>


                <div className="">
                  <h2>
                    Testimonials for Hotel in Viman Nagar Pune - Grand
                    Legacy Inn Hotels: -{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                {/* ================= */}
                <div className=" contact-section">
                  <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                  <p>
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong>Mobile No:</strong>{" "}
                    <a href="tel:+917058638153">+91 7058638153</a>
                  </p>
                  <p>
                    <strong>Email Id:</strong>{" "}
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                     reservation@grandlegacyinnhotels.com
                    </a>
                  </p>
                </div>
              </div>
        
            
            <div className="col-12 col-md-4">
            <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      
    </>
  );
}
