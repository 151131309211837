import React, { useState } from "react";
import { useRef } from 'react';
import { Link } from "react-router-dom";
import "./App.css";
import "./CSS/all.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/magnific-popup.css";
import "./CSS/meanmenu.min.css";
import "./CSS/nice-select.css";
import "./CSS/swiper-bundle.min.css";
import "./sass/style.css";

const Header = () => {

  const navbarCollapseRef = useRef(null);

  const handleNavItemClick = () => {
    if (navbarCollapseRef.current.classList.contains('show')) {
      navbarCollapseRef.current.classList.remove('show');
    }
  };
  return (
    <div className="header__sticky">
      <div className="bg-dark header1">
        <div class="container row text-center pt-2 pb-2">
          <div className="col-12 col-lg-3 ">
            <a href="tel:+91 7058638153" className="text-white fw-bold">
              <i class="bi bi-telephone-fill me-2"></i>
              <a href="tel:+91 7058638153">+91 7058638153</a>
            </a>
          </div>
          <div className="col-12 col-lg-4 ">
            <a
              href="  mailto:reservation@grandlegacyinnhotels.com"
              className="text-white fw-bold"
            >
              <i class="bi bi-envelope me-2"></i>
              <a href="  mailto:reservation@grandlegacyinnhotels.com">
                reservation@grandlegacyinnhotels.com
              </a>
            </a>
          </div>
        </div>
      </div>
  
<nav className="navbar navbar-expand-lg bg-white">
      <div className="container-fluid mycontainer">
        <a className="navbar-brand" href="/">
          <div className="header__area-menubar-left-logo logo text-center  header-box1">
            <Link to="/">
              <div className="logo">
                <Link to="/"  className="mytext">GRAND LEGACY INN</Link><br></br>
                <Link to="/" className="mytext1">HOTELS</Link>
              </div>
             
            </Link>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse header-box2"
          id="navbarSupportedContent"
          ref={navbarCollapseRef}
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link active list" onClick={handleNavItemClick}>
                Home
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                to="/about"
                className="nav-link active list dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About
              </Link>

              <ul className="dropdown-menu">
                <li className="dropdown-item">
                  <Link to="/about" className="nav-link active list" onClick={handleNavItemClick}>
                    About
                  </Link>
                </li>
                <li className="dropdown-item">
                  <Link to="/terms" className="nav-link active list" onClick={handleNavItemClick}>
                    Terms & Conditions
                  </Link>
                </li>
                <li className="dropdown-item">
                  <Link to="/faq" className="nav-link active list" onClick={handleNavItemClick}>
                    FAQs
                  </Link>
                </li>
                <li className="dropdown-item">
                  <Link to="/policy" className="nav-link active list" onClick={handleNavItemClick}>
                    Privacy & Policy
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link to="/room-style" className="nav-link active list" onClick={handleNavItemClick}>
                Room
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/service" className="nav-link active list" onClick={handleNavItemClick}>
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/facilities" className="nav-link active list" onClick={handleNavItemClick}>
                Facilities
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/amenities" className="nav-link active list" onClick={handleNavItemClick}>
                Amenities
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/restaurants" className="nav-link active list" onClick={handleNavItemClick}>
                Restaurants
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/gallery" className="nav-link active list" onClick={handleNavItemClick}>
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/tariff-plan-card" className="nav-link active list" onClick={handleNavItemClick}>
                Tariff Plan
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link active list" onClick={handleNavItemClick}>
                Contact
              </Link>
            </li>
            <li>
              <div className="header__area-menubar-right-box">
                <div className="header__area-menubar-right-box-btn">
                  <Link to="/BookingForm" className="nav-link active">
                    <a className="theme-btn border" href="./BookingForm.js">
                      Book Now
                    </a>
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    </div>
  );
};

export default Header;
