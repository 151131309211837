import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
export default function FAQ() {
   
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the accordion if it's already open
    } else {
      setActiveIndex(index); // Open the clicked accordion
    }
  };
  const faqs = [
    {
      question: 'What time is check-in and check-out?',
      answer:
        'If you need an early check-in or late check-out, please contact the front desk in advance. Availability is subject to room availability and may incur additional charges.',
    },
    {
      question: 'Do you offer airport transfers?',
      answer:
        'Yes, we offer airport transfer services. Please contact our concierge or front desk to arrange transportation. Charges may apply.',
    },
    {
      question: 'Is Wi-Fi available at the hotel?',
      answer:
        'Yes, we provide complimentary high-speed Wi-Fi throughout the hotel, including guest rooms and common areas.',
    },
    {
      question: 'Are pets allowed at the hotel?',
      answer:
        'We are a pet-friendly hotel. Please inform us in advance if you will be bringing a pet, and check our pet policy for any additional fees or requirements.',
    },
    {
      question: 'What are the dining options available at the hotel?',
      answer:
        'Our hotel features a variety of dining options, including a restaurant which serves breakfast, lunch, and dinner. We also have a cafe offering light snacks and beverages. Room service is available 24/7.',
    },
    {
      question: 'Is parking available?',
      answer:
        'Yes, we offer parking options. Please inquire at the front desk for details on availability and any associated fees.',
    },
    {
      question: 'Do you have a fitness centre?',
      answer:
        'Yes, our fitness centre is equipped with cardio machines, free weights, and more. It is available to guests 24/7.',
    },
    {
      question: 'Can I request a late check-out?',
      answer:
        'Late check-out may be available upon request. Please contact the front desk to check availability. Additional fees may apply.',
    },
    {
      question: 'What is your cancellation policy?',
      answer:
        'Our cancellation policy varies depending on the rate type and booking conditions. Please review the terms and conditions at the time of booking or contact our reservations team for specific details.',
    },
    {
      question: 'Do you offer meeting and event facilities?',
      answer:
        'Yes, we have meeting and event spaces available for conferences, weddings, and other special occasions. Please contact our events team for more information and to make arrangements.',
    },
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact:+91 7058638153 | Grand Legacy inn hotels  |Service | Viman Nagar
          Hotel Booking and Stay Options| Hotel Near Me Pune Airport | Hotels
          Near Kharadi | Hotel in Koregaon Park | Best Hotels in Pune for
          Couples
        </title>
        <meta
          name="description"
          content="Book the best hotels in Viman Nagar, Pune for your stay. Find couple-friendly hotels, business hotels, 3-star and 4-star hotels near Pune International Airport. Call +91 7058638153 for bookings."
        />
        <meta
          name="keywords"
          content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, Business hotels in Viman Nagar, Best hotels in Viman Nagar Pune, Couple-friendly hotels in Viman Nagar, 3-star hotels in Viman Nagar, Hotels near Pune International Airport, 3-star hotels near Pune airport, Hotels near Pune domestic airport, 4-star hotels near Pune airport, Best hourly hotels near Pune International Airport"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="+91 7058638153 | Grand Legacy inn hotels  |Service| Viman Nagar Hotel Booking | Hotel Near Me Pune Airport
         Hotels Near Kharadi"
        />
        <meta
          property="og:description"
          content="Book your stay at Grand Legacy inn hotels , one of the best hotels in Viman Nagar. We offer couple-friendly and business hotel rooms, as well as 3-star and 4-star hotel options near Pune Airport. Call +91 7058638153."
        />
        <meta
          property="og:image"
          content="http://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg"
        />
        <meta property="og:url" content="http://www.grandlegacyinnhotels.com/faq" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-1.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>FAQs</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Frequently Asked Questions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}
       {/* =========================== faq section start ====================== */}
 


<div className="container  section">
        <div className="faq-container">
          <h3 className="pb-5 text-center fw-bold">FAQs for Grand Legacy inn hotels </h3>
          <div className="faq-grid">
            {faqs.map((faq, index) => (
              <div className="accordion-item accordian-bg" key={index}>
                <div
                  className={`accordion-header ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  {faq.question}
                  <span className="accordion-icon">
                    {activeIndex === index ? "-" : "+"}
                  </span>
                </div>
                <div
                  className="accordion-content"
                  style={{
                    maxHeight: activeIndex === index ? "200px" : "0",
                    overflow: "hidden",
                    transition: "max-height 0.3s ease",
                  }}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


  {/* ===================== faq section end ====================== */}
	
    </>
  );
}
