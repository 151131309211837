import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function PuneAirportHotel() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question:
        "What is the distance between Grand Legacy inn hotels    and Pune Airport?",
      answer:
        "Grand Legacy inn hotels    is conveniently located just a short drive from Pune Airport (Lohegaon Airport), approximately 5 kilometers away. Our prime location ensures quick and easy access to the airport, making it an ideal choice for travelers seeking a Pune Airport hotel.",
    },
    {
      question: "Does Grand Legacy inn hotels    offer airport shuttle services?",
      answer:
        "Yes, Grand Legacy inn hotels    provides airport shuttle services for our guests. We offer both complimentary and paid shuttle options depending on your booking and needs. Please contact our front desk or reservations team to arrange airport transfers during your stay.",
    },
    {
      question:
        "What types of rooms are available at Grand Legacy inn hotels   ?",
      answer:
        "Grand Legacy inn hotels    offers a variety of room types to cater to different needs, including Deluxe Rooms, Executive Rooms, and Suites. All rooms are designed for maximum comfort and include modern amenities such as high-speed Wi-Fi, flat-screen TVs, and premium bedding.",
    },
    {
      question:
        "Can I book a room at Grand Legacy inn hotels    for just a few hours?",
      answer:
        "Yes, Grand Legacy inn hotels    provides flexible booking options, including hourly stays. This option is perfect for travelers with layovers or those needing a short rest before continuing their journey. Please contact our reservations team for more details on hourly bookings.",
    },
    {
      question:
        "What amenities and facilities are available at Grand Legacy inn hotels   ?",
      answer:
        "At Grand Legacy inn hotels   , we offer a range of amenities and facilities to ensure a comfortable stay:\n• On-Site Restaurant: Enjoy a variety of local and international dishes.\n• Business Center: Equipped with facilities for business travelers.\n• Meeting Rooms: Ideal for conferences and professional meetings.\n• Fitness Center: Stay active with our modern gym.\n• Room Service: Available for dining in the comfort of your room.\n• High-Speed Wi-Fi: Accessible throughout the hotel.",
    },
    {
      question:
        "Are there any dining options available at Grand Legacy inn hotels   ?",
      answer:
        "Yes, Grand Legacy inn hotels    features an on-site restaurant that serves a range of cuisines including local specialties and international dishes. We also offer room service for guests who prefer to dine in the privacy of their rooms.",
    },
    {
      question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
       answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
   },
    {
      question:
        "Does Grand Legacy inn hotels    offer any special services for business travelers?",
      answer:
        "Yes, Grand Legacy inn hotels    caters to business travelers with a range of services including a fully equipped business center, meeting rooms, high-speed internet, and printing and copying facilities. We aim to provide everything you need for a productive stay.",
    },
    {
      question: "Is Grand Legacy inn hotels    a pet-friendly hotel?",
      answer:
        "Currently, Grand Legacy inn hotels    does not accept pets. We aim to provide a comfortable environment for all guests, and therefore, we maintain a pet-free policy. Please contact us directly if you have any specific questions or requirements related to pets.",
    },
  ];

  const testimonials = [
    {
      quote:
        "I had a fantastic experience staying at Grand Legacy inn hotels    before my early morning flight from Pune Airport. The location is incredibly convenient—just a short drive away, which made catching my flight stress-free. The room was spacious, clean, and comfortable, with all the modern amenities I needed. The on-site restaurant served delicious meals, and the staff were exceptionally friendly and helpful. The added bonus of their airport shuttle service was a huge plus. I highly recommend this hotel for anyone needing a comfortable and convenient stay near Pune Airport.",
      author: "Mr. Sanjay Patel",
    },
    {
      quote:
        "I recently stayed at Grand Legacy inn hotels    for a business trip and was thoroughly impressed by the level of service and comfort provided. The hotel is very close to Pune Airport, which was perfect for my travel schedule. The rooms were well-maintained and had a modern feel, making it a great place to unwind after a long day. I was particularly impressed by the professionalism and warmth of the staff. They went out of their way to ensure my stay was pleasant, including arranging a late check-out to accommodate my flight. If you're looking for a reliable and high-quality hotel near Pune Airport, this is the place to stay!",
      author: "Mrs. Rita Sharma",
    },
  ];

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact +91 7058638153| Grand Legacy inn hotels | Pune Airport Hotel:
          Cheap, Budget, Luxury, and Best Options
        </title>
        <meta
          name="description"
          content="Discover Grand Legacy inn hotels , your top choice for hotels near Pune Airport. We offer a range of options including cheap, budget, and luxury hotels. Find the best hotels near Lohegaon Airport and close to Pune Domestic Airport. Contact us at +91 7058638153 for more information and bookings."
        />
        <meta
          name="keywords"
          content="Pune Airport Hotel, Hotels Near Pune Airport, Hotel Booking in Pune Near Airport, Hotels near Lohegaon Airport Pune, Cheap hotels near Pune Airport, Best hotels in Pune near Airport, Budget Hotels Near Pune Airport, Near Pune Airport hotels, Pune near airport hotel, Grand Legacy inn hotels , Good Hotels near Pune Airport, Airport hotel Pune, Budget hotels in Pune Near Airport, Hotels Near Pune Domestic Airport, Hotels Close to Pune Airport, Luxury Hotels Near Pune Airport"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Grand Legacy inn hotels  - Contact +91 7058638153 | Pune Airport Hotel: Cheap, Budget, Luxury, and Best Options"
        />
        <meta
          property="og:description"
          content="Find the best hotel options near Pune Airport with Grand Legacy inn hotels . From cheap and budget-friendly to luxurious stays, we offer a range of hotels near Lohegaon Airport and Pune Domestic Airport. Contact +91 7058638153 for bookings and inquiries."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/Pune Airport Hotel.jpg"
        />
        <meta
          property="og:url"
          content="https://www.grandlegacyinnhotels.com/pune-airport-hotel"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Pune Airport Hotel</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Pune Airport Hotel
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Pune Airport Hotel.jpg" className="img-fluid" alt="Pune Airport Hotel" />
              </div>

              <h3>
                Discover the Ultimate Comfort at Grand Legacy inn hotels  : Your
                Premier Pune Airport Hotel
              </h3>

              <p>
                When traveling to Pune, convenience and comfort are key to a
                stress-free experience. Grand Legacy inn hotels  offers the
                perfect solution for those seeking a top-notch Pune Airport
                hotel. Here’s why our hotel is the ideal choice for travelers
                arriving or departing from Pune Airport:
              </p>

              <h3>Unbeatable Location Near Pune Airport</h3>
              <p>
                Grand Legacy inn hotels  is strategically located just a short
                drive from Pune Airport, providing unmatched convenience for
                travelers. Our prime location ensures that you can reach the
                airport quickly, whether you are catching a flight or arriving
                late at night. This proximity reduces travel time and allows you
                to relax and settle in with minimal hassle.
              </p>

              <h3>Elegant and Comfortable Accommodations</h3>
              <p>
                Our hotel offers a range of elegantly designed rooms and suites
                to suit various preferences and needs. As a leading Pune Airport
                hotel, we focus on providing:
              </p>
              <ul>
                <li>
                  <strong>Spacious Rooms:</strong> Enjoy generous space and a
                  tranquil environment designed for relaxation.
                </li>
                <li>
                  <strong>Modern Amenities:</strong> Each room is equipped with
                  high-speed Wi-Fi, flat-screen TVs, air conditioning, and
                  premium bedding to ensure your comfort.
                </li>
                <li>
                  <strong>Elegant Decor:</strong> Our stylish decor creates a
                  welcoming atmosphere, enhancing your overall experience.
                </li>
              </ul>

              <h3>Affordable Luxury</h3>
              <p>
                At Grand Legacy inn hotels  , we believe that luxury doesn’t have
                to come with a hefty price tag. We offer competitive rates that
                provide excellent value for money. Experience the comforts of a
                high-quality hotel without exceeding your budget, making us a
                preferred choice for both business and leisure travelers.
              </p>

              <h3>Excellent Dining Options</h3>
              <p>
                Our on-site restaurant caters to a variety of tastes, offering
                delicious local and international cuisine. Whether you’re
                starting your day with a hearty breakfast, enjoying a relaxed
                lunch, or unwinding with dinner, our restaurant provides a range
                of dining options. For added convenience, room service is
                available if you prefer to dine in the comfort of your room.
              </p>

              <h3>Business-Friendly Facilities</h3>
              <p>
                For business travelers, Grand Legacy inn hotels  provides an array
                of services to meet your professional needs:
              </p>
              <ul>
                <li>
                  <strong>Business Center:</strong> Equipped with essential
                  facilities to support your work requirements.
                </li>
                <li>
                  <strong>Meeting Rooms:</strong> Ideal for conferences and
                  business meetings, with professional services to assist with
                  your events.
                </li>
                <li>
                  <strong>High-Speed Internet:</strong> Stay connected with
                  reliable and fast Wi-Fi throughout the hotel.
                </li>
              </ul>

              <h3>Exceptional Customer Service</h3>
              <p>
                Our commitment to exceptional customer service sets us apart as
                one of the best Pune Airport hotels. Our friendly and
                professional staff are dedicated to ensuring a pleasant and
                memorable stay. From the moment you arrive until your departure,
                we are here to assist with any needs or requests you may have.
              </p>

              <h3>Relaxation and Recreation</h3>
              <p>
                Despite being close to the airport, Grand Legacy inn hotels 
                offers a serene environment where you can unwind and recharge.
                Enjoy our fitness center, comfortable lounge areas, and other
                amenities designed to help you relax after a long journey.
              </p>

              <h3>Flexible Booking Options</h3>
              <p>
                We offer flexible booking options, including hourly stays, to
                accommodate your specific needs. Whether you need a short-term
                stay or a comfortable place to rest during a layover, our hotel
                provides the flexibility you require.
              </p>

              <h3>Convenient Access to Local Attractions</h3>
              <p>
                While our proximity to Pune Airport is a significant advantage,
                Grand Legacy inn hotels  also provides easy access to local
                attractions, shopping centers, and dining options. Explore
                Pune’s vibrant culture and enjoy various attractions, all within
                a short distance from our hotel.
              </p>

              <h3>Book Your Stay at Grand Legacy inn hotels  </h3>
              <p>
                Choosing Grand Legacy inn hotels  as your Pune Airport hotel
                ensures a blend of convenience, comfort, and exceptional
                service. Whether you are visiting for business or leisure, our
                hotel offers everything you need for a pleasant and stress-free
                stay.
              </p>

              <h3>Perfect Location Close to Pune Airport</h3>
              <p>
                Grand Legacy inn hotels  boasts a prime location just minutes from
                Pune Airport, also known as Lohegaon Airport. Our strategic
                position makes us one of the best hotels near Lohegaon Airport
                Pune, providing travelers with quick and easy access to their
                flights. Whether you’re looking for hotels near Pune Airport or
                near Pune Airport hotels, our location is ideal for minimizing
                travel time.
              </p>

              <h3>Affordable and Budget-Friendly Options</h3>
              <p>
                For those seeking cheap hotels near Pune Airport, 
                Grand Legacy Inn Hotels offers competitive rates without compromising on
                comfort. We provide budget hotels near Pune Airport that cater
                to travelers looking for high-quality accommodations at
                affordable prices. Enjoy a cost-effective stay with all the
                amenities you need for a comfortable experience.
              </p>

              <h3>Seamless Hotel Booking Experience</h3>
              <p>
                Booking your stay at Grand Legacy inn hotels  is easy and
                efficient. Our straightforward online reservation system makes
                hotel booking in Pune near the airport a breeze. Whether you
                need a short stay or an extended visit, securing your room is
                quick and hassle-free.
              </p>

              <h3>On-Site Dining and Additional Amenities</h3>
              <p>
                Our on-site restaurant offers a diverse menu, from local dishes
                to international favorites, catering to all tastes. Additional
                amenities include:
              </p>
              <ul>
                <li>
                  <strong>Room Service:</strong> For dining in the comfort of
                  your room.
                </li>
                <li>
                  <strong>Business Center:</strong> Equipped with facilities to
                  support your work needs.
                </li>
                <li>
                  <strong>Meeting Rooms:</strong> Perfect for business meetings
                  and conferences.
                </li>
              </ul>

              <h3>Flexible Booking Options</h3>
              <p>
                Understanding that travel plans can vary, we offer flexible
                booking options, including hourly stays. This flexibility is
                ideal for travelers with layovers or those needing a brief
                respite before continuing their journey. Our accommodations are
                designed to meet diverse needs and schedules.
              </p>

              <h3>Explore the Vibrant City of Pune</h3>
              <p>
                While our location near Pune Airport offers convenience,
                Grand Legacy Inn Hotels also provides easy access to local attractions,
                shopping, and dining options. Make the most of your stay by
                exploring Pune’s rich culture and vibrant atmosphere.
              </p>

              <h3>Why Grand Legacy inn hotels  Stands Out</h3>
              <p>
                Choosing Grand Legacy inn hotels  means opting for a blend of
                luxury and affordability. As a leading airport hotel in Pune, we
                offer a top-notch experience for both leisure and business
                travelers. Our hotel is renowned for combining excellent
                service, comfortable accommodations, and a prime location.
              </p>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p> - Pune Airport Hotel </p>
                      <p> - Hotels Near Pune Airport</p>
                      <p>- Hotels near Lohegaon Airport Pune</p>
                      <p>- best hotels in pune near airport </p>
                      <p> - Near Pune airport hotels</p>
                      <p> - Grand Legacy inn hotels  </p>
                      <p> - Airport hotel pune</p>
                      <p> - Hotels Near Pune Domestic Airport</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p> - Luxury Hotels Near Pune Airport </p>
                      <p>- Hotel Booking in Pune Near Airport</p>
                      <p>- Cheap hotels near pune airport </p>
                      <p>- Budget Hotels Near Pune Airport </p>
                      <p>- Pune near airport hotel, Hotel </p>
                      <p>- Good Hotels near Pune Airport </p>
                      <p>- Budget hotels in Pune Near Airport </p>
                      <p>- Hotels Close to Pune Airport </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels  for Your Pune Airport Hotel
                Needs?
              </h2>

              <p>
                When traveling to Pune, convenience, comfort, and value are
                paramount. Grand Legacy inn hotels  stands out as the premier
                choice for a Pune Airport Hotel. Here’s why our hotel is the
                perfect fit for your stay near Pune Airport:
              </p>

              <h3>Prime Location</h3>
              <p>
                Grand Legacy inn hotels  is strategically located just minutes
                away from Pune Airport (Lohegaon Airport). Our central location
                ensures that you can easily access your flight with minimal
                travel time, making us an ideal choice for travelers seeking
                proximity to the airport. Whether you’re catching an early
                morning flight or arriving late at night, our location offers
                unparalleled convenience.
              </p>

              <h3>Superior Comfort and Modern Amenities</h3>
              <p>
                We pride ourselves on providing a comfortable and luxurious
                stay. As one of the best Pune Airport hotels, our rooms are
                designed with your relaxation in mind. Enjoy:
              </p>
              <ul>
                <li>
                  <strong>Spacious Accommodations:</strong> Our rooms are
                  elegantly furnished, offering ample space for relaxation and
                  work.
                </li>
                <li>
                  <strong>High-Speed Internet:</strong> Stay connected with
                  reliable Wi-Fi available throughout the hotel.
                </li>
                <li>
                  <strong>Modern Amenities:</strong> Each room is equipped with
                  flat-screen TVs, premium bedding, and air conditioning to
                  ensure a pleasant stay.
                </li>
              </ul>

              <h3>Affordable Rates and Value</h3>
              <p>
                Finding budget hotels in Pune near the airport can be
                challenging, but Grand Legacy inn hotels  offers competitive rates
                without compromising on quality. We provide excellent value for
                your money with our affordable prices, ensuring that you get the
                best experience at a cost-effective rate.
              </p>

              <h3>Exceptional On-Site Dining</h3>
              <p>
                Our on-site restaurant offers a range of dining options to suit
                all tastes. From local delicacies to international cuisine, you
                can enjoy a delicious meal without leaving the hotel.
                Additionally, we provide room service for those who prefer to
                dine in the comfort of their own room.
              </p>

              <h3>Comprehensive Business Facilities</h3>
              <p>
                For business travelers, Grand Legacy inn hotels  offers a range of
                services to support your work needs:
              </p>
              <ul>
                <li>
                  <strong>Business Center:</strong> Equipped with essential
                  facilities for printing, copying, and more.
                </li>
                <li>
                  <strong>Meeting Rooms:</strong> Ideal for conferences,
                  meetings, and other professional gatherings.
                </li>
                <li>
                  <strong>High-Speed Internet:</strong> Ensures that you stay
                  connected and productive during your stay.
                </li>
              </ul>

              <h3>Flexible Booking Options</h3>
              <p>
                We understand that travel plans can be unpredictable. That’s why
                we offer flexible booking options, including hourly stays, to
                accommodate various schedules and needs. Whether you’re in need
                of a brief respite or a longer stay, we’ve got you covered.
              </p>

              <h3>Friendly and Attentive Service</h3>
              <p>
                At Grand Legacy inn hotels  , we believe that exceptional service
                is key to a memorable stay. Our dedicated staff is always ready
                to assist with your needs, from arranging airport shuttles to
                providing local travel information. We strive to make your stay
                as comfortable and enjoyable as possible.
              </p>

              <h3>Explore Pune with Ease</h3>
              <p>
                While our location near Pune Airport is ideal for transit, it
                also provides easy access to Pune’s attractions, shopping, and
                dining options. Take advantage of your stay to explore the
                vibrant city and experience its rich culture and cuisine.
              </p>

              <div className="">
                <h2>
                  FAQs for Hotel in Viman Nagar, Pune - Grand Legacy inn hotels 
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

              {/* ======================== */}
              <div className="">
                <h2>
                  Testimonials for Hotel in Viman Nagar Pune - Grand
                  Legacy Inn Hotels: -{" "}
                </h2>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-slide">
                      <blockquote>
                        <p>{testimonial.quote}</p>
                        <footer>— {testimonial.author}</footer>
                      </blockquote>
                    </div>
                  ))}
                </Slider>
              </div>

              <div className=" contact-section">
                <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>

              {/* ================= */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
