import React from "react";
import './App.css';
import { Link } from "react-router-dom";

export default function ServicesSection() {
  const blogPosts = [
    {
      title: "24/7 Front Desk",
      icon: "fa-clock",
      description:
        "Our friendly front desk staff is available around the clock to assist you with any inquiries or requests.",
    },
    {
      title: "Concierge Service",
      icon: "fa-user-tie",
      description:
        "Our concierge is here to help with recommendations, bookings, and personalized assistance to enhance your stay.",
    },
    {
      title: "Room Service",
      icon: "fa-concierge-bell",
      description:
        "Enjoy a variety of dining options with our in-room service, available at your convenience.",
    },
    {
      title: "Housekeeping",
      icon: "fa-broom",
      description:
        "Our housekeeping team ensures your room is clean and comfortable throughout your stay.",
    },
    {
      title: "Laundry and Dry Cleaning",
      icon: "fa-tshirt",
      description:
        "We offer laundry and dry cleaning services to keep your wardrobe fresh and clean.",
    },
    {
      title: "Business Centre",
      icon: "fa-briefcase",
      description:
        "Our business centre is equipped with all the necessary amenities for your professional needs.",
    },
    {
      title: "Dining Options",
      icon: "fa-utensils",
      description:
        "Explore a range of dining options, including our in-house restaurant and room service.",
    },
    {
      title: "Event Spaces",
      icon: "fa-building",
      description:
        "Our hotel offers versatile event spaces for meetings, conferences, and celebrations.",
    },
    {
      title: "Transportation Services",
      icon: "fa-taxi",
      description:
        "We provide transportation services to help you get around conveniently.",
    },
    {
      title: "Complimentary Wi-Fi",
      icon: "fa-wifi",
      description:
        "Stay connected with complimentary high-speed Wi-Fi throughout the hotel.",
    },
    {
      title: "Valet Parking",
      icon: "fa-car",
      description: "Enjoy the convenience of valet parking during your stay.",
    },
    {
      title: "Pet-Friendly Accommodations",
      icon: "fa-paw",
      description:
        "We welcome pets and offer amenities to make their stay comfortable.",
    },
    {
      title: "Swimming Pool",
      icon: "fa-swimming-pool",
      description: "Relax and unwind in our outdoor swimming pool.",
    },
    {
      title: "24/7 Security",
      icon: "fa-shield-alt",
      description:
        "Our hotel provides 24/7 security to ensure your safety and peace of mind.",
    },
  ];



  
    const blogs = [
      {
        text:'Best Hotels in Pune for couples',
        imgSrc: "./Hotel-Images/Best Hotels in Pune for couples.jpg",
        title:"Pune, a vibrant city known for its blend of tradition and modernity, offers numerous romantic getaways for couples. Whether you're celebrating a special occasion or just looking for a serene escape, finding the perfect hotel can set the stage for an unforgettable experience. Among the top choices for couples seeking a memorable stay, Grand Legacy inn hotels  stands out as one of the best hotels in Pune for couples.",
        link:'best-hotels-in-pune-for-couples'
      },
      {
        text:'Hotel In Kharadi',
        imgSrc: "./Hotel-Images/Hotel In Kharadi.jpg",
        title:
          "When it comes to finding a top-notch hotel in Kharadi, Grand Legacy inn hotels  stands out as the premier choice for discerning travelers. Located in one of Pune’s most dynamic business and residential areas, our hotel combines luxury, convenience, and exceptional service to ensure a memorable stay.",
        link:'hotels-in-kharadi',
      },
      {
        text:'Luxury Hotel on Pune Nagar Road',
        imgSrc: "./Hotel-Images/Luxury Hotel on Pune Nagar Road.jpg",
        title:
         "When it comes to finding a luxurious and comfortable stay in Pune,Grand Legacy inn hotels  stands out as a premier choice along Pune Nagar Road. This upscale hotel offers an unparalleled blend of elegance, convenience, and top-notch amenities, making it the ideal destination for both business and leisure travelers. Here’s why Grand Legacy Inn Hotels is the ultimate luxury hotel on Pune Nagar Road.",
        link:'luxury-hotel-pune-nagar-road'
      },
      {
        text:'Hotel in Koregaon park',
        imgSrc: "./Hotel-Images/Hotel in Koregaon park.jpg",
        title:
         "If you're searching for a premier hotel in Koregaon Park, Pune, look no further than Grand Legacy inn hotels . Known for its blend of luxury, convenience, and exceptional service, Grand Legacy inn hotels  offers an unmatched experience for both business and leisure travelers. ",
        link:'hotel-in-koregaon-park'
      },
  
      {
        text:'Hotel In Wadgoan Sheri',
        imgSrc: "./Hotel-Images/Hotel In Wadgoan Sheri.jpg",
        title:
        "If you’re looking for a luxurious and comfortable stay in Pune, Grand Legacy inn hotels  in Wadgaon Sheri stands out as a top choice. Nestled in one of Pune's vibrant neighborhoods, this hotel combines elegance, convenience, and superior service to offer an unparalleled experience. ",
        link:'hotel-wadgaon-sheri'
      },
      
      {
        text:'Hotels near Pune international airport',
        imgSrc: "./Hotel-Images/Hotels near Pune international airport.jpg",
        title:
        "When traveling to Pune, finding the ideal accommodation near Pune International Airport can significantly enhance your travel experience. Grand Legacy inn hotels  offers a perfect blend of accessibility, comfort, and affordability, making it one of the top hotels near Pune International Airport. ",
        link:"hotels-near-pune-international-airport"
      }
  
  
    ];
  
  

  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-10.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Our Services</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Our Services
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End -->
	<!-- Blog Grid Start --> */}
      <div className="blog__grid section-padding">
    
   <div className="services-section">
      <div className="services-container">
        {blogPosts.map((service, index) => (
          <div className="service-card" key={index}>
            <i className={`fas ${service.icon} service-icon`}></i>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
      </div>


      {/* <!-- Blog Area Start -->	 */}

      <div className="blog__area section-padding">
        <div className="container">
          <div className="row mb-60">
            <div className="col-xl-12">
              <div className="blog__area-title">
                <span className="subtitle__one">Our Services</span>
                <h2 className="text-center fw-bold">Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {blogs.map((blog, index) => (
              <div
                key={index}
                className={`col-xl-4 col-lg-6 ${
                  index === 0 ? "xl-mb-30" : ""
                } ${index === 1 ? "lg-mb-30" : ""}`}
              >
                <div
                  className={`blog__area-item ${
                    blog.hoverEffect ? "blog__area-item-hover" : ""
                  }`}
                >
                  <div className="blog__area-item-image">
                    <a href={blog.link}>
                      <img src={blog.imgSrc} alt={blog.title} />
                    </a>
                  </div>
                  <div className="blog__area-item-content">
                    <div className="blog__area-item-content-box-title text-center">
                        <h5 className="fw-bold text-blog">{blog.text}</h5>
                        <p> <a href={blog.link}>{blog.title}</a></p>
                         <Link to={blog.link} className="mybtn">Read More...</Link>
                      </div>
                    </div>
                  
                  
                </div>
              </div>
            ))}
          </div>

     
        </div>
      </div>
      {/* <!-- Blog Area End -->	 */}
    </>
  );
}
