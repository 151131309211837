import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function OnlineHotelBookingInPune() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How do I book a room at Grand Legacy inn hotels  Inn online?",
      answer:
        "Booking a room at Grand Legacy inn hotels  Inn is simple and convenient through our online booking platform. Visit our website, enter your desired travel dates and location, and browse the available room options. Select the room type that best suits your needs, provide your personal and payment details, and confirm your reservation. You will receive an instant booking confirmation via email.",
    },
    {
      question:
        "Can I view room availability and rates before making a reservation?",
      answer:
        "Yes, our online booking system allows you to view real-time availability and rates for our rooms. You can compare different room types and their associated costs to choose the option that best fits your budget and preferences. Our website provides detailed information about each room, including amenities and special offers.",
    },
    {
      question: "What payment methods are accepted for online bookings?",
      answer:
        "Grand Legacy inn hotels  Inn accepts a variety of payment methods for online bookings, including major credit cards (Visa, MasterCard, American Express), debit cards, and secure online payment gateways. Ensure that your payment details are entered accurately to complete your reservation successfully.",
    },
    {
      question: "Can I modify or cancel my online booking?",
      answer:
        "Yes, you can modify or cancel your booking through our online platform. Log in to your account on our website, navigate to your reservation details, and follow the instructions to make changes or cancel your booking. Please review our cancellation policy for information on potential fees or restrictions.",
    },
    {
      question:
        "Is it possible to book a room for a special occasion or request specific amenities?",
      answer:
        "Absolutely! When making your reservation online, you have the option to add special requests or notes regarding your stay. If you have specific requirements or are celebrating a special occasion, please let us know in the 'Special Requests' section during the booking process. Our staff will do their best to accommodate your requests and ensure a memorable stay.",
    },
    {
      question:
        "Do you offer any special rates or promotions for online bookings?",
      answer:
        "Yes, Grand Legacy inn hotels  Inn offers exclusive rates and promotions for bookings made directly through our website. Keep an eye on our 'Special Offers' section for the latest deals and discounts. Booking directly with us ensures you receive the best available rates and access to unique packages that may not be available through third-party booking sites.",
    },
    {
      question: "How can I ensure that my booking is secure?",
      answer:
        "Our online booking platform utilizes advanced encryption and security measures to protect your personal and payment information. We adhere to industry standards for online security, so you can book your stay with confidence. If you have any concerns about your booking or payment security, please contact our customer support team for assistance.",
    },
    {
      question: "What should I do if I encounter issues while booking online?",
      answer:
        "If you experience any issues while booking your room online, please contact our customer support team for assistance. You can reach us via phone, email, or through the contact form on our website. Our team is available to help resolve any issues and ensure a smooth booking experience.",
    },
    {
      question: "Can I check in early or check out late?",
      answer:
        "Early check-in and late check-out requests are subject to availability and may incur additional charges. Please inform us of your request in advance by noting it in the 'Special Requests' section during your online booking or by contacting our front desk directly. We will do our best to accommodate your needs based on availability.",
    },
    {
      question: "How will I receive my booking confirmation?",
      answer:
        "After completing your online reservation, you will receive a booking confirmation email containing all the details of your stay, including your reservation number, room type, check-in and check-out dates, and payment information. Please check your email for this confirmation and keep it handy for reference during your stay.",
    },
  ];

  const testimonials = [
    {
      quote:
        "I recently stayed at Grand Legacy inn hotels  during a business trip to Pune, and I must say it was a wonderful experience. The online booking process was smooth and straightforward, making my reservation hassle-free. The hotel itself was impeccable—comfortable rooms, attentive staff, and an excellent location. The amenities provided were top-notch, and the staff went above and beyond to ensure my stay was pleasant. I highly recommend Grand Legacy inn hotels  for anyone visiting Pune. It’s definitely my go-to hotel in the city!",
      author: "Mrs. Ananya Sharma",
    },
    {
      quote:
        "My partner and I decided to stay at Grand Legacy inn hotels  for a weekend getaway, and it exceeded all our expectations. The booking process online was incredibly easy, and the hotel offered a range of amenities that made our stay special. The room was beautifully decorated, and we particularly enjoyed the in-room dining service and the comfortable ambiance. The location was also convenient, allowing us to explore Pune with ease. We appreciated the special touches that made our visit memorable. We’ll definitely be returning for our next trip!",
      author: "Mr. Raj Patel",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.example.com/images/hotel-grand-legacy.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "http://localhost:3001/corporate-hotel-booking-in-viman-nagar",
      "http://localhost:3001/hotels-near-kharadi","https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

        <title>
          Contact : +91 7058638153 | Grand Legacy inn hotels  | Online Hotel
          Booking in Pune
        </title>
        <meta
          name="description"
          content="Book hotels online in Pune with Grand Legacy inn hotels . Convenient booking near Pune Railway Station, Viman Nagar, Kharadi, Kalyani Nagar, and more. Contact +91 7058638153."
        />
        <meta
          name="keywords"
          content="online hotel booking in pune, Pune hotel booking near Railway Station, Cheapest Hotel near Pune Railway Station, Couple friendly Hotels near Pune Railway Station, Best couple friendly hotels in Pune, online hotel booking in viman nagar, online hotel booking in kharadi, online hotel booking in kalyani nagar, online hotel booking in koregaon park, online hotel booking in wadgaon sheri, online hotel booking in magarpatta city, online hotel booking in chandan nagar"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="+91 7058638153 | Grand Legacy inn hotels  | Online Hotel Booking in Pune"
        />
        <meta
          property="og:description"
          content="Make your online hotel booking in Pune easy and convenient. Choose from budget to luxury options near Pune Railway Station and key areas like Viman Nagar, Kharadi, Kalyani Nagar, and more."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/Online hotel booking in pune.jpg"
        />
        <meta
          property="og:url"
          content="https://www.grandlegacyinhotels.com/online-hotel-booking-pune"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Online Hotel Booking in Pune</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Online Hotel Booking in Pune
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Online hotel booking in pune.jpg" className="img-fluid" alt="Online hotel booking in pune" />
              </div>
              <h2>
                Grand Legacy inn hotels : Your Premier Choice for Online Hotel
                Booking in Pune
              </h2>
              <p>
                Welcome to Grand Legacy inn hotels , where luxury meets
                convenience through our seamless online hotel booking service.
                If you’re planning a trip to Pune and seeking an exceptional
                stay, our hotel offers the perfect blend of comfort, elegance,
                and modern amenities. Discover how our online booking system
                enhances your travel experience and why Grand Legacy inn hotels 
                should be your top choice for accommodation in Pune.
              </p>

              <h2>How to Book Online</h2>

              <ul>
                <li>
                  <strong>Visit Our Website</strong>
                  <p>
                    To book your stay, visit our website and navigate to the
                    booking section. Enter your desired dates, select your room
                    type, and review the available options. Our website provides
                    detailed descriptions and photos of each room type to help
                    you make an informed decision.
                  </p>
                </li>
                <li>
                  <strong>Select Your Room</strong>
                  <p>
                    Choose the room that best suits your needs and preferences.
                    You can view real-time availability and select from various
                    options, including standard rooms, deluxe rooms, and suites.
                  </p>
                </li>
                <li>
                  <strong>Complete Your Reservation</strong>
                  <p>
                    Fill in your personal details, including contact information
                    and payment method, to complete your booking. You’ll receive
                    an instant confirmation and a summary of your reservation
                    details via email.
                  </p>
                </li>
                <li>
                  <strong>Manage Your Booking</strong>
                  <p>
                    Once you’ve booked, you can easily manage your reservation
                    online. Make modifications, request special services, or
                    check your booking details through our website’s guest
                    portal.
                  </p>
                </li>
              </ul>

              <h2>Seamless Online Hotel Booking in Pune</h2>
              <p>
                Grand Legacy inn hotels  provides a hassle-free and convenient
                online hotel booking in Pune experience. Our user-friendly
                online platform allows you to easily search, compare, and book
                rooms from the comfort of your home or on the go. With real-time
                availability and instant confirmation, planning your stay in
                Pune has never been easier.
              </p>

              <h2>Pune Hotel Booking Near Railway Station</h2>
              <p>
                Convenient Location: Our hotel is ideally located for those
                seeking Pune hotel booking near Railway Station. Situated just a
                short distance from Pune’s main railway station, Grand
                Legacy Inn Hotels offers a perfect blend of accessibility and comfort.
                Whether you're arriving by train or need a convenient place to
                stay before continuing your journey, our location ensures you
                are well-connected to major transportation links.
              </p>

              <h2>Cheapest Hotel Near Pune Railway Station</h2>

              <ul>
                <li>
                  <strong>Affordable Luxury</strong>
                  <p>
                    Looking for the cheapest hotel near Pune Railway Station
                    without compromising on quality? Grand Legacy inn hotels 
                    offers competitive rates and exceptional value. We provide
                    affordable accommodation options that cater to
                    budget-conscious travelers, ensuring you receive the best
                    experience at a cost-effective price.
                  </p>
                </li>
                <li>
                  <strong>Exclusive Deals</strong>
                  <p>
                    Keep an eye on our website for special promotions and
                    discounts, making your stay even more affordable. Our goal
                    is to provide top-notch service and comfort without
                    exceeding your budget.
                  </p>
                </li>
              </ul>

              <h2>Couple-Friendly Hotels Near Pune Railway Station</h2>

              <p>
                For couples seeking a couple-friendly hotel near Pune Railway
                Station, Grand Legacy inn hotels  is the ideal choice. Our hotel
                offers a range of romantic amenities and a welcoming atmosphere,
                making it perfect for a relaxing and memorable stay.
              </p>

              <p>
                Enjoy amenities such as private dining options, stylish rooms,
                and personalized service designed to enhance your romantic
                experience. Our dedicated staff ensures that your stay is both
                comfortable and intimate.
              </p>

              <h2>Best Couple-Friendly Hotels in Pune</h2>

              <p>
                As one of the best couple-friendly hotels in Pune,
                Grand Legacy Inn Hotels is renowned for its exceptional service and
                romantic ambiance. Our luxurious accommodations, elegant décor,
                and thoughtful touches make us a top choice for couples looking
                to celebrate special occasions or simply enjoy a romantic
                retreat.
              </p>

              <p>
                We offer various services tailored to couples, including special
                packages, in-room dining, and exclusive amenities. Let us know
                your preferences, and we’ll do our best to make your stay
                unforgettable.
              </p>

              <h2>Online Hotel Booking in Viman Nagar</h2>

              <p>
                If you’re specifically interested in online hotel booking in
                Viman Nagar, Grand Legacy inn hotels  is also an excellent choice.
                Our Viman Nagar location provides easy access to the city’s
                vibrant business hubs, shopping areas, and entertainment
                options.
              </p>

              <p>
                Booking a stay at our Viman Nagar property is straightforward
                with our online booking system. Access real-time availability,
                view detailed room information, and secure your reservation
                effortlessly from anywhere.
              </p>

              <h2>Online Hotel Booking in Kharadi</h2>

              <p>
                For travelers looking to book a hotel in Kharadi,
                Grand Legacy Inn Hotels offers the perfect solution. Our online booking
                system makes it easy to secure a room in one of Pune's most
                dynamic business and tech hubs. Enjoy modern amenities and
                convenient access to major IT parks and business centers.
              </p>

              <p>
                Browse availability, choose your room type, and confirm your
                reservation with just a few clicks on our website. Enjoy a
                hassle-free experience with instant booking confirmation and
                real-time availability updates.
              </p>

              <h2>Online Hotel Booking in Kalyani Nagar</h2>

              <p>
                Kalyani Nagar is one of Pune's most sought-after locations,
                known for its blend of luxury and convenience. Grand
                Legacy Inn Hotels offers a premier online booking experience in this
                vibrant neighborhood. Our hotel features stylish accommodations
                and top-notch amenities, making it an ideal choice for both
                business and leisure travelers.
              </p>

              <p>
                Our online booking platform allows you to view detailed
                information about rooms, check availability, and secure your
                stay effortlessly. Enjoy the convenience of managing your
                reservation and accessing exclusive deals online.
              </p>

              <h2>Online Hotel Booking in Koregaon Park</h2>

              <p>
                Koregaon Park is renowned for its upscale ambiance and lively
                atmosphere. Grand Legacy inn hotels  provides an exceptional
                option for online hotel booking in Koregaon Park, offering
                luxurious rooms and excellent services in this prime location.
              </p>

              <p>
                Our user-friendly online system ensures that booking your stay
                in Koregaon Park is quick and straightforward. Access
                comprehensive details about room options and rates, and complete
                your reservation with ease.
              </p>

              <h2>Online Hotel Booking in Wadgaon Sheri</h2>

              <p>
                Wadgaon Sheri is a thriving area in Pune, offering easy access
                to major commercial and residential zones. Grand
                Legacy Inn Hotels provides an excellent option for online hotel booking in
                Wadgaon Sheri, featuring comfortable accommodations and
                convenient amenities.
              </p>

              <p>
                Take advantage of our streamlined online booking system to find
                the best rates and availability. Enjoy instant confirmation and
                manage your booking with ease, ensuring a smooth and enjoyable
                stay.
              </p>

              <h2>Online Hotel Booking in Magarpatta City</h2>

              <p>
                Magarpatta City is known for its modern infrastructure and
                business-friendly environment. Grand Legacy inn hotels  offers a
                top-tier option for online hotel booking in Magarpatta City,
                providing guests with access to contemporary amenities and a
                strategic location.
              </p>

              <p>
                Our online booking platform allows you to easily search for
                available rooms, compare rates, and make your reservation.
                Benefit from our real-time updates and secure your stay in
                Magarpatta City effortlessly.
              </p>

              <h2>Online Hotel Booking in Chandan Nagar</h2>

              <p>
                For those seeking a stay in Chandan Nagar,  Grand
                Legacy Inn Hotels offers a great choice for online hotel booking. Our hotel
                provides a blend of comfort and accessibility in this bustling
                area, with amenities designed to meet your needs.
              </p>

              <p>
                Our easy-to-navigate online booking system lets you quickly find
                and book your room in Chandan Nagar. Enjoy instant confirmation
                and manage your reservation conveniently through our website.
              </p>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Online hotel booking in Pune</p>
                      <p>- Pune hotel booking near Railway Station</p>
                      <p>- Cheapest Hotel near Pune Railway Station</p>
                      <p>- Couple-friendly Hotels near Pune Railway Station</p>
                      <p>- Best couple-friendly hotels in Pune</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Online hotel booking in Viman Nagar</p>
                      <p>- Online hotel booking in Kharadi</p>
                      <p>- Online hotel booking in Kalyani Nagar</p>
                      <p>- Online hotel booking in Koregaon Park</p>
                      <p>- Online hotel booking in Wadgaon Sheri</p>
                      <p>- Online hotel booking in Magarpatta City</p>
                      <p>- Online hotel booking in Chandan Nagar</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>
                Why Choose Grand Legacy inn hotels  Inn for Online Hotel Booking
                in Pune?
              </h2>
              <p>
                Discover the reasons why Grand Legacy inn hotels  Inn is the ideal
                choice for your stay in Pune:
              </p>
              <ul>
                <li>
                  <strong>Convenient Online Booking:</strong>
                  Our intuitive online booking platform simplifies the process
                  of reserving your stay. With real-time availability, detailed
                  room descriptions, and secure payment options, booking your
                  accommodation at Grand Legacy inn hotels  Inn is effortless.
                  Enjoy instant confirmation and manage your reservation easily
                  from anywhere at any time.
                </li>
                <li>
                  <strong>Prime Locations Across Pune:</strong>
                  Grand Legacy inn hotels  Inn is strategically located in key
                  areas across Pune, including Kharadi, Kalyani Nagar, Koregaon
                  Park, Wadgaon Sheri, Magarpatta City, and Chandan Nagar.
                  Whether you’re visiting for business or leisure, our hotels
                  offer convenient access to major commercial districts, local
                  attractions, and transportation links, ensuring a hassle-free
                  stay.
                </li>
                <li>
                  <strong>Competitive Rates and Exclusive Offers:</strong>
                  Booking directly through our website ensures you receive the
                  best available rates and access to exclusive online
                  promotions. We regularly update our offers to provide you with
                  exceptional value for your money, making your stay both
                  affordable and luxurious.
                </li>
                <li>
                  <strong>Luxurious Accommodations:</strong>
                  Our hotels are renowned for their luxurious accommodations and
                  modern amenities. From well-appointed rooms and elegant suites
                  to top-notch facilities, Grand Legacy inn hotels  Inn ensures
                  that every guest enjoys a comfortable and memorable stay.
                  Features include high-speed internet, flat-screen TVs, premium
                  bedding, and well-equipped bathrooms.
                </li>
                <li>
                  <strong>Exceptional Service:</strong>
                  At Grand Legacy inn hotels  Inn, we are committed to delivering
                  outstanding service. Our professional and friendly staff are
                  dedicated to making your stay enjoyable, whether you need
                  assistance with your booking, special requests, or local
                  recommendations. We aim to exceed your expectations with
                  personalized service and attention to detail.
                </li>
                <li>
                  <strong>Variety of Options:</strong>
                  We offer a diverse range of accommodation options to cater to
                  different preferences and budgets. Whether you’re looking for
                  a luxury suite, a family-friendly room, or a budget-friendly
                  option, Grand Legacy inn hotels  Inn provides a variety of
                  choices to meet your needs.
                </li>
                <li>
                  <strong>Easy Access to Local Attractions:</strong>
                  Our hotels are ideally situated to offer easy access to Pune’s
                  major attractions, business centers, and cultural landmarks.
                  Enjoy proximity to popular destinations, shopping areas, and
                  dining options, enhancing your overall travel experience.
                </li>
                <li>
                  <strong>Seamless Check-In and Check-Out:</strong>
                  Experience a smooth and efficient check-in and check-out
                  process with our streamlined procedures. We value your time
                  and strive to make your arrival and departure as convenient as
                  possible.
                </li>
                <li>
                  <strong>Flexible Cancellation Policies:</strong>
                  We understand that travel plans can change.Grand
                  Legacy Inn Hotels Inn offers flexible cancellation policies to provide
                  you with peace of mind. Review our terms and conditions during
                  the booking process to choose an option that suits your needs.
                </li>
                <li>
                  <strong>Commitment to Cleanliness and Safety:</strong>
                  Your health and safety are our top priorities.Grand
                  Legacy Inn Hotels adheres to rigorous cleanliness and hygiene
                  standards to ensure a safe and comfortable environment for all
                  guests. Our staff follows strict protocols to maintain the
                  highest levels of cleanliness throughout our properties.
                </li>
              </ul>
              <div className="">
                <h2>
                  FAQs for Grand Legacy inn hotels  Inn: Online Hotel Booking in
                  Pune?
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="">
                <h2>
                  Testimonials for Online Hotel Booking in Pune -
                  Grand Legacy Inn Hotels{" "}
                </h2>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-slide">
                      <blockquote>
                        <p>{testimonial.quote}</p>
                        <footer>— {testimonial.author}</footer>
                      </blockquote>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* ================= */}
              <div className=" contact-section">
                <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
