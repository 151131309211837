import React from "react";
import { Link } from "react-router-dom";
export default function Team(){
  
  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];

 
    return(
        <>
        {/* <!-- Header Area End -->	
	    <!-- Page Banner Start --> */}
    <div class="page__banner" data-background="./img/banner/page-banner-2.jpg">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="page__banner-title">
                        <h1>Terms & Condition</h1>
                        <div class="page__banner-title-menu">
                            <ul>
                              <li><Link to="/">Home</Link></li>
                            
                                <li><span>-</span>Terms & Condition</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	{/* <!-- Page Banner End -->
	<!-- Services Team Start --> */}
  <section className="accommodations__area  section-padding">
       <div className=" container  policy-section">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-md-8">
            <div className="myImage">
              <img src="Hotel-Images\Terms and condition.jpg" alt="" />
            </div>
            <h2>Terms & Condition</h2>

            <div>
              <p>Terms & Condition for Grand Legacy Inn Hotels : </p>
              <div class="hotel-policies">
  <h2>Grand Legacy inn hotels  Luxury Hotel Policies</h2>

  <section class="reservation-process">
    <h3>Reservation Process</h3>
    <p>At Grand Legacy inn hotels  Luxury Hotel, we place a premium on ensuring a seamless reservation process for our esteemed guests, contingent upon the availability of rooms. The provision of accurate and comprehensive information during the booking phase is essential to guarantee a smooth and delightful experience.</p>
  </section>

  <section class="payment-policy">
    <h3>Payment Policy</h3>
    <p>Our payment policy underscores convenience and security, requiring full payment at the time of booking. Guests can make use of our dedicated portal for secure online payments, enhancing the overall booking process.</p>
  </section>

  <section class="cancellation-policy">
    <h3>Cancellation Policy</h3>
    <p>In the unfortunate event of cancellations, guests who cancel <strong>Day or more</strong> before the check-in date are entitled to a full refund. However, cancellations within <strong>Day</strong> of the check-in date will incur a cancellation fee.</p>
  </section>

  <section class="damages-policy">
    <h3>Damages Responsibility</h3>
    <p>Guests are entrusted with the responsibility of any damages to hotel property during their stay, and additional charges incurred are expected to be settled upon check-out. This commitment ensures a harmonious and well-maintained environment for all patrons.</p>
  </section>

  <section class="general-policies">
    <h3>General Hotel Policies</h3>
    <ul>
      <li><strong>Smoking:</strong> Smoking is strictly prohibited in all indoor areas.</li>
      <li><strong>Pets:</strong> Pets are not allowed on the premises.</li>
      <li><strong>Quiet Hours:</strong> Guests are encouraged to observe the designated quiet hours, contributing to the overall comfort of everyone staying at Grand Legacy inn hotels  Luxury Hotel.</li>
    </ul>
  </section>

  <section class="privacy-policy">
    <h3>Privacy and Security</h3>
    <p>Emphasizing privacy and security, we are dedicated to safeguarding guest data in strict accordance with applicable laws. Our unwavering commitment to maintaining the confidentiality of guest information reflects our dedication to providing a secure and enjoyable stay for all who choose Grand Legacy inn hotels  Luxury Hotel.</p>
    <p>Your trust is of utmost importance to us, and we strive to exceed your expectations in every aspect of your stay with us.</p>
  </section>
</div>

              <div className=" contact-section">
                <h2>Grand Legacy inn hotels  Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <h2>Recent Post</h2>
            {hotelImages.map((e) => {
              return (
                <div className="box1">
                  <a
                    href={e.path}
                    className="d-flex justify-content-around align-items-center"
                  >
                    <div className="b1">
                      <img src={e.place} alt="" />
                    </div>
                    <div className="b2">
                      {" "}
                      <a href={e.path}>{e.text}</a>
                    </div>
                  </a>
                </div>
              );
            })}

            <div className="">
              <h5 className="pb-3 pt-3">Contact Information</h5>

              <div className="services__two-item">
                <div className="mybox1 text-center p-3 mb-3">
                  <a href="tel:+91 7058638153">
                    <i class="bi bi-telephone-fill fs-1"></i>
                    <div>
                      <a href="tel:+91 7058638153">+91 7058638153</a>
                    </div>
                  </a>
                </div>

<div className="mybox1 text-center p-3 mb-3">
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                    <i class="bi bi-envelope fs-1"></i>
                    <div className="">
                      <a href="  mailto:reservation@grandlegacyinnhotels.com">
                         reservation@grandlegacyinnhotels.com
                     
                      </a>
                    </div>
                  </a>
                </div>
               

                <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
	{/* <!-- Services Team End --> */}


    </>
    )
}