import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function HotelInVimanNagar() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question:
        "What are the key features of Grand Legacy inn hotels  in Viman Nagar?",
      answer: (
        <ul>
          <li>
            <strong>Prime Location:</strong> Situated in the heart of Viman
            Nagar, providing easy access to business centers, shopping areas,
            and dining options.
          </li>
          <li>
            <strong>Luxurious Accommodations:</strong> Well-appointed rooms with
            modern amenities such as high-speed Wi-Fi, flat-screen TVs, and
            plush bedding.
          </li>
          <li>
            <strong>Dining Options:</strong> An in-house restaurant offering a
            variety of local and international cuisines.
          </li>
          <li>
            <strong>Business Facilities:</strong> A fully equipped business
            center and meeting rooms for corporate needs.
          </li>
          <li>
            <strong>Fitness and Wellness:</strong> A well-equipped gym and a
            relaxing spa.
          </li>
        </ul>
      ),
    },

    {
      question:
        "How far is Grand Legacy inn hotels  from Pune International Airport?",
      answer:
        "Grand Legacy inn hotels  is conveniently located approximately 3 kilometers from Pune International Airport. This close proximity makes it easy for guests to travel to and from the airport quickly, whether you're arriving for a business trip or a leisure stay.",
    },
    {
      question: "Does Grand Legacy inn hotels  offer parking facilities?",
      answer:
        "Yes, Grand Legacy inn hotels  provides complimentary parking facilities for all guests. Our secure parking area ensures that your vehicle is safe while you enjoy your stay with us.",
    },
    {
      question: "Are pets allowed at Grand Legacy inn hotels ?",
      answer:
        "Currently, Grand Legacy inn hotels  does not allow pets on the property. We strive to maintain a comfortable environment for all guests, and as such, we ask that guests make alternative arrangements for their pets during their stay.",
    },
    {
      question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
       answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
   }
  ];

  const testimonials = [
    {
      quote:
        "I had an amazing stay at Grand Legacy inn hotels . The location in Viman Nagar is perfect, with everything you need just a short walk away. The room was spacious, clean, and beautifully decorated. The staff were incredibly friendly and attentive, making me feel right at home. I highly recommend this hotel for anyone visiting Pune!",
      author: "Mrs. Aditi Sharma",
    },
    {
      quote:
        "Grand Legacy inn hotels  exceeded my expectations in every way. From the warm welcome at check-in to the luxurious room amenities, everything was top-notch. The on-site restaurant offered a fantastic variety of dishes, and the business facilities were perfect for my needs. I’ll definitely be staying here again on my next trip to Pune.",
      author: "Mr. Rajesh Kumar",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }
  
  ];


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
     "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <HelmetProvider>
        <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>

          <title>
            Best Hotels in Viman Nagar| Contact:+91 7058638153|Grand Legacy Inn Hotels
            | Hotel in Viman Nagar | Hotels in Viman Nagar for Stay
          </title>
          <meta
            name="description"
            content="Find the best hotels in Viman Nagar, Pune including 3-star hotels, business hotels, couple-friendly options, and more. Book your stay at top-rated hotels in Viman Nagar for a comfortable experience."
          />
          <meta
            name="keywords"
            content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, business hotels in Viman Nagar, best hotels in Viman Nagar Pune, couple friendly hotels in Viman Nagar, 3 star hotels in Viman Nagar"
          />
          <meta name="robots" content="index, follow" />
          <meta
            property="og:title"
            content="Best Hotels in Viman Nagar, Pune"
          />
          <meta
            property="og:description"
            content="Explore top-rated hotels in Viman Nagar, Pune, including business hotels, 3-star accommodations, and couple-friendly options. Book your ideal stay in Viman Nagar today."
          />
          <meta
            property="og:image"
            content="http://www.grandlegacyinnhotels.com/Hotel-Images/Hotel In Viman Nagar.jpg"
          />
          <meta
            property="og:url"
            content="http://www.grandlegacyinnhotels.com/hotel-in-viman-nagar"
          />
          <meta property="og:type" content="website" />
        </Helmet>

        {/* <!-- Page Banner Start --> */}
        <div
          class="page__banner"
          data-background="./img/banner/page-banner-5.jpg"
        >
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="page__banner-title">
                  <h1>Hotel in Viman Nagar</h1>
                  <div class="page__banner-title-menu">
                    <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                      <li>
                        <span>-</span>Hotel in Viman Nagar
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Page Banner End --> */}

        {/* section start */}
        <div class="accommodations__area section-padding keywordsection">
          <div className="container">
            <div className="row d-flex justify-content-around">
              <div className="col-12 col-md-8">
                <div className="keyword-img">
                  <img
                    src="./Hotel-Images/Hotel In Viman Nagar.jpg"
                    className="img-fluid"
                    alt="Hotel In Viman Nagar"
                  />
                </div>
                <div>
                  <h2>
                    Grand Legacy inn hotels : Your Premier Choice for a Stay in
                    Viman Nagar
                  </h2>
                  <p>
                    Welcome to Grand Legacy inn hotels , your ultimate destination
                    for a luxurious and comfortable stay in Viman Nagar. Whether
                    you're visiting for business or leisure, our hotel offers a
                    blend of modern amenities and exceptional service that
                    guarantees a memorable experience. Located in one of Pune's
                    most vibrant neighborhoods, we are perfectly positioned to
                    cater to all your travel needs.
                  </p>
                  <h2>Book Your Stay at Grand Legacy inn hotels </h2>
                  <p>
                    Experience the best of Pune with a stay at Grand Legacy Inn Hotels
                    . Our commitment to quality, comfort, and convenience
                    ensures that you have everything you need for an
                    unforgettable visit. Book your stay with us today and
                    discover why we are the premier hotel choice in Viman Nagar.
                  </p>

                  <h2>
                    Your Top Choice for Viman Nagar Hotel Booking with 
                    Grand Legacy Inn Hotels
                  </h2>
                  <p>
                    Welcome to Grand Legacy inn hotels , the premier destination
                    for Viman Nagar hotel booking. Whether you're here for
                    business, leisure, or a romantic getaway, our hotel offers
                    an exceptional blend of luxury and convenience, tailored to
                    meet your every need.
                  </p>

                  <h2>Viman Nagar Hotels for Stay</h2>
                  <p>
                    When searching for Viman Nagar hotels for stay, Grand Legacy Inn Hotels
                     is your ideal choice. Located in the heart of
                    Viman Nagar, our hotel provides easy access to major
                    business centers, shopping districts, and popular
                    attractions. Enjoy a blend of comfort, style, and
                    convenience with our well-appointed rooms and exceptional
                    service.
                  </p>

                  <h2>Viman Nagar Hotel Room</h2>
                  <p>
                    At Grand Legacy inn hotels , our Viman Nagar hotel rooms are
                    designed to provide a sanctuary of relaxation. Each room is
                    elegantly furnished and equipped with modern amenities to
                    ensure a comfortable stay. Experience plush bedding,
                    high-speed Wi-Fi, flat-screen TVs, and more in our
                    beautifully designed accommodations.
                  </p>

                  <h2>Business Hotels in Viman Nagar</h2>
                  <p>
                    For business travelers, Grand Legacy inn hotels  is one of the
                    top business hotels in Viman Nagar. Our hotel features
                    state-of-the-art meeting and conference facilities, a fully
                    equipped business center, and high-speed internet access.
                    Whether you're hosting a corporate event or need a quiet
                    space to work, we have the resources and services to meet
                    your needs.
                  </p>

                  <h2>The Best Hotels in Viman Nagar Pune</h2>
                  <p>
                    Considered among the best hotels in Viman Nagar Pune, 
                     Grand Legacy Inn Hotels offers unmatched quality and service. From
                    our luxurious rooms to our exceptional dining options, we
                    provide everything you need for a memorable stay. Discover
                    why guests consistently rate us among the top hotels in the
                    area.
                  </p>

                  <h2>Couple Friendly Hotels in Viman Nagar</h2>
                  <p>
                    Looking for couple friendly hotels in Viman Nagar? 
                    Grand Legacy Inn Hotels provides a romantic and serene environment
                    perfect for couples. Our elegant rooms, excellent dining
                    options, and personalized service create the perfect setting
                    for a romantic escape. Enjoy your time together in one of
                    Pune's most desirable locations.
                  </p>

                  <h2>3 Star Hotels in Viman Nagar</h2>
                  <p>
                    As one of the top 3-star hotels in Viman Nagar,
                    Grand Legacy Inn Hotels offers the perfect balance of affordability and
                    luxury. Enjoy the comfort and quality of a higher-starred
                    hotel at a price that fits your budget. Our attentive
                    service and excellent facilities ensure a great stay without
                    the premium cost.
                  </p>

                  <h2>Book Your Stay at Grand Legacy inn hotels </h2>
                  <p>
                    Ready to experience all that Grand Legacy inn hotels  has to
                    offer? Book your stay today and discover why we are the top
                    choice for visitors to Viman Nagar. Our convenient location,
                    luxurious accommodations, and outstanding service make us
                    the ideal choice for your next trip to Pune.
                  </p>

                  <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
                  <p>
                    Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel
                    is strategically located in the heart of Viman Nagar, one of
                    Pune's most vibrant and accessible neighborhoods.
                  </p>

                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                      borderWidth: "0px",
                    }}
                    border="1"
                  >
                    <colgroup>
                      <col style={{ width: "49.9491%" }} />
                      <col style={{ width: "49.9491%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td style={{ borderWidth: "0px" }}>
                          <p>- Viman Nagar Hotel Booking</p>
                          <p>- Viman Nagar Hotels for Stay</p>
                          <p>- Viman Nagar Hotel Room</p>
                          <p>- Business Hotels in Viman Nagar</p>
                        </td>
                        <td style={{ borderWidth: "0px" }}>
                          <p>- The Best Hotels in Viman Nagar Pune</p>
                          <p>- Couple Friendly Hotels in Viman Nagar</p>
                          <p>- 3 Star Hotels in Viman Nagar</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h2>
                    Why Choose Grand Legacy inn hotels  for Your Stay in Viman
                    Nagar, Pune?
                  </h2>
                  <p>
                    When it comes to selecting the perfect hotel in Viman Nagar,
                    Pune, Grand Legacy inn hotels  stands out as the ideal choice.
                    Here's why you should consider staying with us:
                  </p>

                  <h2>1. Prime Location in Viman Nagar</h2>
                  <p>
                    Our hotel is strategically located in the heart of Viman
                    Nagar, one of Pune's most vibrant and accessible
                    neighborhoods. Whether you're here for business or leisure,
                    you’ll appreciate our proximity to key business districts,
                    shopping centers, and popular dining spots. With easy access
                    to major roads and public transport, your stay with us will
                    be both convenient and enjoyable.
                  </p>

                  <h2>2. Luxurious Accommodations</h2>
                  <p>
                    Grand Legacy inn hotels  offers a range of luxurious
                    accommodations designed to provide maximum comfort and
                    relaxation. Our rooms feature:
                  </p>
                  <ul>
                    <li>
                      <strong>Elegant Furnishings:</strong> Tastefully decorated
                      with modern and stylish furnishings.
                    </li>
                    <li>
                      <strong>State-of-the-Art Amenities:</strong> Including
                      high-speed Wi-Fi, flat-screen TVs, minibars, and more.
                    </li>
                    <li>
                      <strong>Comfortable Bedding:</strong> Plush mattresses and
                      high-quality linens ensure a restful night’s sleep.
                    </li>
                  </ul>

                  <h2>3. Exceptional Dining Experience</h2>
                  <p>
                    Our on-site restaurant provides a diverse menu featuring
                    both local and international cuisines. Whether you’re
                    starting your day with a hearty breakfast or enjoying a
                    gourmet dinner, our chefs use the finest ingredients to
                    create memorable dining experiences. Room service is also
                    available for those who prefer dining in the privacy of
                    their room.
                  </p>

                  <h2>4. Comprehensive Business Facilities</h2>
                  <p>
                    For business travelers, Grand Legacy inn hotels  is equipped
                    with top-notch facilities designed to meet your professional
                    needs. Our offerings include:
                  </p>
                  <ul>
                    <li>
                      <strong>Business Center:</strong> Equipped with the latest
                      technology and high-speed internet.
                    </li>
                    <li>
                      <strong>Meeting and Conference Rooms:</strong> Ideal for
                      corporate events, meetings, and presentations.
                    </li>
                    <li>
                      <strong>Support Services:</strong> Including printing,
                      copying, and secretarial services.
                    </li>
                  </ul>

                  <h2>5. Outstanding Service and Hospitality</h2>
                  <p>
                    Our dedicated staff is committed to providing exceptional
                    service to ensure that your stay is both pleasant and
                    memorable. From the moment you arrive, you’ll experience our
                    warm hospitality and attention to detail. Whether you need
                    travel advice, special requests, or assistance with any
                    aspect of your stay, our team is here to help.
                  </p>

                  <h2>6. Amenities for Relaxation and Wellness</h2>
                  <p>
                    We understand the importance of relaxation, and our hotel
                    offers a range of amenities to help you unwind:
                  </p>
                  <ul>
                    <li>
                      <strong>Fitness Center:</strong> Stay active with our
                      well-equipped gym.
                    </li>
                    <li>
                      <strong>Spa Services:</strong> Enjoy a range of treatments
                      designed to rejuvenate and relax.
                    </li>
                    <li>
                      <strong>Leisure Facilities:</strong> Including a lounge
                      area and outdoor spaces for your enjoyment.
                    </li>
                  </ul>

                  <h2>7. Couple-Friendly Environment</h2>
                  <p>
                    Grand Legacy inn hotels  is designed to cater to couples
                    seeking a romantic escape. Our serene atmosphere, luxurious
                    rooms, and special packages create the perfect setting for a
                    memorable getaway.
                  </p>

                  <h2>8. Affordable Luxury</h2>
                  <p>
                    As a 3-star hotel in Viman Nagar, we offer a blend of luxury
                    and affordability. Experience high-quality amenities and
                    exceptional service at competitive rates, ensuring great
                    value for your money.
                  </p>

                  <div className="">
                    <h2>
                      FAQs for Hotel in Viman Nagar, Pune - Grand Legacy Inn Hotels
                    
                    </h2>
                    {faqs.map((faq, index) => (
                      <div key={index} className="faq-item">
                        <div
                          className={`faq-question ${
                            activeIndex === index ? "active" : ""
                          }`}
                          onClick={() => toggleAccordion(index)}
                        >
                          {index + 1}. {faq.question}
                        </div>
                        {activeIndex === index && (
                          <div className="faq-answer">{faq.answer}</div>
                        )}
                      </div>
                    ))}
                  </div>

                  {/* ======================== */}
                  <div className="">
                    <h2>
                      Testimonials for Hotel in Viman Nagar Pune - Grand Legacy Inn Hotels: -{" "}
                    </h2>
                    <Slider {...settings}>
                      {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-slide">
                          <blockquote>
                            <p>{testimonial.quote}</p>
                            <footer>— {testimonial.author}</footer>
                          </blockquote>
                        </div>
                      ))}
                    </Slider>
                  </div>

                  {/* ================= */}
                  <div className=" contact-section">
                    <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                    <p>
                      For booking inquiries or any assistance, please feel free
                      to contact us:
                    </p>
                    <p>
                      <strong>Mobile No:</strong>{" "}
                      <a href="tel:+917058638153">+91 7058638153</a>
                    </p>
                    <p>
                      <strong>Email Id:</strong>{" "}
                      <a href="  mailto:reservation@grandlegacyinnhotels.com">
                       reservation@grandlegacyinnhotels.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <h2>Recent Post</h2>
                {hotelImages.map((e) => {
                  return (
                    <div className="box1">
                      <a
                        href={e.path}
                        className="d-flex justify-content-around align-items-center"
                      >
                        <div className="b1">
                          <img src={e.place} alt="" />
                        </div>
                        <div className="b2">
                          {" "}
                          <a href={e.path}>{e.text}</a>
                        </div>
                      </a>
                    </div>
                  );
                })}

                <div className="">
                  <h5 className="pb-3 pt-3">Contact Information</h5>

                  <div className="services__two-item">
                    <div className="mybox1 text-center p-3 mb-3">
                      <a href="tel:+91 7058638153">
                        <i class="bi bi-telephone-fill fs-1"></i>
                        <div>
                          <a href="tel:+91 7058638153">+91 7058638153</a>
                        </div>
                      </a>
                    </div>

                    <div className="mybox1 text-center p-3 mb-3">
                      <a href="  mailto:reservation@grandlegacyinnhotels.com">
                        <i class="bi bi-envelope fs-1"></i>
                        <div className="">
                          <a href="  mailto:reservation@grandlegacyinnhotels.com">
                            hotelthegrandlegacy
                            <br />
                            @gmail.com
                          </a>
                        </div>
                      </a>
                    </div>

                    <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
}
