// import React from 'react';
import './App.css'; 
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import { Link } from 'react-router-dom';

 
export default function Gallery(){
      const [photoIndex, setPhotoIndex] = useState(0);
      const [isOpen, setIsOpen] = useState(false);
    
      const galleryItems = [
        "./website img/NANASHREE 01. COPY jpg.jpg",
        "./website img/NANASHREE 04 COPY.jpg",
        "./website img/NANASHREE 05.jpg",
        "./website img/NANASHREE 07.jpg",
        "./website img/NANASHREE 09.jpg",
        "./website img/NANASHREE 20.jpg",
        "./website img/NANASHREE 12.jpg",
        "./website img/NANASHREE 14.jpg",
        "./website img/NANASHREE 16.jpg",
        "./website img/NANASHREE 17.jpg",
        "./website img/NANASHREE 18.jpg",
        "./website img/NANASHREE 19.jpg",
        "grand legacy rooms/grand legacy room1.jpg",
        "grand legacy rooms/grand legacy room3.jpg",
        "grand legacy rooms/grand legacy room8.jpg",
        "grand legacy rooms/grand legacy room12.jpg",
        "grand legacy rooms/grand legacy room7.jpg",
        "grand legacy rooms/grand legacy room6.jpg",
        "grand legacy rooms/grand leagcy room11.jpg",
        "grand legacy rooms/grand legacy room2.jpg"
      ];
    
    return(
        <>
           <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Gallery</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Gallery
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<div className="gallery__area section-padding pb-0 overflow-hidden">
      <div className="container p-0">
        <div className="row">
          {galleryItems.map((imageSrc, index) => (
            <div key={index} className="col-sm-3 sm-mb-10">
              <div className="gallery__area-item gallerybox">
                <img
                  className="img__full"
                  src={imageSrc}
                  alt={`Gallery Image ${index + 1}`}
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={galleryItems[photoIndex]}
          nextSrc={galleryItems[(photoIndex + 1) % galleryItems.length]}
          prevSrc={galleryItems[(photoIndex + galleryItems.length - 1) % galleryItems.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + galleryItems.length - 1) % galleryItems.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryItems.length)
          }
        />
      )}
    </div>

      
      {/* /* <!-- Gallery Area End  */}
        </>
    )
 }