import React from "react";
import { Link } from "react-router-dom";

export default function RoomStyle() {
  const rooms = [
    {
      
      image: "./website img/NANASHREE 02.jpg",
    },
    {
      image: "grand legacy rooms/grand legacy room4.jpg",
    },
    {
      
      image: "grand legacy rooms/grand legacy room6.jpg",
     
    },
   
  ];
  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-3.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Room Style</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Room Style
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End -->
	<!-- Room Area Start --> */}
      <div class="room__area pt-5 pb-3">
        <div class="container">
          <div className="row justify-content-center">
            {[
              {
                imgSrc: "grand legacy rooms/grand legacy room1.jpg",
                price: "₹2750",
                title: "Executive Room",
                description:
               "The Executive Room at Grand Legacy inn hotels  offers a blend of elegance and modern amenities, perfect for business travelers and families alike. With contemporary interiors, plush bedding, and sleek furnishings, this room ensures a relaxing and productive stay.",
               
                list1:'King-size bed or twin beds',
                list2:'Complimentary high-speed Wi-Fi',
                list3:'Air conditioning',
                list4:'Flat-screen TV',
                list5:'In-room safe',
              },
              {
                imgSrc: "grand legacy rooms/grand legacy room11.jpg",
                price: "₹1800",
                title: "Standard Room",
                description:
                     "The Standard Room at Grand Legacy inn hotels  offers simplicity and comfort for travelers looking for a cozy and affordable stay. Ideal for solo travelers or couples, this room features essential amenities, ensuring a comfortable experience.",
             
               list1:"Queen-size bed or twin beds",
               list2:"Complimentary Wi-Fi",
               list3:"Air conditioning",
               list4:'Flat-screen TV',
               list5:'In room safe'
              },
              {
                imgSrc: "grand legacy rooms/grand leagcy room11.jpg",
                price: "₹3500",
                title: "Suite Room",
                description:
                   "The Sweet Room at Grand Legacy inn hotels  epitomizes luxury and sophistication, offering expansive space and high-end amenities for a lavish stay. Perfect for discerning travelers or those celebrating special moments, this room provides a separate living area, a king-size bed draped in premium linens, and stylish interiors with elegant décor. ",
             
                list1:'King-size bed with luxury linens', 
                list2:'Separate living room or lounge area',
                list3:'Spacious bathroom with bathtub and rain shower',
                list4:'Flat-screen TV, complimentary Wi-Fi, and mini-bar',
                 list5:'In room safe'
              },
            ].map((room, index) => (
              <div key={index} className="col-xl-4 col-md-6 mb-30">
                <div className="deluxe__two-item">
                  <div className="deluxe__two-item-image">
                    <Link to='/'>
                        <img src={room.imgSrc} alt={room.title} />
                    </Link>
                    
                  </div>
                  <div className="deluxe__two-item-content">
                    <span>{room.price}</span>
                    <h4>
                      <a href="room-details.html">{room.title}</a>
                    </h4>
                     <ul>
                         <li>{room.list1}</li>
                         <li>{room.list2}</li>
                         <li>{room.list3}</li>
                         <li>{room.list4}</li>
                         <li>{room.list5}</li>
                     </ul>
                    <p>{room.description}</p>
                  
                    <div className="deluxe__two-item-content-bottom">
                      <Link className="simple-btn"  to="/">
                        <i className="far fa-chevron-right"></i>Read More
                      </Link>
                   
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modern Room Area */}
      <div className="modern__room">
        <div className="container">
          <div className="row">
            {rooms.map((room, index) => (
              <div
                key={index}
                className={`col-xl-4 col-lg-6 col-md-6 ${
                  index % 3 === 1 ? "md-mb-30" : "mb-30"
                }`}
              >
                <div className="deluxe__three-item">
                  <div className="deluxe__three-item-image">
                    <img src={room.image} alt={room.name} />
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <!-- Room Area End --> */}
    </>
  );
}
