import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";


export default function HotelForStayInLohagaon() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    const faqs = [
      {
        question: "What amenities does Grand Legacy inn hotels  offer for guests staying near Lohegaon Airport?",
        answer: (
          <ul>
            <li><strong>Spacious Rooms:</strong> Featuring modern furnishings, high-speed Wi-Fi, and flat-screen TVs.</li>
            <li><strong>Business Center:</strong> Equipped with essential services for business travelers.</li>
            <li><strong>Fitness Center:</strong> A well-equipped gym to stay active during your stay.</li>
            <li><strong>Restaurant:</strong> Offering a variety of local and international dishes.</li>
            <li><strong>Airport Shuttle Service:</strong> Convenient transport to and from Lohegaon Airport.</li>
            <li><strong>24/7 Front Desk:</strong> For any assistance or special requests you may have.</li>
          </ul>
        ),
      },
      {
        question: "How close is Grand Legacy inn hotels  to Lohegaon Airport?",
        answer: "Grand Legacy inn hotels  is ideally located just minutes away from Lohegaon Airport. This proximity ensures that you can reach the airport quickly and easily, making it an excellent choice for both early morning departures and late-night arrivals.",
      },
      {
        question: "Can I book a room at Grand Legacy inn hotels  for just a few hours or an overnight stay?",
        answer: "Yes, Grand Legacy inn hotels  offers flexible booking options, including short stays and overnight accommodations. Whether you need a brief respite between flights or a comfortable place to stay overnight, we can accommodate your needs. Please contact our reservations team for more details on availability and rates.",
      },
      {
        question: "Is there a restaurant or dining option available at the hotel?",
        answer: "Yes, Grand Legacy inn hotels  features an on-site restaurant where guests can enjoy a variety of dining options. Our menu includes a selection of local and international cuisine, prepared with fresh ingredients. Whether you're looking for a hearty meal or a light snack, our dining facilities have you covered.",
      },
      {
        question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
        answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
      },
    ];

    const testimonials = [
        {
          quote: "I recently stayed at Grand Legacy inn hotels  during a business trip and couldn't have been more pleased with my experience. The hotel’s proximity to Lohegaon Airport was incredibly convenient, making my travel so much easier. The room was modern and comfortable, and the complimentary high-speed Wi-Fi allowed me to stay productive. The staff was exceptionally friendly and accommodating, handling my early check-in request without any hassle. I highly recommend Grand Legacy inn hotels  for anyone looking for a reliable and comfortable stay near the airport.",
          author: "Mrs. Ananya Patel",
        },
        {
          quote: "Grand Legacy inn hotels  was the perfect choice for my overnight stay before catching an early morning flight from Lohegaon Airport. The hotel’s location is incredibly convenient, just a short drive from the terminal. The room was spacious and well-equipped, and the restaurant’s breakfast options were excellent. I was particularly impressed with the efficiency of the airport shuttle service, which made my travel experience stress-free. The staff’s warm hospitality made me feel right at home. For a comfortable and hassle-free stay near the airport, this hotel is top-notch.",
          author: "Mr. Rajiv Kumar",
        },
      ];
      
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    
      const hotelImages = [
        {
          place: "Hotel-Images/Hotel In Viman Nagar.jpg",
          text: "Hotel In Viman Nagar",
          path:'hotel-in-viman-nagar'
        },
        {
          place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
          text: "Hotels in Viman Nagar for Stay",
          path:'viman-nagar-hotels-for-stay'
        },
        {
          place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
          text: "Lodge in Viman Nagar Pune",
          path:'lodge-in-viman-nagar-pune'
        },
        {
          place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
          text: "Hotels Near Viman Nagar Airport Pune",
          path:'hotels-near-viman-nagar-airport-pune'
        },
        {
          place: "Hotel-Images/Pune Airport Hotel.jpg",
          text: "Pune Airport Hotel",
          path:'pune-airport-hotel'
        },
        {
          place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
          text: "Hotel in Wadgaon Sheri",
          path:'hotel-wadgaon-sheri'
        },
        {
          place: "Hotel-Images/Online hotel booking in pune.jpg",
          text: "Online Hotel Booking in Pune",
          path:'online-hotel-booking-pune'
        },
        {
          place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
          text: "Hotel For Stay in Lohegaon Airport",
          path:'hotel-for-stay-lohegaon-airport'
        }
    
      
      ];
    

      const schemaData = {
        "@context": "https://schema.org",
        "@type": "Hotel",
        "name": "Grand Legacy inn hotels ",
        "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
          "addressLocality": "Pune,Viman Nagar",
          "addressRegion": "MH",
          "postalCode": "411014",
          "addressCountry": "IN"
        },
        "telephone": "+91 7058638153",
        "image": "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
        "starRating": {
          "@type": "Rating",
          "bestRating": "5",
          "ratingValue": "3",
          "worstRating": "1"
        },
       
        "sameAs": [
          "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
          "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
          "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
          "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
          "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
          "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
          "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
          "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
          "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
          "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
          "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
          "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
        ]
      };
    
    return(
        <>
         <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
         <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
  
        <title>
            Contact +91 7058638153| Grand Legacy inn hotels  | Hotel For Stay in Lohegaon Airport: Cheap, Luxury, Best Options
        </title>
        <meta name="description" content="Grand Legacy inn hotels  offers a range of accommodations near Lohegaon Airport, including cheap and luxury options. Find the best hotels and lodging for your stay near Pune International Airport. Contact +91 7058638153 for bookings and more information." />
        <meta name="keywords" content="Cheap hotels near Pune Airport, Luxury hotel for stay in Lohegaon Airport, Grand Legacy inn hotels , Best Hotels Near Pune International Airport, Accommodation near Lohegaon Airport, Lodging near Pune Airport, Hotels close to Lohegaon Airport, Stay options near Pune Airport, Places to stay near Lohegaon Airport, Accommodations near Pune International Airport, Hotels in proximity to Lohegaon Airport, Overnight stay near Pune Airport, Hotels near Pune International Terminal, Lodging options near Lohegaon Airport" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Grand Legacy inn hotels  - Contact +91 7058638153 | Hotel For Stay in Lohegaon Airport: Cheap, Luxury, Best Options" />
        <meta property="og:description" content="Stay at Grand Legacy inn hotels  for the best accommodations near Lohegaon Airport. We offer cheap, luxury, and various lodging options close to Pune International Airport. For more details and bookings, call +91 7058638153." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotel for stay in lohegoan Airport.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/hotel-for-stay-lohegaon-airport" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Hotel For Stay in Lohegaon Airport</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Hotel For Stay in Lohegaon Airport
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Hotel for stay in lohegoan Airport.jpg" className="img-fluid" alt="" />
              </div>

              <h2>
                Grand Legacy inn hotels : The Premier Hotel for Your Stay Near
                Lohegaon Airport
              </h2>
              <p>
                When traveling through Pune, finding a convenient and
                comfortable hotel near Lohegaon Airport can significantly
                enhance your travel experience. Grand Legacy inn hotels  stands
                out as the ideal choice for anyone seeking premium accommodation
                in close proximity to Pune's bustling airport. Here’s why 
                Grand Legacy Inn Hotels should be at the top of your list for your next
                stay.
              </p>

              <h2>
                Experience Comfort and Convenience at Grand Legacy inn hotels 
              </h2>
              <p>
                Choosing Grand Legacy inn hotels  for your stay near Lohegaon
                Airport means opting for a blend of comfort, convenience, and
                exceptional service. Whether you're traveling for business or
                pleasure, this hotel provides the perfect base to relax before
                or after your flight.
              </p>

              <p>
                Book your stay at Grand Legacy inn hotels  today and experience
                the best of hospitality just minutes from Pune’s Lohegaon
                Airport.
              </p>

              <h2>Affordable and Comfortable Hotels Near Pune Airport</h2>
              <p>
                Finding cheap hotels near Pune Airport that don’t compromise on
                quality can be a challenge. Grand Legacy inn hotels  provides an
                affordable solution without sacrificing comfort. Our
                budget-friendly rates ensure that you get the best value for
                your money while enjoying a comfortable stay just minutes from
                the airport.
              </p>

              <h2>Luxurious Stay at Lohegaon Airport</h2>
              <p>
                For those seeking a more opulent experience, Grand Legacy inn hotels 
                 is the luxury hotel for stay in Lohegaon Airport. Our
                luxurious accommodations feature spacious rooms with premium
                amenities, including high-speed Wi-Fi, flat-screen TVs, and
                plush bedding. Enjoy an array of top-tier facilities such as a
                modern fitness center, elegant dining options, and personalized
                service that caters to your every need.
              </p>

              <h2>Best Hotels Near Pune International Airport</h2>
              <p>
                When it comes to choosing the best hotels near Pune
                International Airport, Grand Legacy inn hotels  stands out with
                its perfect blend of convenience and comfort. Our proximity to
                the airport ensures that you can easily access your flight,
                while our exceptional services and facilities make your stay
                enjoyable and stress-free. Whether you’re on a short layover or
                an extended visit, we offer the ideal base for all your travel
                needs.
              </p>

              <h2>Prime Accommodation Near Lohegaon Airport</h2>
              <p>
                Looking for accommodation near Lohegaon Airport? Grand
                Legacy Inn Hotels is strategically located to offer easy access to
                Pune’s major attractions and business districts. Our prime
                location means you’re never far from where you need to be,
                whether it’s for business or leisure.
              </p>

              <h2>Convenient Lodging Near Pune Airport</h2>
              <p>
                At Grand Legacy inn hotels , we understand the importance of
                convenient lodging near Pune Airport. Our hotel provides not
                only proximity to the airport but also a range of amenities
                designed to make your stay as comfortable and hassle-free as
                possible. Enjoy quick check-ins, reliable airport shuttle
                services, and a variety of on-site facilities to cater to all
                your travel needs.
              </p>

              <h2>Hotels Close to Lohegaon Airport</h2>
              <p>
                For those specifically searching for hotels close to Lohegaon
                Airport, Grand Legacy inn hotels  is your perfect choice. Our
                hotel’s location provides easy access to the airport, allowing
                you to maximize your time in Pune rather than spending it in
                transit. With competitive rates, luxury amenities, and
                exceptional service, we ensure that your stay is both pleasant
                and convenient.
              </p>

              <h2>Prime Accommodations Near Pune International Airport</h2>
              <p>
                Grand Legacy inn hotels  provides exceptional accommodations near
                Pune International Airport, offering a blend of comfort and
                convenience just minutes from the terminal. Our hotel is
                strategically located to ensure that you’re never far from your
                next flight or key city attractions. Whether you need a short
                stay or a longer visit, we offer various lodging options to make
                your experience seamless and enjoyable.
              </p>

              <h2>Hotels in Proximity to Lohegaon Airport</h2>
              <p>
                If you’re looking for hotels in proximity to Lohegaon Airport,
                Grand Legacy inn hotels  is your ideal choice. Our prime location
                ensures that you can reach the airport quickly and with ease,
                eliminating the stress of long commutes. Enjoy modern amenities,
                stylish rooms, and exceptional service, all while staying close
                to one of Pune's busiest travel hubs.
              </p>

              <h2>Overnight Stay Near Pune Airport</h2>
              <p>
                For travelers needing an overnight stay near Pune Airport, our
                hotel provides the perfect solution. With easy access to the
                airport and comfortable, well-appointed rooms, Hotel Grand The
                Legacy  ensures a restful night’s sleep before your journey.
                Our efficient check-in process and 24/7 service mean that your
                late-night arrival or early morning departure is handled with
                ease.
              </p>

              <h2>Hotels Near Pune International Terminal</h2>
              <p>
                Finding hotels near Pune International Terminal can be crucial
                for those with tight schedules. Grand Legacy inn hotels  offers
                proximity to the terminal along with a range of amenities
                designed to enhance your stay. Enjoy hassle-free access to the
                airport and benefit from our comprehensive services, including
                airport shuttle options, to ensure your travel is as smooth as
                possible.
              </p>

              <h2>Lodging Options Near Lohegaon Airport</h2>
              <p>
                Grand Legacy inn hotels  provides diverse lodging options near
                Lohegaon Airport to accommodate various preferences and budgets.
                Whether you’re seeking a budget-friendly stay or a luxurious
                retreat, our hotel caters to all needs with comfortable rooms,
                modern facilities, and attentive service.
              </p>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Cheap hotels near Pune Airport</p>
                      <p>- Luxury hotel for stay in Lohegaon Airport</p>
                      <p>- Grand Legacy inn hotels </p>
                      <p>- Best Hotels Near Pune International Airport</p>
                      <p>- Accommodation near Lohegaon Airport</p>
                      <p>- Lodging near Pune Airport</p>
                      <p>- Hotels close to Lohegaon Airport</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Stay options near Pune Airport</p>
                      <p>- Places to stay near Lohegaon Airport</p>
                      <p>- Accommodations near Pune International Airport</p>
                      <p>- Hotels in proximity to Lohegaon Airport</p>
                      <p>- Overnight stay near Pune Airport</p>
                      <p>- Hotels near Pune International Terminal</p>
                      <p>- Lodging options near Lohegaon Airport</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels  for Your Stay Near Lohegaon
                Airport?
              </h2>

              <p>
                When traveling through Pune, finding the perfect place to stay
                near Lohegaon Airport is crucial for a smooth and stress-free
                experience. Grand Legacy inn hotels  stands out as an exceptional
                choice for several reasons. Here’s why you should consider us
                for your next stay:
              </p>

              <h2>1. Prime Location</h2>
              <p>
                Grand Legacy inn hotels  is ideally situated just minutes away
                from Lohegaon Airport, making it incredibly convenient for
                travelers. Whether you have an early morning flight or a
                late-night arrival, our hotel’s proximity ensures that you can
                easily reach the airport without the hassle of long commutes.
              </p>

              <h2>2. Comfortable and Modern Accommodations</h2>
              <p>
                Our hotel offers a range of comfortable and modern
                accommodations, designed to provide a relaxing environment after
                your journey. Each room is thoughtfully furnished with:
              </p>
              <ul>
                <li>
                  <strong>High-Speed Wi-Fi:</strong> Stay connected with
                  complimentary high-speed internet.
                </li>
                <li>
                  <strong>Flat-Screen TVs:</strong> Enjoy your favorite shows
                  and movies.
                </li>
                <li>
                  <strong>Comfortable Bedding:</strong> Rest easy on plush,
                  high-quality bedding.
                </li>
                <li>
                  <strong>Spacious Interiors:</strong> Enjoy ample space to
                  unwind and relax.
                </li>
              </ul>

              <h2>3. Exceptional Service</h2>
              <p>
                At Grand Legacy inn hotels , exceptional service is at the heart
                of everything we do. Our friendly and professional staff are
                dedicated to making your stay as enjoyable as possible. From a
                seamless check-in process to personalized assistance with your
                travel needs, we go the extra mile to ensure your comfort.
              </p>

              <h2>4. Modern Amenities</h2>
              <p>
                Our hotel is equipped with a variety of modern amenities to
                cater to both business and leisure travelers, including:
              </p>
              <ul>
                <li>
                  <strong>Business Center:</strong> Ideal for work-related tasks
                  and meetings.
                </li>
                <li>
                  <strong>Fitness Center:</strong> Keep up with your fitness
                  routine during your stay.
                </li>
                <li>
                  <strong>Restaurant and Dining Options:</strong> Savor a range
                  of delicious meals and beverages at our on-site dining
                  facilities.
                </li>
                <li>
                  <strong>Airport Shuttle Service:</strong> Convenient shuttle
                  services to and from Lohegaon Airport for added ease.
                </li>
              </ul>

              <h2>5. Competitive Rates</h2>
              <p>
                We offer competitive rates that provide excellent value for
                money. Whether you’re looking for budget-friendly options or a
                more luxurious experience, Grand Legacy inn hotels  has something
                to suit your needs. Our transparent pricing ensures you know
                exactly what to expect.
              </p>

              <h2>6. Easy Access to Local Attractions</h2>
              <p>
                While being close to the airport is a major advantage, our
                location also provides easy access to Pune’s local attractions.
                Explore cultural sites, shopping areas, and dining options with
                ease, making the most of your time in the city.
              </p>

              <h2>7. Cleanliness and Hygiene</h2>
              <p>
                We prioritize cleanliness and hygiene to ensure a safe and
                pleasant stay. Our housekeeping staff maintains high standards
                of cleanliness throughout the hotel, providing peace of mind
                during your visit.
              </p>

              <h2>FAQs for Grand Legacy inn hotels : Your Top Hotel for Stay Near Lohegaon Airport</h2>
              {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className={`faq-question ${
                          activeIndex === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {index + 1}. {faq.question}
                      </div>
                      {activeIndex === index && (
                        <div className="faq-answer">{faq.answer}</div>
                      )}
                    </div>
                  ))}


                   {/* ======================== */}
                <div className="">
                  <h2>
                    Testimonials for Hotel in Viman Nagar Pune -  Grand
                    Legacy Inn Hotels: -{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                {/* ================= */}
                <div className=" contact-section">
                  <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                  <p>
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong>Mobile No:</strong>{" "}
                    <a href="tel:+917058638153">+91 7058638153</a>
                  </p>
                  <p>
                    <strong>Email Id:</strong>{" "}
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                     reservation@grandlegacyinnhotels.com
                    </a>
                  </p>
                </div>
            </div>

            <div className="col-12 col-md-4">
            <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          
            </div>
            </div>

            
      </div>
    </>
  );
}
