import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";


export default function HotelInWadgaonSheri() {
    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const faqs = [
    {
      question: "What are the check-in and check-out times at Grand Legacy inn hotels ?",
      answer: `Check-in time at Grand Legacy inn hotels  is from 2:00 PM, and check-out time is until 12:00 PM. If you require an early check-in or late check-out, please contact our front desk in advance. We will do our best to accommodate your request based on availability.`
    },
    {
      question: "Is parking available at the hotel?",
      answer: `Yes, Grand Legacy inn hotels  provides complimentary parking for guests. Our parking facility is secure and conveniently located near the hotel entrance. If you have any special parking needs or require valet service, please let us know upon arrival.`
    },
    {
      question: "What amenities are included in the rooms?",
      answer: (
        <>
          Our rooms at Grand Legacy inn hotels  are equipped with a range of amenities to ensure a comfortable stay, including:
          <ul>
            <li>High-speed internet access</li>
            <li>Flat-screen TV with satellite channels</li>
            <li>Air conditioning</li>
            <li>Minibar</li>
            <li>Tea and coffee making facilities</li>
            <li>In-room safe</li>
            <li>Well-appointed bathroom with toiletries</li>
            <li>Plush bedding and seating area</li>
          </ul>
        </>
      )
    },
    {
      question: "Does the hotel have a restaurant or dining options?",
      answer: `Yes, Grand Legacy inn hotels  features an on-site restaurant that offers a diverse menu of local and international cuisines. We also provide 24/7 room service for guests who prefer to dine in the comfort of their rooms. For more details on our dining options, please refer to our restaurant menu or speak with our staff.`
    },
    {
      question: "Is the hotel suitable for families?",
      answer: `Absolutely! Grand Legacy inn hotels  is family-friendly and offers amenities such as spacious rooms, extra beds, cribs, and kid-friendly dining options. Our central location also provides easy access to family-friendly attractions in and around Wadgaon Sheri. If you have specific needs or requests for your family stay, please let us know in advance.`
    },
    {
      question: "Can I book a meeting or event at the hotel?",
      answer: `Yes, Grand Legacy inn hotels  offers versatile meeting and event spaces equipped with modern facilities. Whether you're hosting a business meeting, seminar, or social event, our team can assist with planning and coordination. For more information on booking our event spaces and services, please contact our events team.`
    },
    {
      question: "Are there any special packages or promotions available?",
      answer: `Grand Legacy inn hotels  regularly offers special packages and promotions to provide additional value to our guests. These may include discounts on room rates, dining offers, and exclusive deals. To find out about our current offers and packages, please visit our website or contact our reservations team directly.`
    },
    {
      question: "Is Wi-Fi available at the hotel?",
      answer: `Yes, complimentary high-speed Wi-Fi is available throughout Grand Legacy inn hotels , including in guest rooms and public areas. If you experience any issues with connectivity or need assistance with internet access, please reach out to our front desk.`
    },
    {
      question: "What are the transportation options available near the hotel?",
      answer: `Grand Legacy inn hotels  is conveniently located with easy access to various transportation options, including taxis, ride-sharing services, and public transit. If you require assistance with arranging transportation or have specific travel needs, our concierge team is happy to help.`
    },
    {
      question: "Does the hotel have a fitness center?",
      answer: `Yes, Grand Legacy inn hotels  features a well-equipped fitness center where guests can maintain their workout routine. Our fitness center includes modern exercise equipment and is open to guests during convenient hours. For more information on fitness center facilities and operating hours, please contact our front desk.`
    }
  ];
  const testimonials = [
    {
      quote: "I recently stayed at Grand Legacy inn hotels  in Wadgaon Sheri, and it was an exceptional experience. The location is perfect, with easy access to local attractions and business centers. The room was beautifully designed, clean, and equipped with all the amenities I needed for a comfortable stay. What truly stood out was the attentive and friendly staff, who made sure every detail was taken care of. The on-site restaurant served delicious food, and the fitness center was a great addition. I highly recommend this hotel for anyone visiting Pune!",
      author: "Mrs. Anjali Patel"
    },
    {
      quote: "Grand Legacy inn hotels  in Wadgaon Sheri exceeded my expectations in every way. The luxury accommodations were top-notch, and the staff went above and beyond to ensure a pleasant stay. From the modern and spacious room to the excellent dining options, every aspect of the hotel was designed with guest comfort in mind. I also appreciated the family-friendly environment and the convenient location. It’s a fantastic choice for both business and leisure travelers. I’ll definitely be staying here again on my next visit to Pune!",
      author: "Mr. Ravi Kumar"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    "name": "Grand Legacy inn hotels ",
    "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      "addressLocality": "Pune,Viman Nagar",
      "addressRegion": "MH",
      "postalCode": "411014",
      "addressCountry": "IN"
    },
    "telephone": "+91 7058638153",
    "image": "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    "starRating": {
      "@type": "Rating",
      "bestRating": "5",
      "ratingValue": "3",
      "worstRating": "1"
    },
   
    "sameAs": [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ]
  };

return(
    <>
    
    
        <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">
      {JSON.stringify(schemaData)}
    </script>
  
        <title>
          Contact:+91 7058638153 | Grand Legacy inn hotels  | Hotels in Wadgaon Sheri Pune
        </title>
        <meta name="description" content="Looking for a hotel in Wadgaon Sheri, Pune? Book luxury and budget hotels for couples and families at Grand Legacy inn hotels . Contact +91 7058638153 for reservations." />
        <meta name="keywords" content="Wadgaon Sheri Hotel, Hotels in Wadgaon Sheri, Luxury hotel in Wadgaon Sheri, Budget hotels in Wadgaon Sheri Pune, Hotel for couples in Wadgaon Sheri, Hotel for family in Wadgaon Sheri, Best hotels in Wadgaon Sheri Pune, Budget hotels in Wadgaon Sheri Pune with price, lodge in Wadgaon Sheri, Grand Legacy inn hotels " />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="+91 7058638153 | Grand Legacy inn hotels  | Hotels in Wadgaon Sheri Pune" />
        <meta property="og:description" content="Find the best luxury and budget hotels in Wadgaon Sheri, Pune. Grand Legacy inn hotels  offers comfortable stays for families and couples. Call +91 7058638153 for bookings." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotel In Wadgoan Sheri.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/hotel-wadgaon-sheri" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1> Hotel in Wadgaon Sheri</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span> Hotel in Wadgaon Sheri
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Hotel In Wadgoan Sheri.jpg" className="img-fluid" alt="Hotel In Wadgoan Sheri" />
              </div>
              <h2>
                Discover Grand Legacy inn hotels : Premier Hotel in Wadgaon Sheri
              </h2>
              <p>
                If you’re looking for a luxurious and comfortable stay in Pune,
                Grand Legacy inn hotels  in Wadgaon Sheri stands out as a top
                choice. Nestled in one of Pune's vibrant neighborhoods, this
                hotel combines elegance, convenience, and superior service to
                offer an unparalleled experience. Here’s why  Grand
                Legacy Inn Hotels is the ideal destination for your next stay in Wadgaon
                Sheri.
              </p>

              <h2>Wadgaon Sheri Hotel: Why We Stand Out</h2>
              <p>
                Grand Legacy inn hotels  is renowned for offering a superior
                experience in Wadgaon Sheri. Here’s why our hotel is highly
                regarded:
              </p>
              <ul>
                <li>
                  <strong>Elegant Accommodations:</strong> Our rooms and suites
                  are designed with sophistication and comfort in mind. Enjoy
                  plush bedding, modern decor, and high-end amenities that
                  provide a luxurious retreat in the heart of Wadgaon Sheri.
                </li>
                <li>
                  <strong>Prime Location:</strong> Strategically located in
                  Wadgaon Sheri, our hotel offers convenient access to major
                  business hubs, shopping centers, and local attractions, making
                  it an ideal base for both work and exploration.
                </li>
              </ul>

              <h2>Luxury Hotel in Wadgaon Sheri</h2>
              <ul>
                <li>
                  <strong>Unmatched Luxury:</strong> As a luxury hotel in
                  Wadgaon Sheri, Grand Legacy inn hotels  delivers an opulent
                  experience with exceptional facilities. From spacious rooms to
                  gourmet dining, every detail is designed to ensure a memorable
                  stay.
                </li>
                <li>
                  <strong>Exclusive Amenities:</strong> Indulge in premium
                  amenities including high-speed internet, flat-screen TVs, a
                  well-equipped fitness center, and more. Our on-site restaurant
                  offers a diverse menu to cater to all tastes.
                </li>
              </ul>

              <h2>Hotel in Wadgaon Sheri for Couples</h2>
              <ul>
                <li>
                  <strong>Romantic Getaways:</strong> Looking for a hotel in
                  Wadgaon Sheri for a romantic escape? Grand Legacy inn hotels 
                  provides the perfect setting for couples with its luxurious
                  accommodations and elegant ambiance.
                </li>
                <li>
                  <strong>Special Packages:</strong> Take advantage of our
                  special packages designed for couples, featuring romantic
                  dining options and exclusive amenities. Create lasting
                  memories with a stay tailored to your needs.
                </li>
              </ul>

              <h2>Hotel in Wadgaon Sheri for Families</h2>
              <ul>
                <li>
                  <strong>Family-Friendly Features:</strong> Families will find
                  Grand Legacy inn hotels  to be an excellent choice with its
                  spacious rooms and family-oriented services. We offer extra
                  beds, cribs, and kid-friendly dining options to ensure a
                  comfortable stay for all family members.
                </li>
                <li>
                  <strong>Convenient Location:</strong> Our central location
                  provides easy access to family-friendly attractions, making it
                  convenient for exploring the area with your loved ones.
                </li>
              </ul>

              <h2>Budget Hotels in Wadgaon Sheri Pune</h2>
              <ul>
                <li>
                  <strong>Affordable Luxury:</strong> While we offer a luxury
                  experience, Grand Legacy inn hotels  remains an affordable
                  choice among budget hotels in Wadgaon Sheri Pune. Enjoy
                  high-quality accommodations at competitive rates.
                </li>
                <li>
                  <strong>Value for Money:</strong> We provide exceptional value
                  with our well-priced rooms and special offers. Check our
                  website or contact us for the latest deals and promotions
                  designed to fit your budget.
                </li>
              </ul>
              <h2>Budget Hotels in Wadgaon Sheri Pune with Price</h2>
              <ul>
                <li>
                  <strong>Affordable Luxury:</strong> Grand Legacy inn hotels 
                  stands out among budget hotels in Wadgaon Sheri Pune with
                  price for its exceptional value. We offer competitive rates
                  without compromising on quality, ensuring you receive a
                  luxurious experience at a budget-friendly price.
                </li>
                <li>
                  <strong>Transparent Pricing:</strong> We believe in providing
                  clear and transparent pricing. Our rates are designed to offer
                  great value, and we frequently feature special promotions to
                  make luxury more accessible. Check our website or contact us
                  directly to find the best deals available.
                </li>
                <li>
                  <strong>Comfort and Quality:</strong> Even as a
                  budget-friendly option, our hotel maintains high standards of
                  comfort and quality. Enjoy spacious rooms with modern
                  amenities, ensuring a pleasant stay without exceeding your
                  budget.
                </li>
              </ul>

              <h2>Good Hotels in Wadgaon Sheri</h2>
              <ul>
                <li>
                  <strong>Top-Rated Experience:</strong> Grand Legacy inn hotels 
                  is recognized as one of the good hotels in Wadgaon Sheri due
                  to our commitment to delivering an outstanding guest
                  experience. Our dedication to excellence is reflected in our
                  high ratings and positive reviews from satisfied guests.
                </li>
                <li>
                  <strong>Exceptional Service:</strong> From the moment you
                  arrive, our professional and attentive staff are here to
                  ensure that your stay is comfortable and enjoyable. We
                  prioritize guest satisfaction and aim to exceed your
                  expectations with every visit.
                </li>
                <li>
                  <strong>Prime Location:</strong> Our central location in
                  Wadgaon Sheri offers convenient access to business districts,
                  shopping areas, and local attractions. Whether you’re in town
                  for work or leisure, our location provides easy access to
                  everything you need.
                </li>
              </ul>

              <h2>Best Hotels in Wadgaon Sheri Pune</h2>
              <ul>
                <li>
                  <strong>Luxury and Elegance:</strong> When it comes to the
                  best hotels in Wadgaon Sheri Pune, Grand Legacy inn hotels 
                  stands out for its blend of luxury and sophistication. Our
                  elegantly designed rooms, top-notch amenities, and attentive
                  service make us a premier choice for discerning travelers.
                </li>
                <li>
                  <strong>Comprehensive Amenities:</strong> Enjoy a range of
                  amenities including high-speed internet, a well-equipped
                  fitness center, and an on-site restaurant serving a variety of
                  cuisines. Our facilities are designed to enhance your stay and
                  provide the ultimate in comfort and convenience.
                </li>
                <li>
                  <strong>Exclusive Offers:</strong> To ensure you get the best
                  value, we offer exclusive deals and packages. Keep an eye on
                  our website or contact our reservations team for the latest
                  offers and promotions.
                </li>
              </ul>

              <h2>Lodge in Wadgaon Sheri</h2>
              <ul>
                <li>
                  <strong>Comfortable Lodging:</strong> For those seeking a
                  lodge in Wadgaon Sheri, Grand Legacy inn hotels  provides a
                  comfortable and sophisticated lodging experience. Our rooms
                  are designed to offer a cozy and stylish environment, perfect
                  for both short and extended stays.
                </li>
                <li>
                  <strong>Personalized Service:</strong> Our dedicated staff
                  offer personalized service to make your stay as enjoyable as
                  possible. From check-in to check-out, we are committed to
                  ensuring that your needs are met and that you have a memorable
                  experience.
                </li>
                <li>
                  <strong>Ideal for All Travelers:</strong> Whether you’re
                  traveling for business, leisure, or a special occasion, our
                  hotel offers the perfect setting. Our range of accommodations
                  and services ensures that all travelers have a comfortable and
                  enjoyable stay.
                </li>
              </ul>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Wadgaon Sheri Hotel</p>
                      <p>- Wadgaon Sheri hotels</p>
                      <p>- Luxury hotel in Wadgaon Sheri</p>
                      <p>- Hotel in Wadgaon Sheri for couples</p>
                      <p>- Hotel in Wadgaon Sheri for family</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Budget hotels in Wadgaon Sheri Pune</p>
                      <p>- Budget hotels in Wadgaon Sheri Pune with price</p>
                      <p>- Good Hotels in Wadgaon Sheri</p>
                      <p>- Best hotels in Wadgaon Sheri Pune</p>
                      <p>- Lodge in Wadgaon Sheri</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels  for Your Stay in Wadgaon
                Sheri?
              </h2>
              <p>
                When selecting a hotel in Wadgaon Sheri, Grand Legacy inn hotels 
                emerges as the premier choice for discerning travelers. Here’s
                why our hotel stands out and why it should be your top choice
                for accommodation in Wadgaon Sheri.
              </p>

              <h2>1. Prime Location</h2>
              <ul>
                <li>
                  <strong>Strategic Convenience:</strong> Grand Legacy inn hotels 
                  is ideally located in the heart of Wadgaon Sheri, offering
                  unparalleled convenience. Whether you’re visiting for
                  business, leisure, or family gatherings, our central location
                  provides easy access to major business districts, shopping
                  centers, and popular local attractions.
                </li>
                <li>
                  <strong>Accessibility:</strong> Our location ensures seamless
                  connectivity to other parts of Pune, making it an excellent
                  base for exploring the city or attending meetings and events.
                </li>
              </ul>

              <h2>2. Luxurious Accommodations</h2>
              <ul>
                <li>
                  <strong>Elegant Rooms and Suites:</strong> Our hotel boasts a
                  range of beautifully designed rooms and suites, each crafted
                  with luxury and comfort in mind. Enjoy plush bedding,
                  sophisticated decor, and modern amenities that cater to both
                  relaxation and productivity.
                </li>
                <li>
                  <strong>Top-Notch Amenities:</strong> Experience high-speed
                  internet, flat-screen TVs, minibars, and well-appointed
                  bathrooms. Each room is designed to provide a haven of
                  tranquility amidst the bustling city.
                </li>
              </ul>

              <h2>3. Exceptional Dining Experience</h2>
              <ul>
                <li>
                  <strong>In-House Restaurant:</strong> Our on-site restaurant
                  offers a diverse menu of local and international cuisines,
                  ensuring a delightful dining experience. Whether you’re
                  enjoying a full meal or a quick snack, our restaurant provides
                  a gourmet experience right at your doorstep.
                </li>
                <li>
                  <strong>24/7 Room Service:</strong> For those who prefer
                  dining in the comfort of their room, we offer 24-hour room
                  service with a wide range of dishes to suit your taste and
                  schedule.
                </li>
              </ul>

              <h2>4. Outstanding Facilities</h2>
              <ul>
                <li>
                  <strong>Fitness Center:</strong> Stay active during your visit
                  with our state-of-the-art fitness center. Equipped with the
                  latest exercise equipment, it’s easy to maintain your fitness
                  routine while traveling.
                </li>
                <li>
                  <strong>Business Services:</strong> For business travelers, we
                  offer comprehensive facilities including a business center,
                  meeting rooms, and high-speed internet. Our services are
                  designed to support your professional needs and ensure a
                  productive stay.
                </li>
              </ul>

              <h2>5. Impeccable Service</h2>
              <ul>
                <li>
                  <strong>Personalized Guest Care:</strong> At  Grand
                  Legacy Inn Hotels, we pride ourselves on providing exceptional service.
                  Our attentive staff are dedicated to ensuring your stay is
                  comfortable and enjoyable, from personalized check-in
                  experiences to assistance with special requests.
                </li>
                <li>
                  <strong>24/7 Assistance:</strong> Our team is available around
                  the clock to address any needs or concerns you may have,
                  making your stay as seamless and pleasant as possible.
                </li>
              </ul>

              <h2>6. Affordable Luxury</h2>
              <ul>
                <li>
                  <strong>Value for Money:</strong> While offering a luxury
                  experience, we provide competitive rates that ensure you
                  receive exceptional value for your money. Enjoy premium
                  accommodations and services without exceeding your budget.
                </li>
                <li>
                  <strong>Special Offers:</strong> We frequently feature
                  exclusive deals and promotions. Keep an eye on our website or
                  contact our reservations team to take advantage of the latest
                  offers and packages.
                </li>
              </ul>

              <h2>7. Family-Friendly Environment</h2>
              <ul>
                <li>
                  <strong>Spacious Accommodations:</strong> Our hotel is
                  well-suited for families with spacious rooms and
                  family-friendly amenities. We offer extra beds, cribs, and
                  kid-friendly dining options to make your stay comfortable and
                  convenient.
                </li>
                <li>
                  <strong>Convenient Location:</strong> With easy access to
                  family-friendly attractions and amenities, Grand
                  Legacy Inn Hotels is an excellent choice for a family stay in Wadgaon
                  Sheri.
                </li>
              </ul>

              <div className="">
                  <h2>
                    FAQs for Grand Legacy inn hotels  - Hotel in Wadgaon Sheri
                  </h2>
                  {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className={`faq-question ${
                          activeIndex === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {index + 1}. {faq.question}
                      </div>
                      {activeIndex === index && (
                        <div className="faq-answer">{faq.answer}</div>
                      )}
                    </div>
                  ))}
                </div>

                 {/* ======================== */}
                 <div className="">
                  <h2>
                    Testimonials for Hotel in Viman Nagar Pune - Grand
                    Legacy Inn Hotels: -{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>
         
                <div className=" contact-section">
                  <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                  <p>
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong>Mobile No:</strong>{" "}
                    <a href="tel:+917058638153">+91 7058638153</a>
                  </p>
                  <p>
                    <strong>Email Id:</strong>{" "}
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                     reservation@grandlegacyinnhotels.com
                    </a>
                  </p>
                </div>
                {/* ================= */}
            </div>
            <div class="col-12 col-md-4">
            <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
