import React, { useState } from "react";
import "./BookingForm.css"; // Import the CSS file for styling
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";

const BookingForm = () => {
  

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "a1d04e93-a437-49db-af77-efb9fcb48808");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Success",
        text: "Message Sent Successfully",
        icon: "success"
      });
    }
    event.target.reset();
  };

  return (
    <>
     {/* <!-- Page Banner Start --> */}
     <div
        class="page__banner"
        data-background="./img/banner/page-banner-1.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Online Booking</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                  
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Online Booking
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div className="booking-form-container">
      <h2 className="bookHead">Online Booking</h2>
      <form onSubmit={onSubmit}>
        <div class="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Enter your full name"
              required
            />
          </div>

          <div className="form-group  col-12 col-md-6">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="form-group  col-12 col-md-6">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"     
              placeholder="Enter your phone number"
              required
            />
          </div>

          <div className="form-group  col-12 col-md-6">
            <label htmlFor="checkIn">Check-In Date</label>
            <input
              type="date"
              id="checkIn"
              name="checkIn"
              required
            />
          </div>

          <div className="form-group  col-12 col-md-6">
            <label htmlFor="checkOut">Check-Out Date</label>
            <input
              type="date"
              id="checkOut"
              name="checkOut"
              required
            />
          </div>

          <div className="form-group  col-12 col-md-6">
            <label htmlFor="roomType">Room Type</label>
            <select
              id="roomType"
              name="roomType"
              required
            >
              <option value="" disabled>
                Select room type
              </option>
              <option value="single">Single Room</option>
              <option value="double">Double Room</option>
              <option value="suite">Suite</option>
            </select>
          </div>

          <div className="form-group  col-12 col-md-6">
            <label htmlFor="adults">Adults</label>
            <input
              type="number"
              id="adults"
              name="adults"
              min="1"
              required
            />
          </div>

          <div className="form-group  col-12 col-md-6">
            <label htmlFor="children">Children</label>
            <input
              type="number"
              id="children"
              name="children"
              min="0"
            />
          </div>

          <div className="form-group  col-12">
            <label htmlFor="specialRequests">Special Requests</label>
            <textarea
              id="specialRequests"
              name="specialRequests"
              placeholder="Any special requests?"
            />
          </div>
        </div>
         <div className="col-3 m-auto  text-center">
         <div class="header__area-menubar-right-box">
          <div class="header__area-menubar-right-box-btn text-center">
          <button class="theme-btn" type="submit">Book Now<i class="fal fa-long-arrow-right"></i></button>
          </div>
        </div>
         </div>
        
      </form>
    </div>
    </>
  );
};

export default BookingForm;
