import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export default function Contact(){
    const schemaData = {
        "@context": "https://schema.org",
        "@type": "Hotel",
        "name": "Grand Legacy inn hotels ",
        "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
          "addressLocality": "Pune,Viman Nagar",
          "addressRegion": "MH",
          "postalCode": "411014",
          "addressCountry": "IN"
        },
        "telephone": "+91 7058638153",
        "image": "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
        "starRating": {
          "@type": "Rating",
          "bestRating": "5",
          "ratingValue": "3",
          "worstRating": "1"
        },
       
        "sameAs": [
           "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
        ]
      };


      const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "a1d04e93-a437-49db-af77-efb9fcb48808");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
    
        if (res.success) {
          Swal.fire({
            title: "Success",
            text: "Message Sent Successfully",
            icon: "success"
          });
        }
        event.target.reset();
      };
    
    return(
        <>
         <Helmet>
             <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
         <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
   
        <title>Contact:+91 7058638153 | Grand Legacy inn hotels  |Contact | Viman Nagar Hotel Booking and Stay Options| Hotel Near Me Pune Airport
        | Hotels Near Kharadi | Hotel in Koregaon Park | Best Hotels in Pune for Couples</title>
        <meta name="description" content="Book the best hotels in Viman Nagar, Pune for your stay. Find couple-friendly hotels, business hotels, 3-star and 4-star hotels near Pune International Airport. Call +91 7058638153 for bookings." />
        <meta name="keywords" content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, Business hotels in Viman Nagar, Best hotels in Viman Nagar Pune, Couple-friendly hotels in Viman Nagar, 3-star hotels in Viman Nagar, Hotels near Pune International Airport, 3-star hotels near Pune airport, Hotels near Pune domestic airport, 4-star hotels near Pune airport, Best hourly hotels near Pune International Airport" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="+91 7058638153 | Grand Legacy inn hotels  |Contact | Viman Nagar Hotel Booking | Hotel Near Me Pune Airport
         Hotels Near Kharadi" />
        <meta property="og:description" content="Book your stay at Grand Legacy inn hotels , one of the best hotels in Viman Nagar. We offer couple-friendly and business hotel rooms, as well as 3-star and 4-star hotel options near Pune Airport. Call +91 7058638153." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/contact" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
 
          {/* <!-- Page Banner Start --> */}
    <div class="page__banner" data-background="assets/img/banner/page-banner-9.jpg">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="page__banner-title">
                        <h1>Contact Us</h1>
                        <div class="page__banner-title-menu">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>-</span>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	{/* <!-- Page Banner End --> */}
    {/* <!-- Contact Area Start --> */}
	<div class="contact__area section-padding pb-0">
		<div class="container">
			<div class="row">
				<div class="col-xl-5 col-lg-5 lg-mb-30">
					<div class="contact__area-title">
						<h3 class="mb-25 textgreen">Contact Info</h3> 
                        <p>Nestled in the heart of Pune, Grand Legacy inn hotels  is committed to providing an unparalleled stay experience with luxurious amenities, impeccable service, and comfort. Whether you're traveling for business or leisure, we're here to make your stay memorable.</p>
                    </div>
                    <div class="contact__area-info">
                        <div class="contact__area-info-item">
                            <div class="contact__area-info-item-icon">
                                <i class="fal fa-phone-alt"></i>
                            </div>
                            <div class="contact__area-info-item-content">
								<span>Phone Number</span>
                                <h6><a href="tel:+91 7058638153">+91 7058638153</a></h6>
                            </div>
                        </div>
                        <div class="contact__area-info-item">
                            <div class="contact__area-info-item-icon">
                                <i class="fal fa-envelope"></i>
                            </div>
                            <div class="contact__area-info-item-content email-container">
								<span>Email</span>
                        
                                <p className='fs-5'><a  href="  mailto:reservation@grandlegacyinnhotels.com">reservation@grandlegacyinnhotels.com</a></p>
                            </div>
                        </div>
                        <div class="contact__area-info-item">
                            <div class="contact__area-info-item-icon">
                                <i class="fal fa-map-marker-alt"></i>
                            </div>
                            <div class="contact__area-info-item-content">
								<span>Address</span>
                                <h6><a href="/"> Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, <br/> Pune - 411014</a></h6>
                            </div>
                        </div>
                    </div>
                   
				</div>
				<div class="col-xl-7 col-lg-7">
    
                    <div class="contact__area-form">
                        <h3 class="mb-35 textgreen contact-head">Get In Touch</h3>
                        <form onSubmit={onSubmit}>	
                            <div class="row">
                                <div class="col-sm-12 mb-30"> 
                                    <div class="contact__area-form-item">
                                        <i class="fal fa-user"></i>
                                        <input type="text" name="name" placeholder="Full Name" required="required"/>
                                    </div>										
                                </div>
                                <div class="col-sm-12 mb-30">
                                    <div class="contact__area-form-item">
                                        <i class="far fa-envelope-open"></i>
                                        <input type="email" name="email" placeholder="Email Address" required="required"/>											
                                    </div>									
                                </div>
                                <div class="col-sm-12 mb-30"> 
                                    <div class="contact__area-form-item">
                                        <i class="far fa-phone-alt"></i>
                                        <input type="text" name="phone" placeholder="Phone" required="required"/>
                                    </div>										
                                </div>
    

                                <div class="col-sm-12 mb-30"> 
                                    <div class="contact__area-form-item">
                                        <i class="far fa-comments"></i>
                                        <textarea name="message" placeholder="Type your message here...."></textarea>
                                    </div>										
                                </div>
                                <div class="col-lg-12">										
                                    <div class="contact__area-form-item">
                                        <button class="theme-btn" type="submit">Submit Now<i class="fal fa-long-arrow-right"></i></button>
                                    </div>										
                                </div>
                            
                            </div>							
                        </form>
                    </div>
				</div>
			</div>
            <div class="row">
				<div class="col-xl-12">
					<div class="contact__area-map section-padding">
                    <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d945.5653221956909!2d73.91605056947952!3d18.56225527024742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c147b8b3a3bf%3A0x6f7fdcc8e4d6c77e!2sPhoenix%20Marketcity%20Pune!5e0!3m2!1sen!2sin!4v1728879702986!5m2!1sen!2sinn"
                          width="600"
                          height="450"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
					</div>
				</div>
            </div>
		</div>
	</div>
        </>
    )
}