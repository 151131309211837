import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Policy() {
  
  

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact:+91 7058638153 | Grand Legacy inn hotels  |Policy | Viman Nagar
          Hotel Booking and Stay Options| Hotel Near Me Pune Airport | Hotels
          Near Kharadi | Hotel in Koregaon Park | Best Hotels in Pune for
          Couples
        </title>
        <meta
          name="description"
          content="Book the best hotels in Viman Nagar, Pune for your stay. Find couple-friendly hotels, business hotels, 3-star and 4-star hotels near Pune International Airport. Call +91 7058638153 for bookings."
        />
        <meta
          name="keywords"
          content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, Business hotels in Viman Nagar, Best hotels in Viman Nagar Pune, Couple-friendly hotels in Viman Nagar, 3-star hotels in Viman Nagar, Hotels near Pune International Airport, 3-star hotels near Pune airport, Hotels near Pune domestic airport, 4-star hotels near Pune airport, Best hourly hotels near Pune International Airport"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="+91 7058638153 | Grand Legacy inn hotels  |Policy | Viman Nagar Hotel Booking | Hotel Near Me Pune Airport
         Hotels Near Kharadi"
        />
        <meta
          property="og:description"
          content="Book your stay at Grand Legacy inn hotels , one of the best hotels in Viman Nagar. We offer couple-friendly and business hotel rooms, as well as 3-star and 4-star hotel options near Pune Airport. Call +91 7058638153."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg"
        />
        <meta
          property="og:url"
          content="https://www.grandlegacyinnhotels.com/policy"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Privacy & Policy</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                  
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Privacy & Policy
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}
      <section className="accommodations__area  section-padding">
      <div className="container  policy-section">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-md-8">
            <div className="myImage">
              <img src="Hotel-Images/Privacy & Policy.jpg" alt="" />
            </div>
            <h2>Privacy & Policy</h2>

            <div>
              {/* <p>Privacy & Policy for Hotel Grand Legacy Inns : </p> */}
              <h2>Grand Legacy inn hotels  Luxury Hotel Privacy Policy</h2>
        
        <p>Grand Legacy inn hotels  Luxury Hotel is committed to ensuring the privacy and security of the personal information collected from guests for reservation purposes. When guests provide their name, contact details, and payment information during the reservation process, it is essential that this information is accurate and complete.</p>
        
        <p>The hotel strictly utilizes personal information for reservation-related activities, aiming to enhance the overall guest experience during their stay. This may involve communication for reservation confirmations, updates, and, with guest consent, marketing communications.</p>
        
        <p>Security is paramount, and the hotel employs industry-standard measures to maintain the confidentiality of guest information, restricting access to authorized personnel only.</p>
        
        <p>Guest information is not disclosed to third parties unless legally required or for reservation-related purposes. Trusted service providers may be involved for specific tasks such as payment processing, ensuring that guests' data remains secure and used appropriately.</p>

        <p>In terms of online interactions, the hotel's website may utilize cookies to improve the user experience. However, users have the option to disable cookies in their browser settings, though it is important to note that this might impact the functionality of the website.</p>

        <p>For marketing communications, the hotel seeks explicit consent from guests before using their contact information. Guests also retain the right to opt-out of such communications at any time, and clear instructions for unsubscribing are provided in the communication.</p>

        <p>Grand Legacy inn hotels  Luxury Hotel recognizes the importance of guests' rights to access and correct their personal information. Guests can exercise these rights by contacting the hotel directly.</p>

        <p>As part of its commitment to transparency, the hotel retains the right to update its Privacy Policy. Any changes will be communicated through the hotel's website, and the revised Privacy Policy will include an updated effective date.</p>

        <p>This proactive approach ensures that guests are informed about how their data is handled and gives them the confidence that the hotel is dedicated to maintaining the highest standards of privacy and security.</p>
              

              <div className="contact-section">
                <h2>Grand Legacy inn hotels  Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <h2>Recent Post</h2>
            {hotelImages.map((e) => {
              return (
                <div className="box1">
                  <a
                    href={e.path}
                    className="d-flex justify-content-around align-items-center"
                  >
                    <div className="b1">
                      <img src={e.place} alt="" />
                    </div>
                    <div className="b2">
                      {" "}
                      <a href={e.path}>{e.text}</a>
                    </div>
                  </a>
                </div>
              );
            })}

            <div className="">
              <h5 className="pb-3 pt-3">Contact Information</h5>

              <div className="services__two-item">
                <div className="mybox1 text-center p-3 mb-3">
                  <a href="tel:+91 7058638153">
                    <i class="bi bi-telephone-fill fs-1"></i>
                    <div>
                      <a href="tel:+91 7058638153">+91 7058638153</a>
                    </div>
                  </a>
                </div>

<div className="mybox1 text-center p-3 mb-3">
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                    <i class="bi bi-envelope fs-1"></i>
                    <div className="">
                      <a href="  mailto:reservation@grandlegacyinnhotels.com">
                         reservation@grandlegacyinnhotels.com
                        <br />
                        
                      </a>
                    </div>
                  </a>
                </div>
                <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
}
