import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

export default function HotelInKharadi() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
          question: 'What are the check-in and check-out times at Grand Legacy inn hotels ?',
          answer: 'Check-in time at Grand Legacy inn hotels  is from 2:00 PM, and check-out time is by 12:00 PM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on availability.',
        },
        {
          question: 'Is Grand Legacy inn hotels  suitable for business travelers?',
          answer: 'Yes, Grand Legacy inn hotels  is highly suitable for business travelers. We offer a range of business facilities, including high-speed internet, a fully equipped business center, and modern meeting rooms. Our strategic location near major IT parks and business hubs like EON IT Park and Kharadi Bypass makes it an ideal choice for professionals visiting Kharadi.',
        },
        {
          question: 'Does the hotel provide transportation services?',
          answer: 'We offer transportation services upon request, including airport transfers and local shuttle services. Please contact our front desk or concierge team to arrange these services in advance.',
        },
        {
          question: 'Are there dining options available at Grand Legacy inn hotels ?',
          answer: 'Yes, Grand Legacy inn hotels  features an on-site restaurant that serves a variety of local and international dishes. We offer options for breakfast, lunch, and dinner, ensuring that our guests have access to a diverse range of culinary delights without leaving the hotel.',
        },
        {
          question: 'Is Grand Legacy inn hotels  a pet-friendly hotel?',
          answer: 'Unfortunately, Grand Legacy inn hotels  does not allow pets on the premises. We aim to provide a comfortable and allergen-free environment for all our guests. If you have specific needs or requirements related to pets, please contact us in advance to discuss alternative arrangements.',
        },
        {
          question: 'What amenities are provided in the rooms at Grand Legacy inn hotels ?',
          answer: 'Our rooms at Grand Legacy inn hotels  are equipped with a variety of amenities, including high-speed Wi-Fi, flat-screen TVs with satellite channels, premium bedding, a work desk, and a well-appointed bathroom with complimentary toiletries. We strive to provide all the comforts of home to ensure a pleasant stay.',
        },
        {
          question: 'Is parking available at the hotel?',
          answer: 'Yes, we provide complimentary parking for our guests. Our secure parking facilities are conveniently located near the hotel entrance. If you require valet service, please inform the front desk upon arrival.',
        },
        {
          question: 'Can I make reservations online?',
          answer: 'Yes, you can make reservations online through our official website. Our user-friendly booking system allows you to check real-time availability, view room options, and secure your stay with ease. For any special requests or assistance with your booking, feel free to contact our reservations team.',
        },
        {
          question: 'Are there any special offers or packages available?',
          answer: 'We frequently offer special promotions and packages. For the latest deals and offers, please visit our website or contact our reservations team. We aim to provide the best value for your stay and offer a range of packages to suit different preferences and budgets.',
        },
        {
          question: 'What attractions are near Grand Legacy inn hotels ?',
          answer: 'Grand Legacy inn hotels  is located in a vibrant area with easy access to local attractions. Nearby points of interest include EON IT Park, Kharadi Golf Course, and the Raja Dinkar Kelkar Museum. Our front desk staff will be happy to provide recommendations and assistance with local sightseeing and activities.',
        },
      ];

      const testimonials = [
        {
          quote: "I recently stayed at Grand Legacy inn hotels  during a business trip to Kharadi, and it exceeded all my expectations. The location is incredibly convenient, being close to both EON IT Park and Kharadi Bypass. The room was modern, comfortable, and well-equipped with everything I needed to stay productive. The high-speed Wi-Fi and business center facilities were top-notch. The staff was exceptionally professional and accommodating, making sure all my needs were met. I highly recommend this hotel for anyone visiting Kharadi for business or leisure!",
          author: "Mr. Ravi Kumar, IT Consultant"
        },
        {
          quote: "My recent stay at Grand Legacy inn hotels  was fantastic. From the moment I arrived, I was impressed by the warm hospitality and the elegant décor of the hotel. The restaurant’s diverse menu offered delicious meals, and the room provided a relaxing retreat after a busy day. The proximity to local attractions and business hubs made it an ideal choice for my visit. I appreciated the attention to detail and the excellent service provided by the staff. If you’re looking for a high-quality stay in Kharadi, this hotel is a great choice!",
          author: "Mrs. Anita Desai, Marketing Executive"
        }
      ];


      
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];

  
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    "name": "Grand Legacy inn hotels ",
    "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      "addressLocality": "Pune,Viman Nagar",
      "addressRegion": "MH",
      "postalCode": "411014",
      "addressCountry": "IN"
    },
    "telephone": "+91 7058638153",
    "image": "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    "starRating": {
      "@type": "Rating",
      "bestRating": "5",
      "ratingValue": "3",
      "worstRating": "1"
    },
   
    "sameAs": [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ]
  };

return(
    <>
  
    
        <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">
      {JSON.stringify(schemaData)}
    </script>
  
        <title>
            Contact +91 7058638153 | Grand Legacy inn hotels  | Hotels in Kharadi: EON IT Park, Zensar, Bypass
        </title>
        <meta name="description" content="Discover the best hotels near Kharadi with Grand Legacy inn hotels . We offer accommodations close to EON IT Park, Zensar, and the Kharadi bypass. Book your stay at affordable rates. Contact +91 7058638153 for reservations." />
        <meta name="keywords" content="Hotels in Kharadi, Hotels near Kharadi EON IT Park, Hotels near Zensar Kharadi, Hotels near Kharadi Bypass, Hotels near EON IT Park Kharadi, Lodge near Kharadi Bypass, Hotels near Kharadi, Hotel in Kharadi, Kharadi hotels for stay, Hotel EON Inn Kharadi, Hotel in Kharadi Bypass, Hotels in Kharadi Bypass, Kharadi hotel booking" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Grand Legacy inn hotels  - Contact +91 7058638153 | Hotels Near Kharadi: EON IT Park, Zensar, Bypass" />
        <meta property="og:description" content="Looking for hotels near Kharadi? Grand Legacy inn hotels  provides comfortable stays close to EON IT Park, Zensar, and the Kharadi bypass. Affordable rates and excellent service. Call +91 7058638153 to book now." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotel In Kharadi.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/hotels-in-kharadi" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
    
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Hotel in Kharadi</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Hotel in Kharadi
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Hotel In Kharadi.jpg" className="img-fluid" alt="Hotel In Kharadi" />
              </div>

              <h2>Grand Legacy inn hotels : Your Premier Hotel in Kharadi</h2>

              <p>
                When it comes to finding a top-notch hotel in Kharadi,
                Grand Legacy Inn Hotels stands out as the premier choice for discerning
                travelers. Located in one of Pune’s most dynamic business and
                residential areas, our hotel combines luxury, convenience, and
                exceptional service to ensure a memorable stay.
              </p>

              <h2>Discover the Best Hotel in Kharadi</h2>

              <ul>
                <li>
                  <h3>Hotels Near Kharadi EON IT Park</h3>
                  <p>
                    If you're visiting EON IT Park, one of Pune’s major IT hubs,
                    Grand Legacy inn hotels  is the perfect place to stay. Our
                    proximity to EON IT Park means you can enjoy a short commute
                    to your business meetings or conferences while staying in a
                    comfortable and stylish environment. Our modern amenities
                    and professional services ensure a productive and relaxing
                    stay.
                  </p>
                </li>
                <li>
                  <h3>Hotels Near Zensar Kharadi</h3>
                  <p>
                    For those with business at Zensar Technologies in Kharadi,
                    Grand Legacy inn hotels  offers a convenient and luxurious
                    option. Located close to Zensar’s offices, our hotel
                    provides a sophisticated retreat with top-notch facilities
                    and exceptional service. Our strategic location ensures that
                    you can easily attend meetings and return to a relaxing
                    haven at the end of the day.
                  </p>
                </li>
                <li>
                  <h3>Kharadi Hotels for Stay</h3>
                  <p>
                    When looking for Kharadi hotels for stay,Grand
                    Legacy Inn Hotels stands out due to its comprehensive range of services
                    and amenities. Our hotel features elegantly designed rooms,
                    a variety of dining options, and excellent recreational
                    facilities. Whether you're in Kharadi for business or
                    leisure, our hotel is designed to meet all your needs and
                    provide a comfortable, enjoyable stay.
                  </p>
                </li>
                <li>
                  <h3>Hotel EON Inn Kharadi</h3>
                  <p>
                    Grand Legacy inn hotels  is often compared to Hotel EON Inn
                    Kharadi due to its premium offerings and excellent location.
                    While both provide quality accommodation in Kharadi, our
                    hotel distinguishes itself with a commitment to luxury and
                    personalized service. Our guests enjoy modern comforts, a
                    variety of amenities, and a convenient location close to
                    major business and leisure destinations.
                  </p>
                </li>
                <li>
                  <h3>Hotels Near Kharadi Bypass</h3>
                  <p>
                    If you’re traveling through the Kharadi Bypass area, 
                    Grand Legacy Inn Hotels offers a convenient and comfortable option
                    for your stay. Our hotel's prime location ensures easy
                    access to the bypass, allowing you to navigate Pune with
                    ease. Enjoy the luxury of staying in a well-appointed hotel
                    that provides both convenience and relaxation.
                  </p>
                </li>
                <li>
                  <h3>Best Hotel in Kharadi</h3>
                  <p>
                    For those searching for the best hotel in Kharadi,
                    Grand Legacy Inn Hotels offers an unmatched experience. Our commitment
                    to luxury, comfort, and service sets us apart. Enjoy elegant
                    accommodations, modern amenities, and personalized service
                    that make us the top choice for travelers seeking the finest
                    hospitality in Kharadi.
                  </p>
                </li>
                <li>
                  <h3>Budget Hotels in Kharadi</h3>
                  <p>
                    If you’re looking for budget hotels in Kharadi that don’t
                    compromise on quality, Grand Legacy inn hotels  offers a range
                    of affordable options. While we provide budget-friendly
                    rates, we ensure that our guests enjoy high-quality services
                    and amenities. Our goal is to provide a comfortable and
                    enjoyable stay without breaking the bank.
                  </p>
                </li>
                <li>
                  <h3>Your Ideal Business Hotel in Kharadi</h3>
                  <p>
                    Grand Legacy inn hotels  stands out as the leading business
                    hotel in Kharadi, offering a blend of luxury, convenience,
                    and modern amenities tailored to the needs of business
                    travelers. Located in the heart of Pune’s vibrant IT and
                    business district, our hotel provides an ideal environment
                    for both work and relaxation.
                  </p>
                </li>
              </ul>
              <h2>EON Inn Kharadi vs. Grand Legacy inn hotels </h2>
              <p>
                While EON Inn Kharadi offers a comfortable stay,  Grand
                Legacy Inn Hotels surpasses it with enhanced luxury and a superior range of
                services. Our hotel features state-of-the-art business
                facilities, including high-speed internet, well-equipped meeting
                rooms, and professional concierge services, ensuring that your
                business needs are met with efficiency and style.
              </p>

              <h2>Hotel Rooms in Kharadi</h2>
              <p>
                When it comes to hotel rooms in Kharadi, Grand Legacy inn hotels 
                provides a range of luxurious accommodations designed to cater
                to various preferences. Each room is thoughtfully designed with
                modern furnishings, premium bedding, and amenities such as
                flat-screen TVs, workstations, and high-speed Wi-Fi. Whether
                you’re looking for a standard room or a deluxe suite, we offer a
                variety of options to ensure a comfortable and productive stay.
              </p>

              <h2>Hotels in Kharadi Bypass</h2>
              <p>
                Grand Legacy inn hotels  is ideally situated for easy access to
                the Kharadi Bypass, one of Pune’s major thoroughfares. Our
                location allows guests to seamlessly connect to different parts
                of the city and beyond, making our hotel a convenient choice for
                those needing quick access to transportation routes. Enjoy the
                comfort and convenience of staying at a hotel that offers both
                luxury and practicality.
              </p>

              <h2>Hotels Near EON IT Park Kharadi</h2>
              <p>
                For guests visiting EON IT Park, one of Pune’s leading IT and
                business hubs, Grand Legacy inn hotels  offers unmatched proximity
                and convenience. Our hotel is strategically located near EON IT
                Park, making it an ideal choice for business travelers who need
                easy access to their worksite. Enjoy a stress-free commute and
                return to a hotel that caters to your every need with
                exceptional service and amenities.
              </p>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Hotels near Kharadi</p>
                      <p>- Hotels in Kharadi for stay</p>
                      <p>- Hotels near Kharadi EON IT Park</p>
                      <p>- Hotels near Zensar Kharadi</p>
                      <p>- Kharadi hotels for stay</p>
                      <p>- Hotel EON Inn Kharadi</p>
                      <p>- Hotels near Kharadi Bypass</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Best hotel in Kharadi</p>
                      <p>- Budget hotels in Kharadi</p>
                      <p>- Business hotel in Kharadi</p>
                      <p>- EON Inn Kharadi</p>
                      <p>- Hotel room in Kharadi</p>
                      <p>- Hotels in Kharadi Bypass</p>
                      <p>- Hotels near EON IT Park Kharadi</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels  for Your Stay in Kharadi,
                Pune?
              </h2>

              <p>
                When it comes to selecting a hotel in Kharadi, Pune,
                Grand Legacy Inn Hotels stands out as the premier choice for travelers
                seeking luxury, convenience, and exceptional service. Here’s why
                our hotel should be your top pick:
              </p>

              <ul>
                <li>
                  <h3>Prime Location in Kharadi</h3>
                  <p>
                    Located in the bustling Kharadi district, Grand
                    Legacy Inn Hotels offers a strategic advantage for both business and
                    leisure travelers. Our proximity to major IT parks,
                    including EON IT Park, and key transportation routes like
                    the Kharadi Bypass ensures you’re well-connected and within
                    easy reach of your destination. Enjoy the convenience of
                    being in close proximity to prominent business hubs and
                    vibrant local attractions.
                  </p>
                </li>

                <li>
                  <h3>Luxurious Accommodations</h3>
                  <p>
                    At Grand Legacy inn hotels , we prioritize your comfort and
                    satisfaction. Our elegantly designed rooms and suites are
                    furnished with modern amenities such as high-speed Wi-Fi,
                    flat-screen TVs, premium bedding, and spacious workstations.
                    Each room is thoughtfully designed to offer a blend of
                    luxury and functionality, making it the perfect retreat
                    after a busy day.
                  </p>
                </li>

                <li>
                  <h3>Exceptional Dining Options</h3>
                  <p>
                    Indulge in a range of culinary delights at our on-site
                    restaurant. We offer a diverse menu featuring local and
                    international cuisines, prepared by our talented chefs.
                    Whether you're starting your day with a hearty breakfast,
                    having a business lunch, or enjoying a romantic dinner, our
                    dining options cater to all tastes and preferences.
                  </p>
                </li>

                <li>
                  <h3>State-of-the-Art Business Facilities</h3>
                  <p>
                    Grand Legacy inn hotels  is ideal for business travelers, with
                    a range of state-of-the-art facilities designed to support
                    your professional needs. Our business center is equipped
                    with high-speed internet and modern meeting rooms, providing
                    a conducive environment for meetings, conferences, and other
                    business activities. Our dedicated staff is also available
                    to assist with any special requirements you may have.
                  </p>
                </li>

                <li>
                  <h3>Top-Notch Amenities and Services</h3>
                  <p>
                    Our hotel offers a variety of amenities to enhance your
                    stay, including a fully equipped fitness center, a relaxing
                    swimming pool, and excellent concierge services. Whether
                    you're looking to unwind or stay active, our facilities are
                    designed to meet your needs and ensure a pleasant stay.
                  </p>
                </li>

                <li>
                  <h3>Personalized Service</h3>
                  <p>
                    At Grand Legacy inn hotels , we pride ourselves on delivering
                    exceptional and personalized service. Our friendly and
                    professional staff is committed to making your stay as
                    comfortable and enjoyable as possible. From check-in to
                    check-out, we are here to assist with any requests and
                    ensure that your experience exceeds expectations.
                  </p>
                </li>

                <li>
                  <h3>Competitive Pricing</h3>
                  <p>
                    We offer a range of accommodation options to suit different
                    budgets, providing luxury and comfort without compromising
                    on quality. Our competitive pricing and value-added services
                    ensure that you get the best possible experience at an
                    excellent rate.
                  </p>
                </li>

                <li>
                  <h3>Easy Online Booking</h3>
                  <p>
                    Booking your stay at Grand Legacy inn hotels  is quick and
                    convenient with our user-friendly online reservation system.
                    Check availability, view room options, and secure your
                    booking with just a few clicks. Enjoy a hassle-free booking
                    process and confirm your stay in moments.
                  </p>
                </li>
              </ul>

              <div className="">
                  <h2>
                    FAQs for Hotel in Viman Nagar, Pune - Grand Legacy inn hotels 
                  </h2>
                  {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className={`faq-question ${
                          activeIndex === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {index + 1}. {faq.question}
                      </div>
                      {activeIndex === index && (
                        <div className="faq-answer">{faq.answer}</div>
                      )}
                    </div>
                  ))}
                </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                    Testimonials for Hotel in Viman Nagar Pune - Grand
                    Legacy Inn Hotels: -{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>


                <div className=" contact-section">
                  <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                  <p>
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong>Mobile No:</strong>{" "}
                    <a href="tel:+917058638153">+91 7058638153</a>
                  </p>
                  <p>
                    <strong>Email Id:</strong>{" "}
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                     reservation@grandlegacyinnhotels.com
                    </a>
                  </p>
                </div>
            </div>
            <div class="col-12 col-md-4">
            <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
