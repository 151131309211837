import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function LuxuaryHotelInPuneNagarRoad() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const faqs = [
    {
      question:
        "What types of rooms are available at Grand Legacy Inn Hotels on Pune Nagar Road?",
      answer:
        "Grand Legacy inn hotels  offers a range of luxurious rooms and suites designed for ultimate comfort and sophistication. Room options include Deluxe Rooms, Executive Suites, and Premium Suites, each featuring high-end furnishings, plush bedding, and modern amenities.",
    },
    {
      question: "Is there a restaurant atGrand Legacy Inn Hotels ?",
      answer:
        "Yes,Grand Legacy inn hotels  features an in-house restaurant that offers a diverse menu of local and international cuisines. Guests can enjoy a gourmet dining experience in an elegant setting. Additionally, 24-hour room service is available for those who prefer to dine in their room.",
    },
    {
      question: "What amenities does the hotel offer to guests?",
      answer:
        "The hotel provides a range of amenities including high-speed internet, flat-screen TVs, minibars, and air conditioning in all rooms. Guests also have access to a state-of-the-art fitness center, a business center, and meeting rooms.",
    },
    {
      question:
        "How convenient is the location of Grand Legacy Inn Hotels on Pune Nagar Road?",
      answer:
        "Grand Legacy inn hotels  is ideally situated on Pune Nagar Road, providing easy access to major business districts, shopping centers, dining options, and local attractions. Its central location ensures seamless connectivity to other parts of Pune.",
    },
    {
      question:
        "Does Grand Legacy Inn Hotels offer any special packages or discounts?",
      answer:
        "Yes,Grand Legacy inn hotels  frequently offers special packages and promotions. These may include discounts for extended stays, seasonal offers, or exclusive deals.",
    },
    {
      question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
       answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
   },
    {
      question: "Is Grand Legacy Inn Hotels suitable for business travelers?",
      answer:
        "Absolutely.Grand Legacy inn hotels  is well-equipped to cater to business travelers with facilities such as a business center, high-speed internet, and meeting rooms.",
    },
    {
      question: "Are there any family-friendly amenities at the hotel?",
      answer:
        "Yes,Grand Legacy inn hotels  is family-friendly and offers spacious rooms, extra beds upon request, and kid-friendly dining options.",
    },
    {
      question: "How can I make a reservation atGrand Legacy Inn Hotels ?",
      answer:
        "You can make a reservation at Grand Legacy Inn Hotels through the hotel’s official website, popular online booking platforms, or by contacting the hotel’s reservations team directly.",
    },
    {
      question:
        "What measures does Grand Legacy Inn Hotels take to ensure guest safety and cleanliness?",
      answer:
        "Grand Legacy inn hotels  follows rigorous hygiene and safety protocols, including regular sanitizing of rooms and common areas, and adhering to health and safety guidelines.",
    },
  ];

  const testimonials = [
    {
      author: "Mrs. Anisha Rao",
      quote:
        "My stay at Grand Legacy Inn Hotels on Pune Nagar Road was nothing short of exceptional. From the moment I arrived, the luxurious ambiance and attentive service made me feel like royalty. The room was beautifully appointed with modern amenities and a breathtaking view of the city. The in-house restaurant offered a fantastic dining experience with a diverse menu that catered to all tastes. The location was perfect for exploring Pune, and the staff's professionalism and warmth truly set this hotel apart. I highly recommend Grand Legacy Inn Hotels for anyone seeking a top-notch luxury experience.",
    },
    {
      author: "Mr. Rajiv Mehta",
      quote:
        "Grand Legacy inn hotels  is the epitome of luxury on Pune Nagar Road. The hotel combines elegance with comfort, providing a truly luxurious stay. The rooms are spacious and well-furnished, offering a perfect retreat after a busy day. I was particularly impressed with the exceptional service and attention to detail from the staff. The fitness center and business facilities were well-maintained and met all my needs. The hotel's central location made it easy to access key areas of Pune. Whether you're in town for business or leisure,Grand Legacy inn hotels  is the best choice for a luxurious stay.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image:"https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
   "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact:+91 7058638153 | Grand Legacy inn hotels  | Luxury Hotels on
          Pune Nagar Road
        </title>
        <meta
          name="description"
          content="Looking for a luxury hotel on Pune Nagar Road? Book your stay at Grand Legacy inn hotels  for affordable luxury accommodation. Call +91 7058638153 for reservations."
        />
        <meta
          name="keywords"
          content="Luxury hotel on Pune Nagar Road, Cheap luxury hotel on Pune Nagar Road, Cheap luxury hotel near me, Hotels in Nagar Road Pune, Book Hotels in Nagar Road Pune, Grand Legacy inn hotels , luxury accommodation Pune Nagar Road"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="+91 7058638153 | Grand Legacy inn hotels  | Luxury Hotels on Pune Nagar Road"
        />
        <meta
          property="og:description"
          content="Experience luxury and comfort at Grand Legacy inn hotels  on Pune Nagar Road. We offer affordable luxury stays with world-class amenities. Call +91 7058638153 for bookings."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/Luxury Hotel on Pune Nagar Road.jpg"
        />
        <meta
          property="og:url"
          content="https://www.grandlegacyinnhotels.com/luxury-hotel-pune-nagar-road"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Luxury Hotel on Pune Nagar Road</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Luxury Hotel on Pune Nagar Road
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Luxury Hotel on Pune Nagar Road.jpg" className="img-fluid" alt="Luxury Hotel on Pune Nagar Road" />
              </div>

              <h2>
                Discover Luxury atGrand Legacy Inn Hotels : The Premier Luxury
                Hotel on Pune Nagar Road
              </h2>
              <p>
                When it comes to finding a luxurious and comfortable stay in
                Pune,Grand Legacy inn hotels  stands out as a premier choice along
                Pune Nagar Road. This upscale hotel offers an unparalleled blend
                of elegance, convenience, and top-notch amenities, making it the
                ideal destination for both business and leisure travelers.
                Here’s why Grand Legacy Inn Hotels is the ultimate luxury hotel on
                Pune Nagar Road.
              </p>

              <h2>Luxury Hotel on Pune Nagar Road</h2>
              <p>
                Grand Legacy inn hotels  redefines luxury on Pune Nagar Road with
                its elegant design and first-class amenities. Here's why it’s
                considered the premier luxury hotel in the area:
              </p>
              <ul>
                <li>
                  Exquisite Rooms: Each room is thoughtfully designed with
                  luxurious furnishings, plush bedding, and modern decor,
                  creating a haven of comfort and style.
                </li>
                <li>
                  Premium Facilities: Enjoy high-end amenities including a
                  well-equipped fitness center, gourmet dining options, and
                  high-speed internet.
                </li>
                <li>
                  Central Location: Situated on Pune Nagar Road, the hotel
                  provides easy access to major attractions, business centers,
                  and dining options.
                </li>
              </ul>

              <h2>Cheap Luxury Hotel on Pune Nagar Road</h2>
              <p>
                For those seeking a luxurious stay without breaking the bank,
                Grand Legacy inn hotels  offers a remarkable balance of luxury and
                affordability:
              </p>
              <ul>
                <li>
                  Value for Money: Experience top-notch luxury at competitive
                  rates, ensuring you receive exceptional quality without the
                  high price tag.
                </li>
                <li>
                  Exclusive Offers: Take advantage of special packages and
                  promotions that make luxury more accessible and
                  budget-friendly.
                </li>
                <li>
                  Affordable Luxury: Enjoy elegant accommodations and premium
                  amenities while benefiting from affordable pricing.
                </li>
              </ul>

              <h2>Cheap Luxury Hotel on Pune Nagar Road Near Me</h2>
              <p>
                If you’re searching for a cheap luxury hotel on Pune Nagar Road
                near you,Grand Legacy inn hotels  is the perfect choice. Here’s
                what you can expect:
              </p>
              <ul>
                <li>
                  Proximity: Located conveniently on Pune Nagar Road, the hotel
                  is easily accessible, providing a hassle-free travel
                  experience.
                </li>
                <li>
                  Luxury on a Budget: Enjoy luxurious rooms and high-end
                  services without the premium cost, offering excellent value
                  for your stay.
                </li>
                <li>
                  Convenient Booking: Easily book your stay online or by
                  contacting the hotel directly, ensuring a seamless and
                  efficient reservation process.
                </li>
              </ul>

              <h2>Book Hotels in Nagar Road Pune</h2>
              <p>
                Grand Legacy inn hotels  makes booking your stay on Nagar Road,
                Pune, straightforward and convenient. Here’s how you can secure
                your luxurious accommodation:
              </p>
              <ul>
                <li>
                  Online Booking: Use the hotel’s website or popular booking
                  platforms to reserve your room quickly and easily.
                </li>
                <li>
                  Direct Reservations: Contact the hotel directly for
                  personalized assistance and to take advantage of exclusive
                  deals and packages.
                </li>
                <li>
                  Customer Support: The hotel’s dedicated team is available to
                  help with any queries and to ensure a smooth booking
                  experience.
                </li>
              </ul>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>-Luxury hotel on pune nagar road</p>
                      <p>-Cheap luxury hotel on pune nagar road</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Cheap luxury hotel on pune nagar road near me</p>
                      <p>-Book Hotels in Nagar Road Pune</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy Inn Hotels for Your Luxury Hotel on Pune
                Nagar Road?
              </h2>
              <p>
                When searching for the perfect luxury hotel on Pune Nagar Road,
                Grand Legacy inn hotels  emerges as the premier choice, blending
                elegance, convenience, and exceptional service. Here’s why this
                hotel stands out as the ideal destination for your luxurious
                stay.
              </p>

              <h2>Unmatched Elegance and Comfort</h2>
              <ul>
                <li>
                  <strong>Sophisticated Accommodations:</strong> Hotel Grand The
                  Legacy offers a selection of elegantly designed rooms and
                  suites that exude sophistication and comfort. Each room is
                  meticulously decorated with high-quality furnishings, plush
                  bedding, and modern amenities to ensure a serene and stylish
                  environment.
                </li>
                <li>
                  <strong>Luxury Amenities:</strong> From spacious living areas
                  to luxurious bathrooms, every detail in the rooms is designed
                  to provide a lavish experience. Enjoy premium features such as
                  high-speed internet, flat-screen TVs, and minibars, all
                  tailored to enhance your comfort.
                </li>
              </ul>

              <h2>Prime Location on Pune Nagar Road</h2>
              <ul>
                <li>
                  <strong>Central Accessibility:</strong> Situated on the
                  bustling Pune Nagar Road, the hotel provides easy access to
                  major business districts, shopping centers, and popular dining
                  spots. This central location ensures that you are
                  well-connected to the city’s key attractions and conveniences.
                </li>
                <li>
                  <strong>Convenient Transportation:</strong> With its strategic
                  location,Grand Legacy inn hotels  offers seamless connectivity
                  to other parts of Pune, making it an excellent base for
                  exploring the city or attending business meetings.
                </li>
              </ul>

              <h2>Exceptional Dining Experience</h2>
              <ul>
                <li>
                  <strong>Gourmet Dining Options:</strong> The hotel features an
                  in-house restaurant that offers a diverse menu of local and
                  international cuisines. Guests can enjoy a gourmet dining
                  experience in a sophisticated setting, perfect for both casual
                  meals and special occasions.
                </li>
                <li>
                  <strong>Room Service:</strong> For those who prefer to dine in
                  the comfort of their room, 24-hour room service is available,
                  allowing you to enjoy delectable meals without leaving your
                  accommodation.
                </li>
              </ul>

              <h2>Outstanding Service</h2>
              <ul>
                <li>
                  <strong>Personalized Attention:</strong> At Grand
                  Legacy Inn Hotels, guest satisfaction is a top priority. The hotel’s
                  dedicated staff provide personalized service, ensuring that
                  every aspect of your stay is catered to with care and
                  attention.
                </li>
                <li>
                  <strong>24/7 Assistance:</strong> Whether you need assistance
                  with travel arrangements, special requests, or local
                  recommendations, the staff are available around the clock to
                  make your stay as pleasant and stress-free as possible.
                </li>
              </ul>

              <h2>Affordable Luxury</h2>
              <ul>
                <li>
                  <strong>Competitive Rates:</strong> Despite offering high-end
                  accommodations and services,Grand Legacy inn hotels  provides
                  competitive rates that make luxury more accessible. Enjoy a
                  premium experience without the premium price tag.
                </li>
                <li>
                  <strong>Special Offers:</strong> The hotel regularly features
                  exclusive deals and packages, allowing you to experience
                  luxury at a fraction of the cost. Check the hotel’s website or
                  contact the reservations team for the latest offers.
                </li>
              </ul>

              <h2>Comprehensive Facilities</h2>
              <ul>
                <li>
                  <strong>Fitness Center:</strong> Maintain your fitness routine
                  at the hotel’s state-of-the-art fitness center. With
                  top-of-the-line equipment, it’s easy to stay active and
                  energized during your stay.
                </li>
                <li>
                  <strong>Business Services:</strong> For business travelers,
                  the hotel offers comprehensive facilities including meeting
                  rooms, high-speed internet, and a business center. These
                  amenities are designed to support your professional needs and
                  ensure a productive stay.
                </li>
              </ul>

              <div className="">
                <h2>
                  FAQs forGrand Legacy Inn Hotels : Luxury Hotel on Pune Nagar
                  Road
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

              {/* ======================== */}
              <div className="">
                <h2>
                  Testimonials for Hotel in Viman Nagar Pune - Grand
                  Legacy Inn Hotels:{" "}
                </h2>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-slide">
                      <blockquote>
                        <p>{testimonial.quote}</p>
                        <footer>— {testimonial.author}</footer>
                      </blockquote>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* ================= */}
              <div className=" contact-section">
                <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
