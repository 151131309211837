import "./CSS/all.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/magnific-popup.css";
import "./CSS/meanmenu.min.css";
import "./CSS/nice-select.css";
import "./CSS/swiper-bundle.min.css";
import "./sass/style.css";
import "./App.css";
import Slider from "react-slick";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { link } from "framer-motion/client";


export default function Home() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the accordion if it's already open
    } else {
      setActiveIndex(index); // Open the clicked accordion
    }
  };


  const hotelCards = [
    {
      link:'best-hotels-in-pune-for-couples',
      description: "Best Hotels in Pune for couples",
      image: "./Hotel-Images/Best Hotels in Pune for couples.jpg",
    },
    {
      link:'corporate-hotel-booking-in-viman-nagar',
      description: "Hotel Booking for corporate in Viman  nagar",
      image: "Hotel-Images/Hotel Booking for corporate in Viman nagar.jpg",
    },
    {
      link:'hotel-for-stay-lohegaon-airport',
      description: "Hotel for stay in lohegoan Airport",
      image: "./Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
    },

    {
      link:'business-stays-in-viman-nagar',
      description: "Hotel for Business Stays in Viman Nagar",
      image: "./Hotel-Images/Hotel for Business Stays in Viman Nagar.jpg",
    },

    {
      link:'luxury-hotel-pune-nagar-road',
      description: "Luxury Hotel on Pune Nagar Road",
      image: "./Hotel-Images/Luxury Hotel on Pune Nagar Road.jpg",
    },

    {
      link:'online-hotel-booking-pune',
      description: "Online hotel booking in pune",
      image: "./Hotel-Images/Online hotel booking in pune.jpg",
    },
    // Add more hotel data here
  ];
  

  const containerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = containerRef.current;
    let scrollAmount = 0;
    const scrollSpeed =1; // Adjust scroll speed

    const scroll = () => {
      if (scrollContainer) {
        scrollAmount += scrollSpeed;
        scrollContainer.scrollLeft += scrollSpeed; // Scroll by the scroll speed

        // Reset scroll when it reaches the end
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
          scrollContainer.scrollLeft = 0;
        }
      }

      requestAnimationFrame(scroll);
    };

    scroll();

    return () => {
      cancelAnimationFrame(scroll);
    };
  }, []);
  
  const testimonials = [
    {
      image: "img/testimonial/testG1.png",
      quote:
        "I stayed at Grand Legacy inn hotels  for a business trip, and it was an excellent choice. The proximity to Pune Airport was incredibly convenient, and the hotels amenities were top-notch. The room was spacious, clean, and comfortable, with a great workspace and high-speed Wi-Fi. The on-site restaurant offered delicious meals, and the staff was attentive and helpful. Highly recommend this hotel for both business and leisure stays!",
      author: "Mr. Arjun Rao, Business Consultant",
    },
    {
      image: "img/testimonial/testL1.png",
      quote:
        "Grand Legacy inn hotels  provided an exceptional stay. Its location near Pune Airport was perfect for my travel needs, and the service was outstanding. The modern rooms were well-furnished and had everything I needed for a comfortable stay. The business center and meeting rooms were ideal for my work requirements. I also enjoyed exploring the nearby Viman Nagar area during my downtime. I will definitely stay here again!",
      author: "- Ms. Priya Sharma, Marketing Director",
    },
    {
      image: "img/testimonial/testG2.png",
      quote:
        '"During my recent visit to Pune, I chose Grand Legacy inn hotels  for its proximity to the airport, and I was not disappointed. The hotel offers excellent value with its comfortable rooms, high-speed Wi-Fi, and great dining options. The staff were welcoming and provided excellent service throughout my stay. This hotel is perfect for travelers looking for both convenience and comfort near Pune Airport."',
      author: "- Mr. Sanjay Patel, Financial Advisor",
    },
    {
      image: "img/testimonial/testL5.png",
      quote:
        "My experience at Grand Legacy inn hotels  was fantastic. The location was incredibly convenient for my early morning flight, and the hotel itself exceeded my expectations. The room was clean, modern, and well-equipped. The business amenities, including the fast internet and meeting rooms, were very helpful. The on-site restaurant served excellent food. I highly recommend this hotel to anyone traveling to Pune.",
      author: "— Ms. Anjali Mehta, Project Manager",
    },
    {
      image: "img/testimonial/testG3.png",
      quote:
        "Grand Legacy inn hotels  is a superb choice for both business and leisure travelers. Its proximity to Pune Airport makes travel easy, and the hotel's facilities are excellent. The rooms are comfortable and well-designed, and the high-speed Wi-Fi was a great asset. The attentive staff and the quality of the on-site restaurant added to the overall positive experience. I will definitely choose this hotel again for future visits to Pune.",
      author: "— Mr. Vikram Sinha, Senior Executive",
    },
    {
      image: "img/testimonial/testL3.png",
      quote:
        "Grand Legacy inn hotels  provided a remarkable stay during my recent trip to Pune. The hotel's location near the airport was incredibly convenient. The rooms were stylish and comfortable, and the service from the staff was exemplary. I particularly appreciated the modern business facilities and the delicious food available at the restaurant. This hotel is a great choice for travelers looking for comfort and efficiency.",
      author: "— Ms. Rina Desai, HR Specialist",
    },
    {
      image: "img/testimonial/testL4.png",
      quote:
        "Grand Legacy inn hotels  is ideal for anyone needing a hotel close to Pune Airport. The location is perfect, and the hotel provides a high standard of comfort and service. The rooms are well-appointed with modern amenities, and the on-site restaurant offers a variety of great food. The staff were professional and ensured a smooth stay. This hotel is definitely worth considering for your next trip to Pune.",
      author: "— Ms. Neha Gupta, Sales Executive",
    },
  ];



  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, 
    pauseOnHover: false, 
    arrows: false,
  };

  const faqs = [
    {
      question: "What time is check-in and check-out?",
      answer:
        "If you need an early check-in or late check-out, please contact the front desk in advance. Availability is subject to room availability and may incur additional charges.",
    },
    {
      question: "Do you offer airport transfers?",
      answer:
        "Yes, we offer airport transfer services. Please contact our concierge or front desk to arrange transportation. Charges may apply.",
    },
    {
      question: "Is Wi-Fi available at the hotel?",
      answer:
        "Yes, we provide complimentary high-speed Wi-Fi throughout the hotel, including guest rooms and common areas.",
    },
    {
      question: "Are pets allowed at the hotel?",
      answer:
        "We are a pet-friendly hotel. Please inform us in advance if you will be bringing a pet, and check our pet policy for any additional fees or requirements.",
    },
    {
      question: "What are the dining options available at the hotel?",
      answer:
        "Our hotel features a variety of dining options, including a restaurant which serves breakfast, lunch, and dinner. We also have a cafe offering light snacks and beverages. Room service is available 24/7.",
    },
    {
      question: "Is parking available?",
      answer:
        "Yes, we offer parking options. Please inquire at the front desk for details on availability and any associated fees.",
    },
    {
      question: "Do you have a fitness centre?",
      answer:
        "Yes, our fitness centre is equipped with cardio machines, free weights, and more. It is available to guests 24/7.",
    },
    {
      question: "Can I request a late check-out?",
      answer:
        "Late check-out may be available upon request. Please contact the front desk to check availability. Additional fees may apply.",
    },
    {
      question: "What is your cancellation policy?",
      answer:
        "Our cancellation policy varies depending on the rate type and booking conditions. Please review the terms and conditions at the time of booking or contact our reservations team for specific details.",
    },
    {
      question: "Do you offer meeting and event facilities?",
      answer:
        "Yes, we have meeting and event spaces available for conferences, weddings, and other special occasions. Please contact our events team for more information and to make arrangements.",
    },
  ];

  
  const features = [
    {
      imgSrc: "./website img/NANASHREE 19.jpg",
      subtitle: "Our Restaurant",
      title: "Restaurant",
      description:
        "Nestled within the luxurious ambiance of Grand Legacy inn hotels , our restaurant offers a delightful culinary experience that blends traditional flavors with modern gastronomy. Whether you’re seeking a casual dining experience or a gourmet meal, our restaurant caters to all palates.",
      link: "/restaurants",
      reverse: false,
    },
    {
      imgSrc: "./website img/NANASHREE 02.jpg",
      subtitle: "Our Rooms",
      title: "Rooms",
      description:
        "Grand Legacy inn hotels  offers a variety of rooms catering to different preferences and need",
      link: "/restaurants",
      reverse: true,
    },
    {
      imgSrc: "./website img/NANASHREE 14.jpg",
      subtitle: "Fitness Equipment",
      title: "Restaurant",
      description:
        "Our spacious and elegantly designed rooms provide a serene retreat from the bustling city. With options ranging from Executive Rooms to Deluxe Suites, each room is equipped with modern amenities like free Wi-Fi, flat-screen TVs, air-conditioning, and plush bedding for a restful night.",
      link: "/restaurants",
      reverse: false,
    },
  ];

  const blogs = [
    {
      text:'Best Hotels in Pune for couples',
      imgSrc: "./Hotel-Images/Best Hotels in Pune for couples.jpg",
      title:"Pune, a vibrant city known for its blend of tradition and modernity, offers numerous romantic getaways for couples. Whether you're celebrating a special occasion or just looking for a serene escape, finding the perfect hotel can set the stage for an unforgettable experience. Among the top choices for couples seeking a memorable stay, Grand Legacy inn hotels  stands out as one of the best hotels in Pune for couples.",
      link:'best-hotels-in-pune-for-couples'
    },
    {
      text:'Hotel In Kharadi',
      imgSrc: "./Hotel-Images/Hotel In Kharadi.jpg",
      title:
        "When it comes to finding a top-notch hotel in Kharadi, Grand Legacy inn hotels  stands out as the premier choice for discerning travelers. Located in one of Pune’s most dynamic business and residential areas, our hotel combines luxury, convenience, and exceptional service to ensure a memorable stay.",
      link:'hotels-in-kharadi',
    },
    {
      text:'Luxury Hotel on Pune Nagar Road',
      imgSrc: "./Hotel-Images/Luxury Hotel on Pune Nagar Road.jpg",
      title:
       "When it comes to finding a luxurious and comfortable stay in Pune,Grand Legacy inn hotels  stands out as a premier choice along Pune Nagar Road. This upscale hotel offers an unparalleled blend of elegance, convenience, and top-notch amenities, making it the ideal destination for both business and leisure travelers. Here’s why Grand Legacy Inn Hotels is the ultimate luxury hotel on Pune Nagar Road.",
      link:'luxury-hotel-pune-nagar-road'
    },
    {
      text:'Hotel in Koregaon park',
      imgSrc: "./Hotel-Images/Hotel in Koregaon park.jpg",
      title:
       "If you're searching for a premier hotel in Koregaon Park, Pune, look no further than Grand Legacy inn hotels . Known for its blend of luxury, convenience, and exceptional service, Grand Legacy inn hotels  offers an unmatched experience for both business and leisure travelers. ",
      link:'hotel-in-koregaon-park'
    },

    {
      text:'Hotel In Wadgoan Sheri',
      imgSrc: "./Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      title:
      "If you’re looking for a luxurious and comfortable stay in Pune, Grand Legacy inn hotels  in Wadgaon Sheri stands out as a top choice. Nestled in one of Pune's vibrant neighborhoods, this hotel combines elegance, convenience, and superior service to offer an unparalleled experience. ",
      link:'hotel-wadgaon-sheri'
    },
    
    {
      text:'Hotels near Pune international airport',
      imgSrc: "./Hotel-Images/Hotels near Pune international airport.jpg",
      title:
      "When traveling to Pune, finding the ideal accommodation near Pune International Airport can significantly enhance your travel experience. Grand Legacy inn hotels  offers a perfect blend of accessibility, comfort, and affordability, making it one of the top hotels near Pune International Airport. ",
      link:"hotels-near-pune-international-airport"
    }


  ];

  const instagramData = [
    { src: "./grand legacy rooms/grand leagcy room11.jpg", alt: "grand legacy room" },
    { src: "./website img/NANASHREE 19.jpg", alt: "Image1" },
    { src: "./grand legacy rooms/grand legacy room1.jpg", alt:"grand legacy room" },
    { src: "./grand legacy rooms/grand legacy room1.jpg", alt:"grand legacy room"},
    { src: "./grand legacy rooms/grand legacy room6.jpg", alt: "grand legacy room" },
    { src: "./grand legacy rooms/grand legacy room5.jpg", alt: "grand legacy room"},
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
    
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact:+91 7058638153 | Grand Legacy inn hotels  |Home | Viman Nagar
          Hotel Booking and Stay Options| Hotel Near Me Pune Airport | Hotels
          Near Kharadi | Hotel in Koregaon Park | Best Hotels in Pune for
          Couples
        </title>
        <meta
          name="description"
          content="Book the best hotels in Viman Nagar, Pune for your stay. Find couple-friendly hotels, business hotels, 3-star and 4-star hotels near Pune International Airport. Call +91 7058638153 for bookings."
        />
        <meta
          name="keywords"
          content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, Business hotels in Viman Nagar, Best hotels in Viman Nagar Pune, Couple-friendly hotels in Viman Nagar, 3-star hotels in Viman Nagar, Hotels near Pune International Airport, 3-star hotels near Pune airport, Hotels near Pune domestic airport, 4-star hotels near Pune airport, Best hourly hotels near Pune International Airport"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="+91 7058638153 | Grand Legacy inn hotels  |Home | Viman Nagar Hotel Booking | Hotel Near Me Pune Airport
         Hotels Near Kharadi"
        />
        <meta
          property="og:description"
          content="Book your stay at Grand Legacy inn hotels , one of the best hotels in Viman Nagar. We offer couple-friendly and business hotel rooms, as well as 3-star and 4-star hotel options near Pune Airport. Call +91 7058638153."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg"
        
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/home"/>
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>

      <div class="banner__area header-section">
        <div>
          <Slider {...settings}>
            <div className="myslider">
              <img src="Hotel-Images/Slider-01.jpg" alt="slide1" />
            </div>
            <div className="myslider">
              <img src="Hotel-Images/Slider-02.jpg" alt="slide 2" />
            </div>
            <div className="myslider">
              <img src="Hotel-Images/Slider-03.jpg" alt="slide 3" />
            </div>
            <div className="myslider">
              <img src="Hotel-Images/Slider 1-01.jpg" alt="slide 4" />
            </div>
            <div className="myslider">
              <img src="Hotel-Images/Slider 1-02.jpg" alt="slide 5" />
            </div>
            <div className="myslider">
              <img src="Hotel-Images/Slider 2-01.jpg" alt="slide 6" />
            </div>
            <div className="myslider">
              <img src="Hotel-Images/Slider 2-02.jpg" alt="slide 7" />
            </div>

          </Slider>
        </div>

        <div class="">
          <form action="">
            <div class="check__area">
              <div class="check__area-item">
                <p>
                  Check In
                  <input id="date" type="date" />
                </p>
              </div>
              <div class="check__area-item">
                <p>
                  Check Out
                  <input id="date2" type="date" />
                </p>
              </div>
              <div class="check__area-item">
                <div class="check__area-item-room">
                  <p>Room</p>
                  <select name="select">
                    <option value="1">1 Room</option>
                    <option value="2">2 Room</option>
                    <option value="3">3 Room</option>
                    <option value="4">4 Room</option>
                    <option value="5">5 Room</option>
                  </select>
                </div>
              </div>
              <div class="check__area-item button">
                <button class="theme-btn" type="submit">
                  <Link to="/BookingForm" className="nav-link active">
                    <a href="/BookingForm"> Check Now</a>
                  </Link>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div> 

      {/* <!-- Banner Area End --> */}
      {/* <!-- Accommodations Area Start --> */}
      <div class="accommodations__area section-padding">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-5 col-lg-6 lg-mb-30">
              <div class="accommodations__area-title">
                <span class="subtitle__one">Accommodations</span>
                <h2>Welcome Our Hotels And Resorts</h2>
                <p>
                  At Grand Legacy inn hotels , we invite you to indulge in an
                  unparalleled stay where luxury meets convenience. Our
                  meticulously designed accommodations offer a perfect blend of
                  elegance and modern amenities, ensuring a memorable experience
                  for every guest.
                </p>

                <Link className="theme-btn" to="/restaurants">
                  Read More<i class="fal fa-long-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6">
              <div class="accommodations__area-right">
                <div class="accommodations__area-right-image">
                  <img src="./grand legacy rooms/grand leagcy room11.jpg" alt="" />
                  <div class="accommodations__area-right-image-two">
                    <img
                      src="./website img/NANASHREE 01. COPY jpg.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Accommodations Area End --> */}
      {/* <!-- Deluxe Area Start --> */}
      <div class="deluxe__area section-padding">
        <div class="container">
          <div class="row align-items-end mb-60">
            <div class="col-xl-5">
              <div class="deluxe__area-title">
                <span class="subtitle__one">Deluxe and Luxury</span>
                <h2>Our Luxury Rooms</h2>
              </div>
            </div>
            <div class="col-xl-7">
              <div class="deluxe__area-btn">
                <ul>
                  <li class="active" data-filter="*">
                    <Link to="/room-style" className="nav-link active">
                      All Rooms
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row deluxe__area-active">
            <div class="col-xl-3 col-lg-4 mb-30 suite">
              <div class="deluxe__area-item">
                <div class="deluxe__area-item-image">
                  <img
                    class="img__full"
                    src="./grand legacy rooms/grand legacy room12.jpg"
                    alt="room images"
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-8 mb-30 suite">
              <div class="deluxe__area-item deluxe__area-item-hover">
                <div class="deluxe__area-item-image">
                  <img
                    class="img__full"
                    src="website img/NANASHREE 02.jpg"
                    alt="room iamges"
                  />
                </div>
                <div class="deluxe__area-item-content">
                  <Link to="/contact" className="nav-link active">
                    <a class="simple-btn" href="/contact">
                      <i class="far fa-chevron-right"></i>Booking Now
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 mb-30 family">
              <div class="deluxe__area-item">
                <div class="deluxe__area-item-image">
                  <img
                    class="img__full"
                    src="./grand legacy rooms/grand leagcy room11.jpg"
                    alt=""
                  />
                </div>
                <div class="deluxe__area-item-content">
                  <Link to="/contact" className="nav-link active">
                    <a class="simple-btn" href="/contact">
                      <i class="far fa-chevron-right"></i>Booking Now
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-8 lg-mb-30 single">
              <div class="deluxe__area-item">
                <div class="deluxe__area-item-image">
                  <img
                    class="img__full"
                    src="grand legacy rooms/grand legacy room7.jpg"
                    alt=""
                  />
                </div>
                <div class="deluxe__area-item-content">
                  <Link to="/contact" className="nav-link active">
                    <a class="simple-btn" href="/contact">
                      <i class="far fa-chevron-right"></i>Booking Now
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-6 luxury">
              <div class="deluxe__area-item">
                <div class="deluxe__area-item-image">
                  <img
                    class="img__full"
                    src="website img/NANASHREE 10.jpg"
                    alt=""
                  />
                </div>
                <div class="deluxe__area-item-content">
                  <a class="simple-btn" href="/contact">
                    <i class="far fa-chevron-right"></i>Booking Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Deluxe Area End --> */}
      {/* <!-- Video Area Start --> */}
      <div class="video__area" data-background="./img/video.jpg">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xxl-6 col-xl-7 col-lg-8">
              <div class="video__area-title">
                <h2>Book hotel rooms, get deals & book flights online.</h2>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-5 col-lg-4">
              <div class="video__area-right">
                <div class="video__play">
                  <a
                    class="video-popup"
                    href="https://www.youtube.com/watch?v=0WC-tD-njcA"
                  >
                    <i class="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Video Area End --> */}
    


      {/* <!-- Feature Area Start --> */}
      <section className="section">
      <div className="feature__area">
        <div className="container">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`row align-items-center mb-60 ${
                feature.reverse ? "bg-right" : "bg-left"
              }`}
            >
              <div
                className={`col-xl-6 col-lg-6 ${
                  feature.reverse ? "order-last order-lg-first" : ""
                }`}
              >
                <div className="feature__area-left">
                  <div className="feature__area-left-title">
                    <span className="subtitle__one">{feature.subtitle}</span>
                    <h2>{feature.title}</h2>
                    <p>{feature.description}</p>
                    <Link to="/restaurants" className="nav-link active">
                      <a className="theme-border-btn" href={feature.link}>
                        Read More<i className="fal fa-long-arrow-right"></i>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="feature__area-image">
                  <img
                    className="img__full"
                    src={feature.imgSrc}
                    alt={feature.title}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </section>
      {/* <!-- Feature Area End --> */}

  
<div className="hotel-section">
      <div className="hotel-cards-container">
        {hotelCards.map((hotel, index) => (
          <div className="hotel-card" key={index}>
            <img src={hotel.image} alt={hotel.name} className="hotel-image" />
            <Link to={hotel.link}><p>{hotel.description}</p></Link>
          </div>
        ))}
        {/* Duplicate the cards to create a looping effect */}
        {hotelCards.map((hotel, index) => (
          <div className="hotel-card" key={`duplicate-${index}`}>
            <img src={hotel.image} alt={hotel.name} className="hotel-image" />
            <Link to={hotel.link}><p>{hotel.description}</p></Link>
            
          </div>
        ))}
      </div>
    </div>

    {/* ====================== */}
      <div className="container  section">
        <div className="faq-container">
          <h3 className="pb-5 text-center fw-bold">FAQs for Grand Legacy inn hotels </h3>
          <div className="faq-grid">
            {faqs.map((faq, index) => (
              <div className="accordion-item accordian-bg" key={index}>
                <div
                  className={`accordion-header ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  {faq.question}
                  <span className="accordion-icon">
                    {activeIndex === index ? "-" : "+"}
                  </span>
                </div>
                <div
                  className="accordion-content"
                  style={{
                    maxHeight: activeIndex === index ? "200px" : "0",
                    overflow: "hidden",
                    transition: "max-height 0.3s ease",
                  }}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* ===================== faq section end ====================== */}
      <section className="Testimonial-section section-padding">
      <h3 className="text-center text-white fw-bold pb-4 clientText">What our client says?</h3>
           {/* <!-- Testimonial Area Start -->	 */}
      <div className="testimonial-carousel">
      
        <div class="container">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-item">
                <img
                  src={testimonial.image}
                  alt={`Testimonial ${index + 1}`}
                  className="testimonial-image m-auto"
                />
                <blockquote className="testimonial-quote">
                  <p>{testimonial.quote}</p>
                </blockquote>
                <footer className="testimonial-author">
                  <p>{testimonial.author}</p>
                </footer>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      </section>
     
      {/* <!-- Testimonial Area End -->	 */}
      {/* <!-- Blog Area Start -->	 */}

      <div className="blog__area section-padding">
        <div className="container">
          <div className="row mb-60">
            <div className="col-xl-12">
              <div className="blog__area-title">
                <span className="subtitle__one">Our Services</span>
                <h2 className="text-center fw-bold">Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {blogs.map((blog, index) => (
              <div
                key={index}
                className={`col-xl-4 col-lg-6 ${
                  index === 0 ? "xl-mb-30" : ""
                } ${index === 1 ? "lg-mb-30" : ""}`}
              >
                <div
                  className={`blog__area-item ${
                    blog.hoverEffect ? "blog__area-item-hover" : ""
                  }`}
                >
                  <div className="blog__area-item-image">
                    <a href={blog.link}>
                      <img src={blog.imgSrc} alt={blog.title} />
                    </a>
                  </div>
                  <div className="blog__area-item-content">
                    <div className="blog__area-item-content-box-title text-center">
                        <h5 className="fw-bold text-blog text-center">{blog.text}</h5>
                        <p> <a href={blog.link}>{blog.title}</a></p>
                         <Link to={blog.link} className="mybtn">Read More...</Link>
                      </div>
                    </div>
                  
                  
                </div>
              </div>
            ))}
          </div>

     
        </div>
      </div>
      {/* <!-- Blog Area End -->	 */}

      <div className="instagram__area">
        <div className="container-fluid">
          <div className="row">
            {instagramData.map((item, index) => (
              <div key={index} className="col-lg-2 col-sm-4 pl-5 pr-5 mb-10">
                <div className="instagram__area-item ">
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ height: "200px" }}
                  />
                 
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
