import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

export default function HotelBookingForCorporateInVimanNagar() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What amenities does Grand Legacy inn hotels  offer for business travelers?",
      answer: (
        <>
          Grand Legacy inn hotels  provides a comprehensive range of amenities tailored for business travelers, including:
          <ul>
            <li>State-of-the-Art Business Center: Equipped with the latest technology and office supplies.</li>
            <li>High-Speed Wi-Fi: Reliable internet access throughout the hotel.</li>
            <li>Versatile Meeting Rooms: Flexible spaces with audiovisual equipment for meetings, conferences, and presentations.</li>
            <li>Ergonomic Workstations: Comfortable desks and chairs in each room for working.</li>
          </ul>
        </>
      ),
    },
    {
      question: "How far is Grand Legacy inn hotels  from Pune International Airport?",
      answer: "Grand Legacy inn hotels  is conveniently located just a short drive from Pune International Airport, approximately [X] kilometers away.",
    },
    {
      question: "Can I book a room at Grand Legacy inn hotels  for a long-term stay?",
      answer: "Yes, we offer attractive rates for long-term stays. Contact us directly to discuss your long-term accommodation requirements and special rates.",
    },
    {
      question: "Are there dining options available at Grand Legacy inn hotels ?",
      answer: "Yes, we have an on-site restaurant with a diverse menu and room service available.",
    },
    {
      question: "Is Grand Legacy inn hotels  a pet-friendly hotel?",
      answer: "Currently, we do not accommodate pets. For specific needs or special requests, please contact our reception.",
    },
    {
      question: "Does Grand Legacy inn hotels  offer airport transfer services?",
      answer: "Yes, we offer airport transfer services. Please inform us of your flight details in advance. Additional charges may apply.",
    },
    {
     question: "How can I make a reservation at Grand Legacy inn hotels ?",
      answer: "Reservations can be made directly through our website, by phone at [+91 7058638153], or by email at [reservation@grandlegacyinnhotels.coms].",
     
    },
    {
      question: "Are there any special rates or promotions for corporate bookings?",
      answer: "We offer special corporate rates and promotions. Contact our sales team or visit our website for more details.",
    },
    {
      question: "What local attractions are near Grand Legacy inn hotels ?",
      answer: "We are near shopping centers, dining options, and cultural sites. Our staff can assist with recommendations and transportation.",
    },
  ];


  const testimonials = [
    {
      quote: "I recently stayed at Grand Legacy inn hotels  for a week-long business trip in Viman Nagar, and I couldn't have asked for a better experience. The hotel's location was incredibly convenient, with quick access to major IT parks and corporate offices. The business center was well-equipped, and the high-speed Wi-Fi allowed me to stay connected without any interruptions. The staff was professional and attentive, making sure all my needs were met. The comfortable rooms and excellent dining options were an added bonus. I highly recommend Grand Legacy inn hotels  for any business traveler in Pune!",
      author: "Mr. Rajesh Patel, Senior Manager at Tech Solutions Ltd.",
    },
    {
      quote: "Grand Legacy inn hotels  exceeded my expectations for a corporate stay in Viman Nagar. The amenities provided, such as the versatile meeting rooms and ergonomic workstations, made it easy for me to conduct business efficiently. The staff was incredibly accommodating, and the location was perfect for accessing local business hubs. I appreciated the quiet and comfortable environment that allowed me to focus on work. Whether you're visiting Pune for business or need a reliable base for corporate meetings, this hotel is a top choice.",
      author: "Mrs. Anita Sharma, Director of Operations at InnovateTech Inc.",
    },
  ];


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    "name": "Grand Legacy inn hotels ",
    "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      "addressLocality": "Pune,Viman Nagar",
      "addressRegion": "MH",
      "postalCode": "411014",
      "addressCountry": "IN"
    },
    "telephone": "+91 7058638153",
    "image": "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    "starRating": {
      "@type": "Rating",
      "bestRating": "5",
      "ratingValue": "3",
      "worstRating": "1"
    },
   
    "sameAs": [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ]
  };

return(
    <>
     <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
     <script type="application/ld+json">
      {JSON.stringify(schemaData)}
    </script>
        <title>Contact:+91 7058638153 | Grand Legacy inn hotels  | Best Hotel Booking for Corporate in Viman Nagar</title>
        <meta name="description" content="Find the best hotel booking for corporate in Viman Nagar at Grand Legacy inn hotels . Offering affordable rates for business stays, family-friendly rooms, and monthly rental options. Contact +91 7058638153." />
        <meta name="keywords" content="Best hotel booking for corporate in Viman Nagar, Cheap hotel booking for corporate in Viman Nagar, Cheap hotel booking for corporate in Viman Nagar for family, hotel room rent monthly in Viman Nagar, hotel room rent monthly in Kharadi, hotel room rent monthly near Pune airport, hotel room rent monthly near Magarpatta city, hotel on rent monthly basis in Wadgaon Sheri, Top Guest House for Monthly Rental in Pune, Hotel room rent monthly basis in Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="+91 7058638153 | Grand Legacy inn hotels  | Best Hotel Booking for Corporate in Viman Nagar" />
        <meta property="og:description" content="Grand Legacy inn hotels  offers corporate hotel booking in Viman Nagar. Ideal for long-term stays with monthly rental options for corporate clients. Call +91 7058638153 to book now." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotel Booking for corporate in Viman  nagar.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1> Hotel Booking for Corporate in Viman Nagar  </h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span> Hotel Booking for Corporate in Viman Nagar 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="Hotel-Images/Hotel Booking for corporate in Viman nagar.jpg" className="img-fluid" alt="" />
              </div>
              <h2>
                Discover the Best Hotel Booking for Corporate Stays in Viman
                Nagar
              </h2>
              <p>
                When it comes to hotel booking for corporate stays in Viman
                Nagar, Grand Legacy inn hotels  stands out as the ideal choice for
                business professionals seeking comfort, convenience, and
                exceptional service. Our strategically located hotel caters
                specifically to the needs of corporate travelers, offering a
                range of amenities and services designed to enhance productivity
                and ensure a seamless business experience.
              </p>

              <h2>Best Hotel Booking for Corporate in Viman Nagar</h2>
              <p>
                Grand Legacy inn hotels  is renowned for being the best hotel
                booking choice for corporate stays in Viman Nagar. Our
                strategically located hotel caters to the unique needs of
                business travelers, offering a combination of comfort,
                convenience, and high-quality amenities. Positioned in the heart
                of Pune’s bustling business district, our hotel provides quick
                access to major IT parks, corporate offices, and key business
                hubs. With our comprehensive business facilities, including a
                state-of-the-art business center, high-speed Wi-Fi, and
                versatile meeting rooms, we ensure that your corporate stay is
                both productive and enjoyable.
              </p>

              <h2>Cheap Hotel Booking for Corporate in Viman Nagar</h2>
              <p>
                For those seeking cheap hotel booking for corporate stays in
                Viman Nagar, Grand Legacy inn hotels  offers an excellent balance
                of affordability and quality. We provide competitive rates that
                do not compromise on comfort or service, making it possible for
                business travelers to stay within budget while enjoying
                top-notch amenities. Our hotel features comfortable rooms,
                modern business facilities, and an on-site restaurant, all at
                economical prices that cater to cost-conscious professionals.
              </p>

              <h2>
                Cheap Hotel Booking for Corporate in Viman Nagar for Family
              </h2>
              <p>
                If you're looking for cheap hotel booking for corporate stays in
                Viman Nagar for family, Grand Legacy inn hotels  provides ideal
                accommodations that cater to both business and family needs. Our
                hotel offers spacious rooms that can comfortably accommodate
                families traveling with business professionals. With amenities
                such as high-speed Wi-Fi, a business center, and a variety of
                dining options, we ensure that both business and leisure needs
                are met, all at an affordable rate.
              </p>

              <h2>Hotel Room Rent Monthly in Viman Nagar</h2>
              <p>
                For extended stays, Grand Legacy inn hotels  offers competitive
                hotel room rent monthly in Viman Nagar. Our monthly rental
                options are perfect for business travelers who need long-term
                accommodation in Pune. With well-equipped rooms, a dedicated
                workspace, and access to all our business facilities, you can
                enjoy a comfortable and productive stay. Contact us directly to
                inquire about our monthly rates and special offers tailored for
                long-term stays.
              </p>

              <h2>Hotel Room Rent Monthly in Kharadi</h2>
              <p>
                In addition to Viman Nagar, Grand Legacy inn hotels  also provides
                hotel room rent monthly in Kharadi. Our Kharadi location offers
                the same high standards of comfort and business facilities as
                our Viman Nagar property. Ideal for professionals needing
                extended stays in the Kharadi area, our monthly rental options
                include spacious rooms with ergonomic workstations, high-speed
                internet, and easy access to local business hubs. Book with us
                for a convenient and cost-effective long-term stay solution in
                Kharadi.
              </p>

              <h2>Hotel Room Rent Monthly Near Pune Airport</h2>
              <p>
                When you need hotel room rent on a monthly basis near Pune
                Airport, Grand Legacy inn hotels  offers the perfect solution. Our
                conveniently located hotel provides comfortable and
                well-appointed rooms just a short drive from the airport, making
                it ideal for frequent travelers or business professionals with
                early flights. Enjoy the ease of access to airport facilities
                along with our exceptional monthly rates, which include
                high-speed Wi-Fi, modern amenities, and a range of business
                services to support your extended stay.
              </p>

              <h2>Hotel Room Rent Monthly Near Magarpatta City</h2>
              <p>
                For those seeking hotel room rent monthly near Magarpatta City,
                Grand Legacy inn hotels  is an excellent choice. Our hotel is
                strategically positioned to offer easy access to the vibrant
                Magarpatta business district, known for its IT parks and
                commercial hubs. Our monthly rental options provide a blend of
                comfort and convenience with spacious rooms, ergonomic
                workstations, and high-speed internet. Whether you’re in town
                for work or leisure, enjoy a productive and relaxing stay close
                to Magarpatta City.
              </p>

              <h2>Hotel on Rent Monthly Basis in Wadgaon Sheri</h2>
              <p>
                Grand Legacy inn hotels  also offers attractive monthly rental
                options in Wadgaon Sheri. Situated in a prominent business area,
                our hotel caters to professionals and families seeking extended
                stays. With well-furnished rooms, comprehensive business
                facilities, and easy access to local amenities, our hotel
                provides everything you need for a comfortable monthly stay.
                Enjoy the convenience of a central location combined with
                affordable rates and exceptional service.
              </p>

              <h2>Top Guest House for Monthly Rental in Pune</h2>
              <p>
                As one of the top guest houses for monthly rental in Pune,
                Grand Legacy Inn Hotels stands out for its excellent service and
                comfortable accommodations. Our hotel is designed to cater to
                long-term guests, offering flexible rental options and a range
                of amenities that make extended stays enjoyable. From
                well-equipped rooms to dedicated business services and dining
                options, we ensure that every guest feels at home during their
                stay.
              </p>

              <h2>Hotel Room Rent Monthly Basis in Pune</h2>
              <p>
                Grand Legacy inn hotels  provides a variety of options for hotel
                room rent on a monthly basis in Pune. Our competitive rates and
                high-quality accommodations are perfect for individuals or
                families needing long-term stays. Choose from our locations in
                Viman Nagar, Kharadi, and other prime areas, each offering
                unique benefits and proximity to key business hubs and
                amenities. Our monthly rentals include access to our
                comprehensive facilities, ensuring a comfortable and productive
                stay throughout your time in Pune.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Best hotel booking for corporate in Viman Nagar</p>
                      <p>- Cheap hotel booking for corporate in Viman Nagar</p>
                      <p>
                        - Cheap hotel booking for corporate in Viman Nagar for
                        family
                      </p>
                      <p>- Hotel room rent monthly in Viman Nagar</p>
                      <p>- Hotel room rent monthly in Kharadi</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Hotel room rent monthly near Pune Airport</p>
                      <p>- Hotel room rent monthly near Magarpatta City</p>
                      <p>- Hotel on rent monthly basis in Wadgaon Sheri</p>
                      <p>- Top Guest House for Monthly Rental in Pune</p>
                      <p>- Hotel room rent monthly basis in Pune</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels  for Hotel Booking for
                Corporate in Viman Nagar?
              </h2>

              <p>
                When it comes to hotel booking for corporate stays in Viman
                Nagar, Grand Legacy inn hotels  is the premier choice for business
                professionals seeking a blend of comfort, convenience, and
                high-quality service. Here’s why our hotel stands out as the
                ideal option for your corporate needs:
              </p>

              <h2>1. Prime Location</h2>
              <p>
                Grand Legacy inn hotels  is strategically located in the heart of
                Viman Nagar, Pune's bustling business district. This prime
                location offers:
              </p>
              <ul>
                <li>
                  <strong>
                    Easy Access to Major IT Parks and Corporate Offices:
                  </strong>{" "}
                  Close proximity to key business hubs such as the EON IT Park
                  ensures that you can reach your meetings and client sites
                  quickly and efficiently.
                </li>
                <li>
                  <strong>Convenient Travel Connections:</strong> Just a short
                  drive from Pune International Airport and major transportation
                  links, making your travel hassle-free.
                </li>
              </ul>

              <h2>2. Comprehensive Business Facilities</h2>
              <p>
                Our hotel is designed to support your professional activities
                with a range of dedicated business amenities:
              </p>
              <ul>
                <li>
                  <strong>State-of-the-Art Business Center:</strong> Equipped
                  with the latest technology and office supplies to facilitate
                  your work needs.
                </li>
                <li>
                  <strong>High-Speed Wi-Fi:</strong> Reliable and fast internet
                  access throughout the hotel, ensuring you stay connected at
                  all times.
                </li>
                <li>
                  <strong>Versatile Meeting Rooms:</strong> Flexible meeting
                  spaces with audiovisual equipment, ideal for conferences,
                  presentations, and business discussions.
                </li>
              </ul>

              <h2>3. Comfortable and Functional Accommodations</h2>
              <p>
                Grand Legacy inn hotels  provides accommodations that cater
                specifically to the needs of corporate travelers:
              </p>
              <ul>
                <li>
                  <strong>Ergonomic Workstations:</strong> Each room features a
                  comfortable desk and chair, providing an ideal space for
                  working.
                </li>
                <li>
                  <strong>Premium Bedding:</strong> Ensures a restful sleep so
                  you can be at your best for important meetings and events.
                </li>
                <li>
                  <strong>Modern Amenities:</strong> Rooms are equipped with
                  flat-screen TVs, minibars, and well-appointed bathrooms for a
                  convenient and comfortable stay.
                </li>
              </ul>

              <h2>4. Exceptional Dining Options</h2>
              <p>
                Enjoy a variety of dining options without leaving the hotel:
              </p>
              <ul>
                <li>
                  <strong>On-Site Restaurant:</strong> Offering a diverse menu
                  of local and international dishes, perfect for business meals
                  or a relaxing dinner after a busy day.
                </li>
                <li>
                  <strong>Room Service:</strong> Available for those who prefer
                  to dine in the comfort of their room or need a meal after
                  late-night work.
                </li>
              </ul>

              <h2>5. Professional and Attentive Service</h2>
              <p>
                Our dedicated team at Grand Legacy inn hotels  is committed to
                providing outstanding service:
              </p>
              <ul>
                <li>
                  <strong>Personalized Assistance:</strong> From check-in to
                  check-out, our staff is here to cater to your specific needs
                  and requests.
                </li>
                <li>
                  <strong>24/7 Support:</strong> We offer round-the-clock
                  assistance to ensure that all aspects of your stay are handled
                  smoothly.
                </li>
              </ul>

              <h2>6. Competitive Corporate Rates</h2>
              <p>
                We offer special corporate rates that provide excellent value
                for high-quality accommodations and services. Booking directly
                with us ensures you receive the best rates and additional
                benefits tailored to business travelers.
              </p>

              <h2>7. Local Amenities and Attractions</h2>
              <p>
                While focusing on business, enjoy the convenience of being close
                to:
              </p>
              <ul>
                <li>
                  <strong>Shopping and Dining Options:</strong> Explore local
                  shops, restaurants, and recreational activities in Viman
                  Nagar.
                </li>
                <li>
                  <strong>Cultural and Entertainment Venues:</strong> Take
                  advantage of your downtime to experience Pune’s vibrant
                  cultural scene.
                </li>
              </ul>
              <div className="">
                  <h2>
                  FAQs for Grand Legacy inn hotels  with the Hotel Booking for Corporate in Viman Nagar?
                  </h2>
                  {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className={`faq-question ${
                          activeIndex === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {index + 1}. {faq.question}
                      </div>
                      {activeIndex === index && (
                        <div className="faq-answer">{faq.answer}</div>
                      )}
                    </div>
                  ))}
                </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonials for Hotel Booking for Corporate in Viman Nagar -  Grand Legacy Inn Hotels{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" contact-section">
                  <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                  <p>
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong>Mobile No:</strong>{" "}
                    <a href="tel:+917058638153">+91 7058638153</a>
                  </p>
                  <p>
                    <strong>Email Id:</strong>{" "}
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                     reservation@grandlegacyinnhotels.com
                    </a>
                  </p>
                </div>

            </div>
            <div class="col-12 col-md-4">
            <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

              

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
           
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
