import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function PuneAirportHotels() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question:
        "How far is Grand Legacy inn hotels  from Pune International Airport?",
      answer:
        "Grand Legacy inn hotels  is conveniently located approximately [insert distance or time, e.g., 5 kilometers or 15 minutes] from Pune International Airport. Its close proximity ensures a quick and easy transfer between the airport and the hotel.",
    },
    {
      question: "Does Grand Legacy inn hotels  offer airport shuttle services?",
      answer:
        "Yes, Grand Legacy inn hotels  provides airport shuttle services to make your travel experience as smooth as possible. Please contact the hotel in advance to arrange for shuttle services and confirm availability.",
    },
    {
      question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
       answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
   },
    {
      question: "Can I book a room at Grand Legacy inn hotels  for a few hours?",
      answer:
        "Yes, Grand Legacy inn hotels  offers flexible booking options, including hourly stays. This is ideal for travelers with layovers or those needing a brief rest. Please contact the hotel directly for details on hourly rates and availability.",
    },
    {
      question: "What amenities are available at Grand Legacy inn hotels ?",
      answer:
        "Grand Legacy inn hotels  offers a range of amenities including [list key amenities, e.g., complimentary Wi-Fi, air-conditioned rooms, flat-screen TVs, an on-site restaurant, and a 24-hour front desk]. Our goal is to ensure a comfortable and enjoyable stay for all guests.",
    },
    {
      question: "Is Wi-Fi available at Grand Legacy inn hotels ?",
      answer:
        "Yes, free high-speed Wi-Fi is available throughout the property at Grand Legacy inn hotels , allowing you to stay connected during your stay.",
    },
    {
      question: "Are there dining options at Grand Legacy inn hotels ?",
      answer:
        "Yes, Grand Legacy inn hotels  features an on-site restaurant where guests can enjoy a variety of dishes. The restaurant offers [brief description of cuisine or meal options, e.g., local and international cuisine] to suit different tastes and preferences.",
    },
    {
      question:
        "Does Grand Legacy inn hotels  have facilities for business travelers?",
      answer:
        "Yes, the hotel is equipped with facilities designed for business travelers, including [mention facilities, e.g., a business center, meeting rooms, and high-speed internet access]. We aim to support your business needs and provide a productive environment.",
    },
    {
      question:
        "Are family-friendly amenities available at Grand Legacy inn hotels ?",
      answer:
        "Grand Legacy inn hotels  offers family-friendly amenities such as [list amenities, e.g., spacious rooms, extra bedding upon request, and kid-friendly meal options]. We strive to accommodate families and ensure a pleasant stay for guests of all ages.",
    },
    {
      question: "What is the cancellation policy at Grand Legacy inn hotels ?",
      answer:
        "The cancellation policy at Grand Legacy inn hotels  varies depending on the rate type and booking conditions. Please review the cancellation policy during the booking process or contact the hotel directly for specific details.",
    },
    {
     question: "How can I make a reservation at Grand Legacy inn hotels ?",
      answer: "Reservations can be made directly through our website, by phone at [+91 7058638153], or by email at [reservation@grandlegacyinnhotels.coms].",
     
       
    },
    {
      question: "Is parking available at Grand Legacy inn hotels ?",
      answer:
        "Yes, Grand Legacy inn hotels  provides parking facilities for guests. Please inquire about parking availability and any associated fees at the time of booking or upon arrival.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];


  const testimonials = [
    {
      quote:
        "Grand Legacy inn hotels  is the perfect choice for anyone needing a comfortable and convenient stay near Pune Airport. I stayed there during a recent business trip, and I was thoroughly impressed by the quality of service and the modern amenities. The rooms were spacious and well-appointed, providing a great space to relax after a long flight. The staff was exceptionally friendly and efficient, ensuring my check-in and check-out were seamless. The proximity to the airport was incredibly convenient, making my travels stress-free. I highly recommend Grand Legacy inn hotels  for anyone seeking a top-notch airport hotel experience.",
      author: "—Mr. Rohit Sharma",
    },
    {
      quote:
        "My experience at Grand Legacy inn hotels  was fantastic! As a frequent flyer, finding a hotel that offers both comfort and proximity to the airport is crucial, and this place exceeded my expectations. The rooms were stylish, clean, and very comfortable, with all the amenities I needed for a pleasant stay. The hotel’s location made my early morning flight a breeze. I was particularly impressed with the attentive and courteous staff who went out of their way to make sure my stay was enjoyable. For anyone traveling through Pune and needing a reliable airport hotel, this is the perfect choice.",
      author: "—Mrs. Priya Mehta",
    },
  ];
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact +91 7058638153| Grand Legacy inn hotels  | Pune Airport Hotels:
          Cheap, Budget, Luxury, Best Options
        </title>
        <meta
          name="description"
          content="Discover Grand Legacy inn hotels , the top choice for hotels near Pune Airport. We offer a range of accommodations including cheap, budget, and luxury options. Conveniently located near Viman Nagar and Pune Domestic Airport. Contact +91 7058638153 for the best deals and bookings."
        />
        <meta
          name="keywords"
          content="Hotels Near Pune Airport, Hotel booking in Pune near airport, Hotels near Viman Nagar Airport Pune, Cheap hotels near Pune airport, Best hotels in Pune near airport, Budget hotels near Pune airport, Near Pune airport hotels, Pune near airport hotel, Good hotels near Pune airport, Grand Legacy inn hotels , Airport hotel Pune, Budget hotels in Pune near airport, Hotels near Pune domestic airport, Hotels close to Pune airport, Luxury hotels near Pune airport"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Grand Legacy inn hotels  - Contact +91 7058638153 | Pune Airport Hotels: Cheap, Budget, Luxury, Best Options"
        />
        <meta
          property="og:description"
          content="Find the best hotels near Pune Airport at Grand Legacy inn hotels . Offering cheap, budget, and luxury accommodations. Located near Viman Nagar and Pune Domestic Airport. Contact +91 7058638153 for reservations and inquiries."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/pune-airport-hotels-grand-legacy.jpg"
        />
        <meta
          property="og:url"
          content="https://www.yourwebsite.com/pune-airport-hotels"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Pune Airport Hotels </h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Pune Airport Hotels
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Pune Airport Hotels.jpg" className="img-fluid" alt="Pune Airport Hotels" />
              </div>
              <h2>
                Discover the Best Pune Airport Hotels: Grand Legacy inn hotels 
              </h2>
              <p>
                When traveling to Pune, finding the perfect accommodation near
                Pune International Airport is crucial for a smooth and
                stress-free journey. Grand Legacy inn hotels  stands out as a
                premier choice among Pune Airport Hotels, offering exceptional
                comfort, convenience, and value. Here’s why Grand
                Legacy Inn Hotels should be at the top of your list for your next stay near
                the Pune Airport.
              </p>

              <h2>Hotels Near Pune Airport</h2>
              <p>
                Choosing the right Hotels Near Pune Airport ensures you have a
                comfortable and stress-free stay close to your flight. These
                hotels offer convenient access to Pune International Airport,
                making them ideal for both short layovers and extended stays.
                Enjoy amenities such as free Wi-Fi, airport shuttles, and
                flexible check-in options to make your travel experience
                seamless.
              </p>

              <h2>Hotel Booking in Pune Near Airport</h2>
              <p>
                When it comes to Hotel Booking in Pune Near Airport, you'll find
                a variety of options to suit every preference and budget. Many
                hotels offer easy online booking systems, allowing you to secure
                your accommodation with just a few clicks. Look for properties
                that provide competitive rates, excellent amenities, and
                proximity to the airport to ensure a pleasant stay.
              </p>

              <h2>Hotels Near Viman Nagar Airport Pune</h2>
              <p>
                If you're specifically looking for Hotels Near Viman Nagar
                Airport Pune, you're in luck. Viman Nagar is a bustling area
                with several hotels offering quick access to Pune's domestic
                terminal. This area is also known for its vibrant dining and
                shopping scene, making it a convenient and enjoyable place to
                stay during your visit.
              </p>

              <h2>Cheap Hotels Near Pune Airport</h2>
              <p>
                Traveling on a budget? Cheap Hotels Near Pune Airport provide
                affordable yet comfortable accommodation options. These
                budget-friendly hotels offer essential amenities such as clean
                rooms, free Wi-Fi, and complimentary breakfast, allowing you to
                stay close to the airport without breaking the bank. Perfect for
                travelers seeking value for money.
              </p>

              <h2>Best Hotels in Pune Near Airport</h2>
              <p>
                For those seeking top-quality accommodation, the Best Hotels in
                Pune Near Airport offer a blend of luxury and convenience. These
                hotels feature premium amenities, including upscale rooms, fine
                dining options, and exceptional service. Whether you're looking
                for a high-end experience or just want the best in comfort and
                service, these hotels provide a memorable stay.
              </p>

              <h2>Budget Hotels Near Pune Airport</h2>
              <p>
                Budget Hotels Near Pune Airport are ideal for travelers looking
                for cost-effective options that don’t compromise on quality.
                These hotels offer basic yet comfortable amenities at reasonable
                prices. Expect clean rooms, essential services, and friendly
                staff, ensuring a pleasant stay close to the airport without
                overspending.
              </p>

              <h2>Near Pune Airport Hotels</h2>
              <p>
                Choosing Near Pune Airport Hotels offers the convenience of
                being close to the airport, making it easier to manage your
                travel schedule. These hotels provide quick access to Pune
                International Airport and a range of amenities designed to make
                your stay comfortable and hassle-free. Enjoy features like free
                airport shuttles, flexible check-in times, and modern
                accommodations.
              </p>

              <h2>Pune Near Airport Hotel</h2>
              <p>
                For those specifically searching for a Pune Near Airport Hotel,
                you’ll find a variety of options that cater to different
                preferences and budgets. These hotels are strategically located
                to offer easy access to both domestic and international
                terminals, ensuring that you can transition smoothly between
                your flight and your stay.
              </p>

              <h2>Good Hotels Near Pune Airport</h2>
              <p>
                Finding Good Hotels Near Pune Airport means selecting
                accommodations that offer a blend of comfort, convenience, and
                quality service. Look for hotels that provide clean and
                comfortable rooms, reliable customer service, and essential
                amenities like high-speed Wi-Fi and on-site dining. Positive
                guest reviews and ratings can help you identify the best
                options.
              </p>

              <h2>Grand Legacy inn hotels </h2>
              <p>
                Grand Legacy inn hotels  is a standout choice among Near Pune
                Airport Hotels. Located conveniently close to the airport, this
                hotel offers modern, well-furnished rooms, exceptional customer
                service, and a range of amenities including a restaurant and
                business facilities. Whether you’re in Pune for a short stay or
                a longer visit, Grand Legacy inn hotels  provides a comfortable
                and convenient base.
              </p>

              <h2>Airport Hotel Pune</h2>
              <p>
                When searching for an Airport Hotel Pune, look for properties
                that prioritize convenience and ease of access to Pune
                International Airport. These hotels typically offer features
                such as airport transfers, flexible booking options, and
                amenities designed to accommodate travelers’ needs, making them
                ideal for both short and extended stays.
              </p>

              <h2>Budget Hotels in Pune Near Airport</h2>
              <p>
                For those on a budget, Budget Hotels in Pune Near Airport
                provide affordable accommodation without compromising on
                comfort. These hotels offer clean, functional rooms and
                essential services at a lower price point, allowing you to stay
                close to the airport while keeping your expenses in check.
              </p>

              <h2>Hotels Near Pune Domestic Airport</h2>
              <p>
                If you’re traveling through Pune Domestic Airport, Hotels Near
                Pune Domestic Airport offer proximity to the terminal and
                convenience for travelers. These hotels cater specifically to
                passengers arriving or departing from domestic flights,
                providing amenities that suit both short layovers and longer
                stays.
              </p>

              <h2>Hotels Close to Pune Airport</h2>
              <p>
                Hotels Close to Pune Airport provide easy access for travelers
                who need to be near the airport for early flights or late
                arrivals. These accommodations typically offer features like
                free parking, airport shuttle services, and comfortable rooms,
                ensuring a smooth and stress-free stay.
              </p>

              <h2>Luxury Hotels Near Pune Airport</h2>
              <p>
                For those seeking a more luxurious experience, Luxury Hotels
                Near Pune Airport offer premium accommodations and high-end
                amenities. Enjoy upscale rooms, gourmet dining options, and
                top-notch service, all within a short distance from the airport.
                These hotels are perfect for travelers who want to indulge in
                comfort and sophistication.
              </p>

              <h2>
                Why Choose Grand Legacy inn hotels  for Pune Airport Hotels?
              </h2>

              <p>
                <strong>1. Prime Location:</strong> Grand Legacy inn hotels  is
                ideally situated just a short drive from Pune International
                Airport, making it one of the most conveniently located Pune
                Airport Hotels. This proximity ensures that you can quickly
                transition between your flight and your accommodation, saving
                you valuable time and reducing travel stress.
              </p>

              <p>
                <strong>2. Modern and Comfortable Rooms:</strong> Our hotel
                features modern, well-furnished rooms designed to provide the
                utmost comfort. Each room is equipped with plush bedding,
                high-speed Wi-Fi, a flat-screen TV, and air conditioning,
                ensuring a relaxing and enjoyable stay. Whether you’re in town
                for business or leisure, our accommodations cater to all your
                needs.
              </p>

              <p>
                <strong>3. Exceptional Customer Service:</strong> At
                Grand Legacy Inn Hotels, we pride ourselves on delivering top-notch
                customer service. Our friendly and professional staff are
                dedicated to making your stay as pleasant as possible. From
                smooth check-in processes to personalized assistance, our team
                is committed to providing a memorable experience.
              </p>

              <p>
                <strong>4. Convenient Amenities:</strong> Our Pune Airport Hotel
                offers a range of convenient amenities to enhance your stay.
                Enjoy on-site dining options with a variety of delicious meals,
                free Wi-Fi throughout the property, and comfortable common areas
                for relaxation. We also offer flexible booking options,
                including hourly stays, perfect for guests with layovers or
                varying travel schedules.
              </p>

              <p>
                <strong>5. Easy Airport Transfers:</strong> To further
                streamline your travel experience, Grand Legacy inn hotels 
                provides airport transfer services upon request. This added
                convenience ensures a seamless journey between the airport and
                your hotel, allowing you to focus on enjoying your time in Pune.
              </p>

              <p>
                <strong>6. Family-Friendly Accommodations:</strong> Traveling
                with family? Our hotel is well-equipped to accommodate families
                with spacious rooms and additional services tailored to guests
                of all ages. We aim to provide a comfortable and enjoyable stay
                for everyone in your group.
              </p>

              <p>
                <strong>7. Competitive Rates:</strong> Grand Legacy inn hotels 
                offers competitive rates that provide excellent value for money.
                Our pricing is designed to be affordable while maintaining high
                standards of comfort and service, ensuring you get the best
                possible experience without breaking the bank.
              </p>

              <p>
                <strong>8. Positive Guest Reviews:</strong> Our guests
                consistently leave positive feedback about their stays at
                Grand Legacy Inn Hotels. Reviews highlight our hotel’s convenient
                location, comfortable rooms, and attentive service. These
                testimonials reflect our commitment to guest satisfaction and
                make us a trusted choice among Pune Airport Hotels.
              </p>

              <p>
                <strong>9. Explore Pune’s Attractions:</strong> Staying at 
                Grand Legacy Inn Hotels not only provides convenience for airport
                access but also allows you to explore the vibrant Viman Nagar
                area. With shopping centers, dining options, and local
                attractions nearby, you can make the most of your time in Pune
                beyond your airport stay.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Hotel booking in pune near airport</p>
                      <p>- Hotels near Viman Nagar Airport Pune</p>
                      <p>- Cheap hotels near pune airport</p>
                      <p>- Best hotels in pune near airport</p>
                      <p>- Budget hotels near pune airport</p>
                      <p>- Near pune airport hotels</p>
                      <p>- Pune near airport hotel</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Hotels Near Pune Airport</p>
                      <p>- Good hotels near pune airport</p>
                      <p>- Grand Legacy inn hotels </p>
                      <p>- Airport hotel Pune</p>
                      <p>- Budget hotels in pune near airport </p>
                      <p>- Hotels near pune domestic airport</p>
                      <p>- Hotels close to pune airport</p>
                      <p>- Luxury hotels near pune airport</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="">
                <h2>FAQs for Pune Airport Hotels - Grand Legacy inn hotels </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

              {/* ======================== */}
              <div className="">
                <h2>
                  Testimonials for Pune Airport Hotels - Grand Legacy inn hotels :
                  -{" "}
                </h2>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-slide">
                      <blockquote>
                        <p>{testimonial.quote}</p>
                        <footer> {testimonial.author}</footer>
                      </blockquote>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* ================= */}
              <div className=" contact-section">
                <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
