import "./App.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./TestimonialCarousel.css"; 
import { Link } from "react-router-dom";
export default function About() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const services = [
    {
      id: "01",
      icon: "./img/icon/cleaning-1.png",
      title: "Room Cleaning",
      description:
        "Proin massa augue, lacinia at blandit ac, Fringilla scelerisque tortor. Mauris",
    },
    {
      id: "02",
      icon: "./img/icon/car-parking.png",
      title: "Car Parking",
      description:
        "Proin massa augue, lacinia at blandit ac, Fringilla scelerisque tortor. Mauris",
    },

    {
      id: "04",
      icon: "./img/icon/location-1.png",
      title: "PickUp & Drop",
      description:
        "Proin massa augue, lacinia at blandit ac, Fringilla scelerisque tortor. Mauris",
    },
  ];

  const galleryItems = [
    "grand legacy rooms/grand legacy room10.jpg",
    "./website img/NANASHREE 14.jpg",
    "grand legacy rooms/grand leagcy room11.jpg",
    "grand legacy rooms/grand legacy room3.jpg",
  ];

  
  const testimonials = [
    {
      text: `"I had an amazing stay at Grand Legacy Inn Hotels. The location in Viman Nagar is perfect, with everything you need just a short walk away. The room was spacious, clean, and beautifully decorated. The staff were incredibly friendly and attentive, making me feel right at home. I highly recommend this hotel for anyone visiting Pune!"`,
      name: "Mrs. Aditi Sharma",
      title: "A Perfect Stay in Viman Nagar",
    },
    {
      text: `"Grand Legacy Inn Hotels exceeded my expectations in every way. From the warm welcome at check-in to the luxurious room amenities, everything was top-notch. The on-site restaurant offered a fantastic variety of dishes, and the business facilities were perfect for my needs. I’ll definitely be staying here again on my next trip to Pune."`,
      name: "Mr. Rajesh Kumar",
      title: "Exceptional Service and Comfort",
    },
  ];


  const images = [
     "./website img/NANASHREE 01. COPY jpg.jpg",
        "./website img/NANASHREE 03.jpg",
        "./website img/NANASHREE 06.jpg",
        "./website img/NANASHREE 07.jpg",
          "./website img/NANASHREE 15.jpg",
          "./website img/NANASHREE 16.jpg",
          "./website img/NANASHREE 17.jpg",
          "./website img/NANASHREE 18.jpg",
          "./website img/NANASHREE 19.jpg",
          "./website img/NANASHREE 20.jpg",
            "./website img/NANASHREE 12.jpg",
            "./website img/NANASHREE 14.jpg",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const setting = {
    // dots: true,         // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Delay between slides in milliseconds
  };



  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/images/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
       "https://grandlegacyinnhotels.com/hotel-in-viman-nagar",
       "https://grandlegacyinnhotels.com/viman-nagar-hotels-for-stay",
       "https://grandlegacyinnhotels.com/hotels-near-viman-nagar",
       "https://grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
       "https://grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
        "https://grandlegacyinnhotels.com/pune-airport-hotel",
        "https://grandlegacyinnhotels.com/hotels-near-pune-international-airport",
        "https://grandlegacyinnhotels.com/pune-near-airport-hotel",
        "https://grandlegacyinnhotels.com/pune-airport-hotels",
        "https://grandlegacyinnhotels.com/hotel-wadgaon-sheri",
        "https://grandlegacyinnhotels.com/online-hotel-booking-pune",
        "https://grandlegacyinnhotels.com/hotel-for-stay-lohegaon-airport",
        "https://grandlegacyinnhotels.com/hotel-near-me-pune-airport",
        "https://grandlegacyinnhotels.com/hotels-near-kharadi",
        "https://grandlegacyinnhotels.com/hotel-in-koregaon-park",
        "https://grandlegacyinnhotels.com/best-hotels-in-pune-for-couples",
        "https://grandlegacyinnhotels.com/hotel-in-kalyni-nagar",
        "https://grandlegacyinnhotels.com/luxury-hotel-pune-nagar-road",
        "https://grandlegacyinnhotels.com/hotels-in-kharadi",
        "https://grandlegacyinnhotels.com/business-stays-in-viman-nagar",
        "https://grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        {/* Schema Markup */}
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

        <title>
          Contact:+91 7058638153 | Grand Legacy inn hotels  |About | Viman Nagar
          Hotel Booking and Stay Options| Hotel Near Me Pune Airport | Hotels
          Near Kharadi | Hotel in Koregaon Park | Best Hotels in Pune for
          Couples
        </title>
        <meta
          name="description"
          content="Book the best hotels in Viman Nagar, Pune for your stay. Find couple-friendly hotels, business hotels, 3-star and 4-star hotels near Pune International Airport. Call +91 7058638153 for bookings."
        />
        <meta
          name="keywords"
          content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, Business hotels in Viman Nagar, Best hotels in Viman Nagar Pune, Couple-friendly hotels in Viman Nagar, 3-star hotels in Viman Nagar, Hotels near Pune International Airport, 3-star hotels near Pune airport, Hotels near Pune domestic airport, 4-star hotels near Pune airport, Best hourly hotels near Pune International Airport"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="+91 7058638153 | Grand Legacy inn hotels  |About | Viman Nagar Hotel Booking | Hotel Near Me Pune Airport
         Hotels Near Kharadi"
        />
        <meta
          property="og:description"
          content="Book your stay at Grand Legacy inn hotels , one of the best hotels in Viman Nagar. We offer couple-friendly and business hotel rooms, as well as 3-star and 4-star hotel options near Pune Airport. Call +91 7058638153."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg"
        />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/about" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-1.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>About Us</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>About Us
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      {/* <!-- WELCOME SECTION START --> */}
      <div class="section-padding">
        <div class="container">
          <div class="section-content">
            <div class="row d-flex align-items-center">
              <div class="col-lg-6 col-md-12 text-black">
                {/* <!-- TITLE START --> */}
                <div class="section-head text-left">
                  <h2 class=" m-b5" data-title="About">
                    About Grand Legacy inn hotels {" "}
                  </h2>
                </div>
                {/* <!-- TITLE END --> */}
                <h4 class=" m-t0">
                  {/* <!-- Attractive Title --> */}
                  Discover the Wonders of Grand Legacy inn hotels 
                </h4>
                <p>
                  Welcome to Grand Legacy inn hotels , where luxury meets comfort.
                  Our journey began with a vision to provide exceptional
                  hospitality and unparalleled service in the vibrant city of
                  Pune. Founded by
                  <strong> Mr.Vishal Suresh satav </strong>, Hotel Grand Legacy
                  Inn has grown to become a preferred choice for travelers
                  seeking a memorable and relaxing stay. Our hotel offers
                  spacious rooms, impeccable amenities, and a warm and welcoming
                  atmosphere. From the moment you step into our hotel, you will
                  feel at home, surrounded by the finest touches that make your
                  stay unforgettable. Our dedicated team ensures that every
                  detail is taken care of, from the refreshing rooftop bar to
                  the delectable cuisine in our restaurants. We strive to create
                  a memorable stay experience by blending luxury, comfort, and
                  personalized service. Whether you are here for business or
                  leisure, Grand Legacy inn hotels  is the perfect choice for
                  making your Pune experience truly unforgettable.
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <div className="hotel-menu-slider">
                  <Slider {...setting}>
                  <div className="slider-item">
                      <img src="./Hotel-Images/breakfast food, Lunch & Dinner menu-03.jpg" alt="Menu Item 3" />
                    </div>
                    
                    <div className="slider-item">
                      <img src="./Hotel-Images/breakfast food, Lunch & Dinner menu-01.jpg" alt="Menu Item 1" />
                    </div>
                    <div className="slider-item">
                      <img src="./Hotel-Images/breakfast food, Lunch & Dinner menu-02.jpg" alt="Menu Item 2" />
                    
                    </div>

                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- WELCOME  SECTION END --> */}

      {/* ============== visio and mission ======================= */}
      <div className="services__area">
        <div className="container">
          <div class="row visionbox">
            <div class="col-12 col-md-6 text-center d-flex flex-column justify-content-center align-items-center">
              <h2 className="textgreen fw-bold">Our Vision</h2>
              <p>
                "To be the premier choice for travelers seeking exceptional
                hospitality, where comfort, elegance, and personalized service
                come together to create memorable experiences. We strive to set
                new standards in the hospitality industry by consistently
                delivering outstanding service, fostering innovation, and
                creating a welcoming environment that exceeds the expectations
                of our guests."
              </p>
            </div>
            <div class="col-12 col-md-6">
              <img src="./Hotel-Images/Our Vision & Mission Images-01.jpg" alt="Our Vision"/>
            </div>
          </div>

          <div class="row visionbox">
            <div class="col-12 col-md-6">
               <img src="./Hotel-Images/Our Vision & Mission Images-02.jpg" alt="Our Mission"/>
            </div>
            <div class="col-12 col-md-6 text-center d-flex flex-column justify-content-center align-items-center">
              <h2 className="textgreen fw-bold">Our Mission</h2>
              <p>
                "To be the premier choice for travelers seeking exceptional
                hospitality, where comfort, elegance, and personalized service
                come together to create memorable experiences. We strive to set
                new standards in the hospitality industry by consistently
                delivering outstanding service, fostering innovation, and
                creating a welcoming environment that exceeds the expectations
                of our guests."
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ============================ */}
      {/* <!-- Gallery Area Start -->  */}
      <div className="gallery__area section-padding pb-0 overflow-hidden">
        <div className="container-fluid p-0">
          <div className="row">
            {galleryItems.map((imageSrc, index) => (
              <div key={index} className="col-sm-3 sm-mb-10">
                <div className="gallery__area-item gallerybox">
                  <img
                    className="img__full"
                    src={imageSrc}
                    alt={`Gallery Image ${index + 1}`}
                    onClick={() => {
                      setPhotoIndex(index);
                      setIsOpen(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={galleryItems[photoIndex]}
            nextSrc={galleryItems[(photoIndex + 1) % galleryItems.length]}
            prevSrc={
              galleryItems[
                (photoIndex + galleryItems.length - 1) % galleryItems.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + galleryItems.length - 1) % galleryItems.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % galleryItems.length)
            }
          />
        )}
      </div>

      {/* ###################### Exceptional services sectiion #################### */}

      <div className="section-padding">
        <div className="container">
          <h2 className="pb-3">Exceptional Services</h2>
          <div className="row d-flex justify-content-between flex-wrap">
            <div className="col-12 col-md-4">
              <div className="box1 p-2 text-center">
                <h4>Close Proximity to the Airport</h4>
                <p>
                  Just a short drive from Lohegaon Pune Airport, making your
                  travel hassle-free.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box1 p-2 text-center">
                <h4 class="">Nearby Attractions</h4>
                <p>
                  Explore popular tourist spots and entertainment venues such as
                  Phoenix Market City, Aga Khan Palace, and Osho Ashram.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box1 p-2 text-center">
                <h4 class="">Business Convenience</h4>
                <p>
                  Ideal for business travelers with easy access to corporate
                  offices and business parks.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box1 p-2 text-center">
                <h4 class="">24/7 Front Desk</h4>
                <p>
                  Our friendly staff is available around the clock to assist
                  with any requests or inquiries.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box1 p-2 text-center">
                <h4 class="">Meeting and Event Facilities</h4>
                <p>
                  Our versatile spaces are perfect for hosting business
                  meetings, conferences, and social gatherings.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box1 p-2 text-center">
                <h4 class="wt-tilte ">Fitness Center</h4>
                <p>
                  Maintain your fitness routine with our state-of-the-art gym
                  facilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============================ */}

      <div className="services__two">
        <div className="container">
          <div className="row justify-content-center">
            {services.map((service) => (
              <div
                key={service.id}
                className="col-xl-3 col-lg-4 col-md-6 mb-30"
              >
                <div className="services__two-item">
                  <span>{service.id}</span>
                  <div className="services__two-item-icon">
                    <img src={service.icon} alt={service.title} />
                  </div>
                  <div className="services__two-item-content">
                    <h4>{service.title}</h4>
                    <p>{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <!-- Services Two End -->
	
      {/* ================== Testimonial Section ================== */}
      <div className="testimonial-carousel-container  testimonial-section">
        <h2 className="text-center text-white">Our Client Says...</h2>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-slide">
              <div className="testimonial-content">
                <h3 className="testimonial-title">{testimonial.title}</h3>
                <p className="testimonial-text">{testimonial.text}</p>
                <p className="testimonial-name">— {testimonial.name}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* ============================ */}
      {/* <!-- Gallery Area End -->

       {/* ================ photo gallery ============== */}
       <div className="photo-gallery">
            {images.map((image, index) => (
              <div key={index} className="photo-item">
                <img
                  src={`${image}`}
                  alt={`Gallery item ${index + 1}`}
                  className="photo-img"
                />
              </div>
            ))}
          </div>

          {/* =================== */}
    </>
  );
}
