import React from "react";
import "./CSS/all.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/magnific-popup.css";
import "./CSS/meanmenu.min.css";
import "./CSS/nice-select.css";
import "./CSS/swiper-bundle.min.css";
import "./sass/style.css";
import "./App.css";
import { Link } from "react-router-dom";

export default function Footer() {

  return (
    <>
      <div class="footer__area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3 col-sm-12 lg-mb-30">
              <div class="footer__area-widget">
                <h5>Services</h5>
                <div class="footer__area-widget-menu">
                <ul className="list-unstyled">
      <li>
        <Link to="/hotel-in-viman-nagar">Hotel in Viman Nagar</Link>
      </li>
      <li>
        <Link to="/viman-nagar-hotels-for-stay">Hotels in Viman Nagar for Stay</Link>
      </li>
      <li>
        <Link to="/hotels-near-viman-nagar">Hotels Near Viman Nagar</Link>
      </li>
      <li>
        <Link to="/lodge-in-viman-nagar-pune">Lodge in Viman Nagar Pune</Link>
      </li>
      <li>
        <Link to="/hotels-near-viman-nagar-airport-pune">Hotels Near Viman Nagar Airport Pune</Link>
      </li>
      <li>
        <Link to="/pune-airport-hotel">Pune Airport Hotel</Link>
      </li>
      <li>
        <Link to="/hotels-near-pune-international-airport">Hotels Near Pune International Airport</Link>
      </li>
      <li>
        <Link to="/pune-near-airport-hotel">Pune Near Airport Hotel</Link>
      </li>
      <li>
        <Link to="/pune-airport-hotels">Pune Airport Hotels</Link>
      </li>
      <li>
        <Link to="/hotel-wadgaon-sheri">Hotel in Wadgaon Sheri</Link>
      </li>
      <li>
        <Link to="/online-hotel-booking-pune">Online Hotel Booking in Pune</Link>
      </li>
    </ul>
                </div>
              </div>
            </div>
            <div class=" col-md-3 col-sm-12 sm-mb-30">
              <div class="footer__area-widget">
                <h5>Services</h5>
                <div class="footer__area-widget-menu">
                   <ul>
      <li>
        <Link to="/hotel-for-stay-lohegaon-airport">
          Hotel For Stay in Lohegaon Airport
        </Link>
      </li>
      <li>
        <Link to="/hotel-near-me-pune-airport">
          Hotel Near Me Pune Airport
        </Link>
      </li>
      <li>
        <Link to="/hotels-near-kharadi">Hotels Near Kharadi</Link>
      </li>
      <li>
        <Link to="/hotel-in-koregaon-park">Hotel in Koregaon Park</Link>
      </li>
      <li>
        <Link to="/best-hotels-in-pune-for-couples">
          Best Hotels in Pune for Couples
        </Link>
      </li>
      <li>
        <Link to="/hotel-in-kalyni-nagar">Hotels in Kalyani Nagar</Link>
      </li>
      <li>
        <Link to="/luxury-hotel-pune-nagar-road">
          Luxury Hotel on Pune Nagar Road
        </Link>
      </li>
      <li>
        <Link to="/hotels-in-kharadi">Hotel in Kharadi</Link>
      </li>
      <li>
        <Link to="/business-stays-in-viman-nagar">
          Hotel for Business Stays in Viman Nagar
        </Link>
      </li>
      <li>
        <Link to="/corporate-hotel-booking-in-viman-nagar">
          Hotel Booking for Corporate in Viman Nagar
        </Link>
      </li>
    </ul>
                </div>
              </div>
            </div>

           
            <div class="col-md-3 col-sm-12  text-center">
              <div class="footer__area-widget">
                <h5>Contact Us</h5>
                <div class="footer__area-widget-menu myfoot">
                  <ul>
                    <div className="mybox">
                      <a href="tel:+91 7058638153">
                        <i class="bi bi-telephone-fill me-2 fs-2 d-block"></i>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </a>
                    </div>
                    <div className="mybox text-center p-2">
                      <a href="  mailto:reservation@grandlegacyinnhotels.com">
                        <i class="bi bi-envelope me-2 fs-2 d-block"></i>
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                            reservation@grandlegacyinnhotels.com
                        </a>
                      </a>
                    </div>

                    <div className="mybox p-2 ">
                      <a href="./">
                        <i class="bi bi-house-fill fs-1"></i>
                        <p>
                        Grand Legacy Inn hotels <br/>
                        Pune Nagar Road, Vimanagar Chowk 
                        Opp <br/> Phoenix Market city, 
                        Pune - 411014
                        </p>
                      </a>
                    </div>

                    <div className="mapbox">
                      <div style={{ width: "100%", height: "350px" }}>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d945.5653221956909!2d73.91605056947952!3d18.56225527024742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c147b8b3a3bf%3A0x6f7fdcc8e4d6c77e!2sPhoenix%20Marketcity%20Pune!5e0!3m2!1sen!2sin!4v1728879702986!5m2!1sen!2sin"
                          width="600"
                          height="350"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          

            <div class="col-md-3 col-sm-12 lg-mb-30">
              <div class="footer__area-widget text-white footer-logo myfoot">
               <img src="img/The grand legacy logo sizes-08.jpg"  style={{width:"200px", height:"100px"}} alt="logo"/>
                <p>
                  Experience unparalleled comfort and convenience at Grand Legacy Inn Hotels
                </p>
                <div className="">
                  <h4 className="pb-2">Near By Locations</h4>
                  <ul className="list-unstyled">
                    <li>
                      <a href="./" className="d-flex justfy-content-between">
                        <i class="bi bi-geo-alt-fill me-2"></i>
                        <p>EON IT Park Kharadi - 4 Km -10 Min</p>
                      </a>
                    </li>
                    <li>
                      <a href="./" className="d-flex justfy-content-between">
                        <i class="bi bi-geo-alt-fill me-2"></i>
                        <p>Phoenix Mall Viman Nagar - 0.100 Meter - 1 Min</p>
                      </a>
                    </li>
                    <li>
                      <a href="./" className="d-flex justfy-content-between">
                        <i class="bi bi-geo-alt-fill me-2"></i>
                        <p>Pune Railway Station - 6.00 Km - 15 Min</p>
                      </a>
                    </li>
                    <li>
                      <a href="./" className="d-flex justfy-content-between">
                        <i class="bi bi-geo-alt-fill me-2"></i>
                        <p>Pune International Airport- 3km - 7 Min</p>
                      </a>
                    </li>
                    <li>
                      <a href="./" className="d-flex justfy-content-between">
                        <i class="bi bi-geo-alt-fill me-2"></i>
                        <p>Magarpatta City - 8 Km - 17 Min</p>
                      </a>
                    </li>
                    <li>
                      <a href="./" className="d-flex justfy-content-between">
                        <i class="bi bi-geo-alt-fill me-2"></i>
                        <p>Seasons Mall - 7 Km - 15 min</p>
                      </a>
                    </li>
                    <li>
                      <a href="./" className="d-flex justfy-content-between">
                        <i class="bi bi-geo-alt-fill me-2"></i>
                        <p>Viman Nagar Metro Station - 1 Km - 2 Min</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>



        {/* <!-- =============== whatsapp and phone button =============== --> */}
        <div class="phone-float">
                <a href="tel:+917058638153">
                <i class="bi bi-telephone-fill"></i><span>Call Us
                        <br/><small>+91 7058638153</small></span>
                </a>
            </div>

            <div class="whats-float">
                <a href="https://api.whatsapp.com/send/?phone=917058638153&text=I would like to inquire about booking  room at your Grand Legacy Inn Hotels Request you Please Send More Details or Call Me Thank you!! ." 
                    target="_blank">
                    <span>WhatsApp<br/><small style={{ textAlign: 'right' }}>+91 7058638153</small></span><i
                        class="bi bi-whatsapp"></i>
                </a>
              
            </div>
        {/* ========================= */}
        <div class="copyright__area">
          <div class="container">
            <div class="row align-items-center">
              <div className="text-white">
                All rights reserved Grand Legacy inn hotels  2024 Developed By
                <Link to="https://dexterintelligence.com/" target="_blank"> <span className="text fw-bold">Dexter Intelligence</span> </Link> |  <Link to='https://www.urvahosting.com/' target="_blank">Hosted By <span className="text fw-bold">Urva Hosting</span></Link> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
