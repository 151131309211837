import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function LodgeInVimanNagar() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const faqs = [
    {
      question:
        "What makes Grand Legacy inn hotels  a preferred lodge in Viman Nagar, Pune?",
      answer:
        "Grand Legacy inn hotels  is a top choice for those seeking a lodge in Viman Nagar, Pune due to its prime location, luxurious accommodations, and exceptional service. We offer well-appointed rooms with modern amenities, a variety of dining options, and personalized guest services. Our central location provides easy access to local attractions, business hubs, and shopping areas, making it ideal for both leisure and business travelers.",
    },
    {
      question:
        "Are there family-friendly accommodations available at Grand Legacy inn hotels  ?",
      answer:
        "Yes, Grand Legacy inn hotels  offers family-friendly accommodations designed to cater to the needs of guests traveling with children. Our spacious rooms and suites provide ample space for families, and we offer amenities such as cribs, extra beds, and kid-friendly dining options. Our staff is committed to ensuring a comfortable and enjoyable stay for guests of all ages.",
    },
    {
      question:
        "Does Grand Legacy inn hotels  offer special services or packages for couples?",
      answer:
        "Absolutely! Grand Legacy inn hotels  is known for being a couple-friendly lodge. We offer special services and packages tailored to enhance the romantic experience, including room decorations, special dining arrangements, and personalized amenities. Our serene atmosphere and elegant rooms make it an ideal choice for a romantic getaway or special occasion.",
    },
    {
      question:
        "Can I book a room at Grand Legacy inn hotels  on an hourly basis?",
      answer:
        "Yes, Grand Legacy inn hotels  provides hourly basis bookings for guests who need flexible accommodation options. This service is ideal for travelers requiring a short-term stay or a private space for a few hours. Please contact our reservations team to check availability and rates for hourly stays.",
    },
    {
      question: "What dining options are available at Grand Legacy inn hotels  ?",
      answer:
        "Grand Legacy inn hotels  features an on-site restaurant that offers a diverse menu with both local and international cuisine. Guests can enjoy a variety of meals including breakfast, lunch, and dinner. Additionally, we offer room service for those who prefer to dine in the comfort of their room. Our restaurant is designed to provide a pleasant dining experience for all guests.",
    },
    {
      question: "Is Grand Legacy inn hotels  a 24x7 hotel?",
      answer:
        "Yes, Grand Legacy inn hotels  operates as a 24x7 hotel, ensuring that we are available to meet your needs at any time of day or night. Our round-the-clock services include front desk assistance, dining options, and support for any guest requests or requirements.",
    },
    {
      question:
        "How close is Grand Legacy inn hotels  to major attractions and business centers in Pune?",
      answer:
        "Grand Legacy inn hotels  is conveniently located in Viman Nagar, providing easy access to major attractions and business centers in Pune. Our proximity to key locations ensures that guests can efficiently travel to business meetings, shopping areas, and local attractions, making it an ideal choice for both business and leisure travelers.",
    },
    {
      question: "What types of rooms are available at Grand Legacy inn hotels  ?",
      answer:
        "Grand Legacy inn hotels  offers a range of room types to suit various preferences and needs, including standard rooms, deluxe rooms, and suites. Each room is designed with modern amenities such as high-speed Wi-Fi, flat-screen TVs, and comfortable bedding to ensure a pleasant and relaxing stay.",
    },
    {
      question: "Does Grand Legacy inn hotels  provide parking facilities?",
      answer:
        "Yes, Grand Legacy inn hotels  offers complimentary parking for all guests. Our secure parking area ensures that your vehicle is safely accommodated while you enjoy your stay with us.",
    },
    {
      question: "Are pets allowed at Grand Legacy inn hotels  ?",
      answer:
        "At this time, Grand Legacy inn hotels  does not allow pets on the property. We strive to maintain a comfortable environment for all guests and ask that you make alternative arrangements for your pets during your stay.",
    },
  ];

  const testimonials = [
    {
      quote:
        "Our stay at Grand Legacy inn hotels  was absolutely wonderful. We were looking for a comfortable and family-friendly lodge in Viman Nagar, Pune, and this place exceeded our expectations. The rooms were spacious and well-maintained, providing plenty of room for our family to relax. The staff was incredibly accommodating, and the on-site restaurant offered a variety of delicious meals that everyone enjoyed. The location was perfect—close to shopping areas and attractions. We couldn't have asked for a better place to stay during our visit to Pune. Highly recommend it for families!",
      author: "Mr & Mrs. Ravi and Priya Deshmukh",
    },
    {
      quote:
        "Grand Legacy inn hotels  made our romantic getaway in Viman Nagar truly special. We were looking for a couple-friendly lodge and found everything we wanted at this lovely hotel. The room was beautifully decorated and provided a serene, intimate atmosphere perfect for our escape. The hotel's central location allowed us to easily explore the local area, and the restaurant served fantastic food. The staff was attentive and made sure every detail of our stay was perfect. If you're looking for a romantic retreat in Pune, this lodge is a fantastic choice!",
      author: "Mr & Mrs. Ankit and Neha Sharma",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }
  ];


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "http://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact +91 7058638153 | Grand Legacy inn hotels | Lodge in Viman Nagar
          Pune: Luxury, Cheap, Couple Friendly & Family Lodges
        </title>
        <meta
          name="description"
          content="Discover Grand Legacy inn hotels  for the best lodges in Viman Nagar Pune. Explore luxury lodges for families, affordable options for couples, and 24x7 hotels. Find top lodges on Pune Nagar Road and Chandannagar. Contact us at +91 7058638153 for more details."
        />
        <meta
          name="keywords"
          content="lodge in Viman Nagar Pune for family, luxury lodge in Viman Nagar Pune for family, best luxury lodge in Viman Nagar Pune for family, cheap lodge in Viman Nagar for couple, couple friendly hotels in Viman Nagar, 24x7 hotel in Viman Nagar Pune, hotels in Pune Nagar Road, hotels on Pune Nagar highway, lodge on Pune Nagar Road, lodge in Chandannagar"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Grand Legacy inn hotels  - Contact +91 7058638153 | Lodge in Viman Nagar Pune: Luxury, Cheap, Couple Friendly & Family Lodges"
        />
        <meta
          property="og:description"
          content="Find the best lodges in Viman Nagar Pune with Grand Legacy inn hotels . Offering luxury lodges for families, affordable options for couples, and 24x7 hotels. Located on Pune Nagar Road and Chandannagar. Contact +91 7058638153 for bookings and information."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/Lodge in Viman nagar pune.jpg"
        />
        <meta
          property="og:url"
          content="https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Lodge in Viman Nagar Pune</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Lodge in Viman Nagar Pune
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Lodge in Viman nagar pune.jpg" className="img-fluid" alt="Lodge in Viman nagar pune" />
              </div>
              <h3>
                Experience Unmatched Comfort at Grand Legacy inn hotels  : Your
                Top Lodge in Viman Nagar, Pune
              </h3>
              <p>
                If you’re searching for the perfect lodge in Viman Nagar, Pune,
                look no further than Grand Legacy inn hotels  . Our hotel combines
                convenience, comfort, and exceptional service to provide an
                unforgettable stay in one of Pune’s most vibrant neighborhoods.
                Here’s why Grand Legacy inn hotels  stands out as the premier
                choice for your stay in Viman Nagar.
              </p>

              <h3>Prime Location in Viman Nagar</h3>
              <p>
                Grand Legacy inn hotels  is strategically located in the heart of
                Viman Nagar, offering easy access to the area’s key attractions,
                business centers, and dining options. Whether you’re here for
                work or leisure, our central location makes it convenient to
                explore the lively neighborhood while providing a serene retreat
                from the hustle and bustle.
              </p>

              <h3>Elegant and Comfortable Accommodations</h3>
              <p>
                When it comes to finding a lodge in Viman Nagar, comfort is
                paramount. At Grand Legacy inn hotels  , we offer a range of
                well-appointed rooms designed to ensure a restful and relaxing
                stay. Our accommodations feature:
              </p>
              <ul>
                <li>Spacious Rooms: Enjoy ample space to unwind and relax.</li>
                <li>
                  Modern Amenities: Including high-speed Wi-Fi, flat-screen TVs,
                  and premium bedding.
                </li>
                <li>
                  Thoughtful Details: Elegant decor and cozy furnishings create
                  a welcoming atmosphere.
                </li>
              </ul>

              <h3>Exceptional Dining Experience</h3>
              <p>
                Dining is a key component of any great stay, and at
                Grand Legacy Inn Hotels, we offer an outstanding culinary experience. Our
                on-site restaurant serves a diverse menu of local and
                international cuisines, ensuring that every meal is a delight.
                Whether you’re starting your day with a hearty breakfast,
                enjoying a leisurely lunch, or savoring a gourmet dinner, our
                restaurant provides a top-notch dining experience. For added
                convenience, room service is also available.
              </p>

              <h3>Ideal for Business and Leisure Travelers</h3>
              <p>
                Grand Legacy inn hotels  caters to both business and leisure
                travelers. Our business facilities include a fully equipped
                business center and versatile meeting rooms, making it easy to
                conduct work efficiently. For those visiting for leisure, our
                proximity to Viman Nagar’s attractions and shopping areas
                ensures you can enjoy all that the neighborhood has to offer.
              </p>

              <h3>Couple-Friendly Atmosphere</h3>
              <p>
                Looking for a couple-friendly lodge in Viman Nagar?
                Grand Legacy Inn Hotels provides a romantic and intimate setting perfect
                for couples. Our thoughtfully designed rooms and serene ambiance
                create an ideal environment for a romantic getaway or special
                celebration. We also offer special packages and services to
                enhance your experience.
              </p>

              <h3>Flexible Booking Options</h3>
              <p>
                For those in need of flexible accommodation, Grand
                Legacy Inn Hotels offers hourly basis bookings. This option is ideal for
                travelers who require a short-term stay or a private space for a
                few hours. Contact our reservations team to inquire about
                availability and rates for hourly stays.
              </p>

              <h3>Family-Friendly Environment</h3>
              <p>
                Traveling with family? Grand Legacy inn hotels  is a great choice
                for families as well. Our spacious rooms and family-friendly
                amenities cater to guests of all ages. With our convenient
                location, families can easily explore local attractions and
                enjoy nearby dining options.
              </p>

              <h3>Affordable Luxury</h3>
              <p>
                As a premier lodge in Viman Nagar, Grand Legacy inn hotels  offers
                a perfect blend of luxury and affordability. Our competitive
                rates ensure you get the best value for your stay while enjoying
                high-quality accommodations and exceptional service. Experience
                the comfort and elegance of a top-tier lodge without the premium
                cost.
              </p>

              <h3>Outstanding Service and Hospitality</h3>
              <p>
                At Grand Legacy inn hotels  , we pride ourselves on delivering
                outstanding service and hospitality. Our dedicated staff is
                committed to ensuring that every aspect of your stay is
                enjoyable and stress-free. From the moment you arrive, you’ll
                experience personalized service that meets your needs and
                exceeds your expectations.
              </p>

              <h3>Lodge in Viman Nagar Pune for Family</h3>
              <p>
                When searching for the perfect lodge in Viman Nagar, Pune for
                family, Grand Legacy inn hotels  stands out as a top choice. Our
                family-friendly accommodations are designed with comfort and
                convenience in mind. Enjoy spacious rooms, modern amenities, and
                a range of activities suitable for all ages. Located in the
                heart of Viman Nagar, our lodge provides easy access to local
                attractions and dining options, making it an ideal base for
                family explorations.
              </p>

              <h3>Luxury Lodge in Viman Nagar Pune for Family</h3>
              <p>
                For those seeking a luxury lodge in Viman Nagar, Pune for
                family, Grand Legacy inn hotels  offers an unparalleled
                experience. Our luxurious rooms and suites are equipped with
                high-end amenities, ensuring a comfortable and indulgent stay.
                Families can enjoy a variety of services, including on-site
                dining and recreational facilities, all within a sophisticated
                and elegant setting. Our prime location allows easy access to
                both leisure and business destinations in Pune.
              </p>

              <h3>Best Luxury Lodge in Viman Nagar Pune for Family</h3>
              <p>
                At Grand Legacy inn hotels  , we pride ourselves on being the best
                luxury lodge in Viman Nagar, Pune for family. Our hotel features
                top-notch accommodations with stylish decor and modern comforts.
                From spacious family suites to personalized services, we ensure
                that every aspect of your stay meets the highest standards of
                luxury. Explore the vibrant neighborhood and return to the
                comfort of our elegantly designed lodge.
              </p>

              <h3>Cheap Lodge in Viman Nagar for Couple</h3>
              <p>
                If you're looking for a cheap lodge in Viman Nagar for a couple,
                Grand Legacy inn hotels  provides affordable yet comfortable
                accommodations without compromising on quality. Our
                competitively priced rooms are designed to offer a pleasant and
                enjoyable stay for couples. Benefit from our central location,
                which provides easy access to local attractions and romantic
                dining options, all while staying within budget.
              </p>

              <h3>Couple-Friendly Hotels in Viman Nagar</h3>
              <p>
                Grand Legacy inn hotels  is a standout choice among
                couple-friendly hotels in Viman Nagar. Our hotel offers a
                romantic and intimate atmosphere perfect for couples seeking a
                special getaway. Enjoy elegantly designed rooms, a serene
                environment, and attentive service that ensures a memorable
                stay. We also offer special packages and amenities tailored to
                enhance your romantic experience.
              </p>

              <h3>24x7 Hotel in Viman Nagar Pune</h3>
              <p>
                For those needing a 24x7 hotel in Viman Nagar, Pune,
                Grand Legacy Inn Hotels is your go-to choice. Our round-the-clock service
                ensures that all your needs are met at any hour of the day or
                night. Whether you have a late check-in, require late-night
                dining, or need assistance at any time, our dedicated staff is
                available to provide exceptional service and support around the
                clock.
              </p>

              <h3>Hotels in Pune Nagar Road</h3>
              <p>
                Grand Legacy inn hotels  is conveniently located among hotels in
                Pune Nagar Road, offering easy access to the major highway and
                nearby attractions. Our location is ideal for travelers who need
                to stay connected to key destinations while enjoying a
                comfortable and relaxing environment. Experience the best of
                both worlds with our excellent amenities and strategic
                positioning.
              </p>

              <h3>Hotels on Pune Nagar Highway</h3>
              <p>
                If you're looking for hotels on Pune Nagar Highway,
                Grand Legacy Inn Hotels provides a prime location that combines convenience
                with comfort. Situated near the major highway, our hotel offers
                easy access to various parts of Pune, making it an excellent
                choice for both short and extended stays. Enjoy modern
                accommodations and top-notch services while being well-connected
                to the city.
              </p>

              <h3>Lodge on Pune Nagar Road</h3>
              <p>
                Grand Legacy inn hotels  offers a premium lodge on Pune Nagar
                Road, ideal for travelers seeking a blend of comfort and
                accessibility. Our lodge provides a relaxing retreat with modern
                amenities, all while being conveniently located on Pune Nagar
                Road. Whether you're here for business or leisure, our lodge
                offers a perfect base to explore the city and its surroundings.
              </p>

              <h3>Lodge in Chandannagar</h3>
              <p>
                While Grand Legacy inn hotels  is situated in Viman Nagar, for
                those seeking a lodge in Chandannagar, we offer a comparable
                level of comfort and service in a different location. Our
                accommodations in Chandannagar are designed to meet high
                standards, providing a cozy and enjoyable stay for all guests.
                For information on our Chandannagar properties, please contact
                us directly.
              </p>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Lodge in viman nagar pune for family </p>
                      <p>- Luxury lodge in viman nagar pune for family</p>
                      <p>- Cheap lodge in viman nagar for couple</p>
                      <p>- 24x7 hotel in Viman Nagar Pune</p>
                      <p>- hotels on pune nagar highway</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Best luxury lodge in viman nagar pune for family </p>
                      <p>- Couple Friendly Hotels in Viman Nagar </p>
                      <p>- Hotels in Pune Nagar Road</p>
                      <p>- lodge in chandannagar</p>
                      <p>- Lodge on Pune Nagar Road </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels  for a Lodge in Viman Nagar,
                Pune?
              </h2>
              <p>
                When looking for the ideal lodge in Viman Nagar, Pune,
                Grand Legacy Inn Hotels offers an unmatched combination of comfort,
                convenience, and exceptional service. Here’s why Grand
                Legacy Inn Hotels should be your top choice:
              </p>

              <ul>
                <li>
                  <h3>Prime Location in Viman Nagar</h3>
                  <p>
                    Grand Legacy inn hotels  is strategically situated in the
                    vibrant and bustling area of Viman Nagar. This prime
                    location offers easy access to key business districts,
                    shopping areas, and dining options. Whether you are in Pune
                    for business or leisure, our lodge provides the perfect base
                    to explore the city while being close to local attractions
                    and major transport links.
                  </p>
                </li>

                <li>
                  <h3>Elegant and Comfortable Accommodations</h3>
                  <p>
                    At Grand Legacy inn hotels  , we prioritize your comfort with
                    our elegant and well-appointed rooms. Each room is designed
                    to provide a relaxing retreat, featuring:
                  </p>
                  <ul>
                    <li>
                      Spacious Layouts: Enjoy ample space to unwind after a busy
                      day.
                    </li>
                    <li>
                      Modern Amenities: Including high-speed Wi-Fi, flat-screen
                      TVs, and comfortable bedding.
                    </li>
                    <li>
                      Sophisticated Decor: Elegant furnishings and a calming
                      atmosphere ensure a pleasant stay.
                    </li>
                  </ul>
                </li>

                <li>
                  <h3>Exceptional Dining Experience</h3>
                  <p>
                    Our on-site restaurant offers a diverse menu with both local
                    and international cuisines, catering to all tastes. Start
                    your day with a hearty breakfast, enjoy a leisurely lunch,
                    or savor a gourmet dinner without having to leave the hotel.
                    For added convenience, room service is also available for
                    those who prefer to dine in the comfort of their room.
                  </p>
                </li>

                <li>
                  <h3>Ideal for Families</h3>
                  <p>
                    Grand Legacy inn hotels  is an excellent choice for families
                    seeking a lodge in Viman Nagar. Our family-friendly
                    accommodations include spacious rooms and amenities designed
                    to make your stay enjoyable and stress-free. We offer cribs,
                    extra beds, and kid-friendly dining options to cater to the
                    needs of guests traveling with children.
                  </p>
                </li>

                <li>
                  <h3>Couple-Friendly Atmosphere</h3>
                  <p>
                    Our lodge is renowned for being couple-friendly, providing a
                    romantic and intimate setting perfect for a getaway. Enjoy
                    elegant rooms, serene surroundings, and special packages
                    designed to enhance your romantic experience. Whether it’s a
                    special occasion or a simple escape, Grand Legacy inn hotels 
                    creates the perfect environment for couples.
                  </p>
                </li>

                <li>
                  <h3>Flexible Booking Options</h3>
                  <p>
                    For those needing flexibility, we offer hourly basis
                    bookings, ideal for guests who require short-term stays or a
                    private space for just a few hours. This flexible option
                    ensures you can enjoy the comforts of our lodge even if your
                    stay is brief.
                  </p>
                </li>

                <li>
                  <h3>24x7 Service</h3>
                  <p>
                    Grand Legacy inn hotels  operates as a 24x7 hotel, providing
                    round-the-clock services to meet your needs at any hour. Our
                    dedicated staff is available to assist with check-ins,
                    late-night dining, and any other requirements you may have,
                    ensuring a seamless and comfortable stay.
                  </p>
                </li>

                <li>
                  <h3>Affordable Luxury</h3>
                  <p>
                    We offer a balance of luxury and affordability, making us
                    one of the best lodge options in Viman Nagar. Enjoy
                    high-quality accommodations and exceptional service at
                    competitive rates, ensuring that you get the best value for
                    your stay.
                  </p>
                </li>

                <li>
                  <h3>Outstanding Service and Hospitality</h3>
                  <p>
                    Our commitment to outstanding service and hospitality sets
                    us apart. From the moment you arrive, our friendly and
                    professional staff is dedicated to making your stay
                    enjoyable. We focus on providing personalized service to
                    ensure that all your needs are met and your expectations
                    exceeded.
                  </p>
                </li>

                <li>
                  <h3>Convenient Access to Pune Nagar Road</h3>
                  <p>
                    Grand Legacy inn hotels  ’s location provides convenient
                    access to Pune Nagar Road, making it easy for guests to
                    travel to various parts of Pune. Whether you’re heading to
                    business meetings, shopping centers, or other attractions,
                    our strategic position ensures that you are well-connected
                    to major routes.
                  </p>
                </li>
              </ul>

              <div className="">
                <h2>
                  FAQs for Hotel in Viman Nagar, Pune - Grand Legacy inn hotels 
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

              {/* ======================== */}
              <div className="">
                <h2>
                  Testimonials for Hotel in Viman Nagar Pune -  Grand
                  Legacy Inn Hotels: -{" "}
                </h2>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-slide">
                      <blockquote>
                        <p>{testimonial.quote}</p>
                        <footer>— {testimonial.author}</footer>
                      </blockquote>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* ================= */}

              <div className=" contact-section">
                <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                <p>
                  For booking inquiries or any assistance, please feel free to
                  contact us:
                </p>
                <p>
                  <strong>Mobile No:</strong>{" "}
                  <a href="tel:+917058638153">+91 7058638153</a>
                </p>
                <p>
                  <strong>Email Id:</strong>{" "}
                  <a href="  mailto:reservation@grandlegacyinnhotels.com">
                   reservation@grandlegacyinnhotels.com
                  </a>
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
          
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
