import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
export default function Facilities(){
    const services = [
		{
		  id: '01',
		  icon: <i class="fa fa-wifi"></i>,
		  title: 'Wifi Coverage',
		  description: 'Stay connected with high-speed Wi-Fi internet access available in all areas of our hotel. Whether youre catching up on work, browsing the web, or staying in touch with loved ones, our reliable Wi-Fi ensures seamless connectivity.',
		},
        {
            id: '02',
            icon:  <i class="fas fa-parking"></i>, 
            title: 'Parking',
            description: 'Convenient parking facilities are available at Grand Legacy inn hotels . Whether youre traveling by car or renting one during your stay, our ample parking space provides ease and accessibility.',
          },
		{
		  id: '03',
		  icon: <i class="fa fa-tv"></i>,
		  title: 'Smart TV',
		  description: 'Enjoy a variety of entertainment options with our flat-screen Smart TVs in every room. Access your favorite shows, movies, and streaming services for a relaxing and enjoyable stay.',
		},
		{
		  id: '04',
		  icon: <i class="fas fa-camera"></i>,
		  title: 'CCTV Camera',
		  description: 'Your safety is our priority. We maintain 24/7 surveillance with CCTV cameras throughout the premises to ensure a secure environment for all our guests.',
		},
     
          {
            id: '05',
            icon:<i class="fa fa-bath"></i>,
            title: 'Bath Kit',
            description: 'Experience comfort and convenience with our complimentary bath kits provided in each room. Includes essential toiletries and amenities for a refreshing bathing experience.',
          },
          {
            id: '06',
            icon: <i class="fa fa-thermometer-half"></i>,
            title: 'Hot Water Geyser',
            description: 'Enjoy hot showers anytime with hot water geysers available in every bathroom, ensuring your comfort and relaxation throughout your stay.',
          },
          {
            id: '07',
            icon: <i class="fa fa-tint"></i>,
            title: 'Mineral Bottle',
            description: 'Stay refreshed with complimentary mineral water bottles provided in your room. Enjoy the convenience of having fresh drinking water readily available.',
          },
          {
            id: '08',
            icon: <i class="fas fa-crown"></i>,
            title: 'AC Luxury Room',
            description: 'Experience a cool and comfortable stay in our air-conditioned luxury rooms. Each room is equipped with modern climate control to ensure optimal comfort regardless of the weather outside.',
          },
          {
            id: '09',
            icon: <i class="fas fa-fan"></i>,
            title: 'Fan',
            description: 'For added comfort, our rooms are also equipped with ceiling fans. Enjoy a gentle breeze and maintain your preferred temperature during your stay.',
          },
          {
            id: '10',
            icon: <i class="fas fa-shield-alt"></i>,
            title: '24x7 Security',
            description: 'Rest assured with round-the-clock security personnel at Grand Legacy inn hotels . Our dedicated team ensures the safety and well-being of all guests with vigilant monitoring and response.',
          },
          {
            id: '11',
            icon: <i class="fa fa-credit-card"></i>,
            title: 'Online Payment Acceptance',
            description: 'We offer secure and hassle-free online payment options for your convenience. Make reservations and settle bills with ease using our online payment acceptance services.',
          },
          {
            id: '12',
            icon: <i class="fa fa fa-wheelchair"></i>,
            title: 'Facilities for Disabled Guests',
            description: 'We are committed to providing accessible accommodations for all guests. Our facilities include features designed to ensure comfort and convenience for guests with disabilities.',
          },
	  ];

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Hotel",
      "name": "Grand Legacy inn hotels ",
      "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
        "addressLocality": "Pune,Viman Nagar",
        "addressRegion": "MH",
        "postalCode": "411014",
        "addressCountry": "IN"
      },
      "telephone": "+91 7058638153",
      "image": "http://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
      "starRating": {
        "@type": "Rating",
        "bestRating": "5",
        "ratingValue": "3",
        "worstRating": "1"
      },
     
      "sameAs": [
        "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune"
      ]
    };
  
  return(
      <>
       <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
       <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
   
        <title>Contact:+91 7058638153 | Grand Legacy inn hotels  |Facilities | Viman Nagar Hotel Booking and Stay Options| Hotel Near Me Pune Airport
        | Hotels Near Kharadi | Hotel in Koregaon Park | Best Hotels in Pune for Couples</title>
        <meta name="description" content="Book the best hotels in Viman Nagar, Pune for your stay. Find couple-friendly hotels, business hotels, 3-star and 4-star hotels near Pune International Airport. Call +91 7058638153 for bookings." />
        <meta name="keywords" content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, Business hotels in Viman Nagar, Best hotels in Viman Nagar Pune, Couple-friendly hotels in Viman Nagar, 3-star hotels in Viman Nagar, Hotels near Pune International Airport, 3-star hotels near Pune airport, Hotels near Pune domestic airport, 4-star hotels near Pune airport, Best hourly hotels near Pune International Airport" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="+91 7058638153 | Grand Legacy inn hotels  |Facilities | Viman Nagar Hotel Booking | Hotel Near Me Pune Airport
         Hotels Near Kharadi" />
        <meta property="og:description" content="Book your stay at Grand Legacy inn hotels , one of the best hotels in Viman Nagar. We offer couple-friendly and business hotel rooms, as well as 3-star and 4-star hotel options near Pune Airport. Call +91 7058638153." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg"/>
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/facilities" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
           {/* <!-- Page Banner Start --> */}
    <div class="page__banner" data-background="./img/banner/page-banner-5.jpg">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="page__banner-title">
                        <h1>Facilities</h1>
                        <div class="page__banner-title-menu">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>-</span>Facilities</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   {/* <!-- Page Banner End --> */}

    {/* <!-- Services Two Start --> */ }
	 <div className="services__two section-padding">
      <div className="container">
        <div className="row justify-content-center">
          {services.map(service => (
            <div key={service.id} className="col-xl-3 col-lg-4 col-md-6 mb-30">
              <div className="services__two-item">
          
                <div className="services__two-item-icon text-center">
                 
                  {service.icon}
                </div>
                <div className="services__two-item-content">
                  <h4>{service.title}</h4>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
	 {/* <!-- Services Two End --> */}
        </>
    )
}