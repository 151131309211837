import { useState } from "react";
import "./App.css";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import { Link } from "react-router-dom";

export default function HotelNearKharadi() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
    const faqs = [
      {
        question: "What is the location of Grand Legacy inn hotels ?",
        answer: "Grand Legacy inn hotels  is conveniently located in Kharadi, Pune. It is situated close to major business hubs such as EON IT Park and Zensar IT Park, making it an ideal choice for both business and leisure travelers."
      },
      {
        question: "What types of rooms are available at Grand Legacy inn hotels ?",
        answer: (
          <ul>
            <li><strong>Deluxe Rooms:</strong> Spacious and elegantly furnished with modern amenities.</li>
            <li><strong>Executive Rooms:</strong> Designed for business travelers, featuring additional workspace and enhanced facilities.</li>
            <li><strong>Suites:</strong> Luxurious suites with separate living areas and premium amenities for a more comfortable stay.</li>
          </ul>
        )
      },
      {
        question: "What amenities are provided at Grand Legacy inn hotels ?",
        answer: (
          <ul>
            <li><strong>High-speed Wi-Fi:</strong> Complimentary internet access throughout the hotel.</li>
            <li><strong>Restaurant:</strong> An in-house dining option offering a variety of local and international cuisines.</li>
            <li><strong>Fitness Center:</strong> A well-equipped gym to help you stay fit during your stay.</li>
            <li><strong>Business Facilities:</strong> Meeting rooms and conference facilities for business needs.</li>
            <li><strong>Spa Services:</strong> Relax and rejuvenate with on-site spa treatments.</li>
          </ul>
        )
      },
      {
        question: "Is Grand Legacy inn hotels  close to public transportation?",
        answer: "Yes, Grand Legacy inn hotels  is well-connected to public transportation options. It is easily accessible by taxis, auto-rickshaws, and buses. The hotel’s location also provides easy access to major roads and highways."
      },
      {
        question: "Are there any dining options at Grand Legacy inn hotels ?",
        answer: "Grand Legacy inn hotels  features an in-house restaurant that serves a diverse menu, including local Indian dishes and international cuisine. Guests can enjoy a comfortable dining experience without needing to leave the hotel."
      },
      {
        question: "Does Grand Legacy inn hotels  offer airport transportation?",
        answer: "Yes, Grand Legacy inn hotels  offers airport transfer services. You can arrange for pick-up and drop-off at Pune Airport by contacting the hotel’s front desk or through their booking platform."
      },
      {
        question: "Can I book meeting rooms at Grand Legacy inn hotels ?",
        answer: "Absolutely. Grand Legacy inn hotels  provides meeting and conference facilities for business travelers. The hotel offers various sizes of meeting rooms equipped with necessary audiovisual equipment and high-speed internet."
      },
      {
        question: "What are the check-in and check-out times at Grand Legacy inn hotels ?",
        answer: "The standard check-in time at Grand Legacy inn hotels  is 2:00 PM, and check-out time is 12:00 PM. If you require different timings, please contact the hotel in advance to check for availability and any additional charges."
      },
      {
        question: "Is parking available at Grand Legacy inn hotels ?",
        answer: "Yes, Grand Legacy inn hotels  offers complimentary parking for guests. There is ample parking space available on the hotel premises."
      },
      {
        question: "Are pets allowed at Grand Legacy inn hotels ?",
        answer: "Grand Legacy inn hotels  generally does not allow pets. For specific requests or exceptions, please contact the hotel directly before booking."
      },
      {
        question: "What is the cancellation policy at Grand Legacy inn hotels ?",
        answer: "The cancellation policy may vary depending on the rate or booking plan you choose. It is advisable to review the specific terms and conditions at the time of booking or contact the hotel for detailed information about their cancellation policy."
      },
      {
       question: "How can I make a reservation at Grand Legacy inn hotels ?",
      answer: "Reservations can be made directly through our website, by phone at [+91 7058638153], or by email at [reservation@grandlegacyinnhotels.coms].",
        
      },
      {
        question: "Are there any special offers or packages available at Grand Legacy inn hotels ?",
        answer: "Grand Legacy inn hotels  frequently offers special packages and promotional rates. Check their official website or contact the hotel directly for information on current offers and deals."
      },
    ];

    const testimonials = [
        {
          quote: "I recently stayed at Grand Legacy inn hotels  during a business trip to Pune, and I couldn't have asked for a better experience. The hotel's location near Kharadi was incredibly convenient, especially with EON IT Park just a short drive away. The rooms were elegantly furnished and very comfortable, providing the perfect retreat after a busy day. The in-house restaurant offered a fantastic selection of dishes, and the service was top-notch. The staff were attentive and made sure all my needs were met. I highly recommend Grand Legacy inn hotels  for anyone needing a comfortable stay in the Kharadi area.",
          author: "- Mr. Rohan Sharma"
        },
        {
          quote: "My stay at Grand Legacy inn hotels  was simply wonderful. The hotel's proximity to Kharadi's shopping and dining options made it a perfect choice for both business and leisure. The room I stayed in was spacious and well-equipped, and the amenities, including the fitness center and spa, added a touch of luxury to my stay. The staff were incredibly friendly and helpful, making me feel right at home. The airport transfer service was an added convenience, making my arrival and departure seamless. If you're looking for a top-quality hotel near Kharadi, this is the place to stay!",
          author: "- Mrs. Priya Deshmukh"
        },
      ];

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
      };
    
      const hotelImages = [
        {
          place: "Hotel-Images/Hotel In Viman Nagar.jpg",
          text: "Hotel In Viman Nagar",
          path:'hotel-in-viman-nagar'
        },
        {
          place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
          text: "Hotels in Viman Nagar for Stay",
          path:'viman-nagar-hotels-for-stay'
        },
        {
          place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
          text: "Lodge in Viman Nagar Pune",
          path:'lodge-in-viman-nagar-pune'
        },
        {
          place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
          text: "Hotels Near Viman Nagar Airport Pune",
          path:'hotels-near-viman-nagar-airport-pune'
        },
        {
          place: "Hotel-Images/Pune Airport Hotel.jpg",
          text: "Pune Airport Hotel",
          path:'pune-airport-hotel'
        },
        {
          place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
          text: "Hotel in Wadgaon Sheri",
          path:'hotel-wadgaon-sheri'
        },
        {
          place: "Hotel-Images/Online hotel booking in pune.jpg",
          text: "Online Hotel Booking in Pune",
          path:'online-hotel-booking-pune'
        },
        {
          place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
          text: "Hotel For Stay in Lohegaon Airport",
          path:'hotel-for-stay-lohegaon-airport'
        }
    
      
      ];
    
      const schemaData = {
        "@context": "https://schema.org",
        "@type": "Hotel",
        "name": "Grand Legacy inn hotels ",
        "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
          "addressLocality": "Pune,Viman Nagar",
          "addressRegion": "MH",
          "postalCode": "411014",
          "addressCountry": "IN"
        },
        "telephone": "+91 7058638153",
        "image": "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
        "starRating": {
          "@type": "Rating",
          "bestRating": "5",
          "ratingValue": "3",
          "worstRating": "1"
        },
       
        "sameAs": [
          "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
          "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
          "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
          "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
          "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
          "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
          "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
          "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
          "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
          "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
          "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
          "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
        ]
      };
    
    return(
        <>
  
          <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
            <script type="application/ld+json">
               {JSON.stringify(schemaData)}
            </script>
  
        <title>
            Contact +91 7058638153 | Grand Legacy inn hotels  | Hotels Near Kharadi: EON IT Park, Zensar, Bypass
        </title>
        <meta name="description" content="Discover the best hotels near Kharadi with Grand Legacy inn hotels . We offer accommodations close to EON IT Park, Zensar, and the Kharadi bypass. Book your stay at affordable rates. Contact +91 7058638153 for reservations." />
        <meta name="keywords" content="Hotels Near Kharadi, Hotels near Kharadi EON IT Park, Hotels near Zensar Kharadi, Hotels near Kharadi Bypass, Hotels near EON IT Park Kharadi, Lodge near Kharadi Bypass, Hotels near Kharadi, Hotel in Kharadi, Kharadi hotels for stay, Hotel EON Inn Kharadi, Hotel in Kharadi Bypass, Hotels in Kharadi Bypass, Kharadi hotel booking" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Grand Legacy inn hotels  - Contact +91 7058638153 | Hotels Near Kharadi: EON IT Park, Zensar, Bypass" />
        <meta property="og:description" content="Looking for hotels near Kharadi? Grand Legacy inn hotels  provides comfortable stays close to EON IT Park, Zensar, and the Kharadi bypass. Affordable rates and excellent service. Call +91 7058638153 to book now." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotels near Kharadi.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/hotels-in-kharadi" />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Hotels Near Kharadi</h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Hotels Near Kharadi
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Hotels near Kharadi.jpg" className="img-fluid" alt="Hotels near Kharadi" />
              </div>
              <h2>Discover the Best Hotels Near Kharadi</h2>
              <p>
                Are you planning a trip to Pune and looking for a comfortable
                and convenient place to stay? Kharadi, a bustling suburb in
                Pune, is an ideal location due to its proximity to major IT
                hubs, shopping centers, and cultural attractions. Whether you're
                in town for business or leisure, finding the right hotel near
                Kharadi can significantly enhance your experience. In this
                guide, we’ll explore the top hotels near Kharadi, including the
                renowned Grand Legacy inn hotels , to ensure your stay is both
                pleasant and memorable.
              </p>

              <h2>Hotels Near Kharadi</h2>
              <p>
                Kharadi is a prominent suburb in Pune, known for its thriving IT
                industry and vibrant community. Staying in hotels near Kharadi
                offers easy access to shopping, dining, and local attractions.
                Here are some top choices:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels : A premier choice with elegant rooms,
                  modern amenities, and a prime location near Kharadi.
                </li>
              </ul>

              <h2>Hotels Near EON IT Park Kharadi</h2>
              <p>
                EON IT Park is a major business hub, attracting professionals
                from various sectors. If you’re working in or visiting this
                area, consider staying in nearby hotels that offer easy access
                to the park:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels : Located just a short drive away, this
                  hotel offers convenience and comfort with excellent amenities.
                </li>
              </ul>

              <h2>Hotels Near Zensar IT Park Kharadi</h2>
              <p>
                Zensar IT Park is another key business location in Kharadi. Here
                are some nearby hotels that provide excellent accommodation for
                business travelers:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels : Enjoy upscale accommodations and
                  business-friendly services close to Zensar IT Park.
                </li>
              </ul>

              <h2>Hotels Near Kharadi Bypass</h2>
              <p>
                The Kharadi Bypass is a crucial roadway, providing access to
                various parts of Pune. Staying at hotels near this bypass can
                offer both convenience and accessibility:
              </p>
              <ul>
                <li>
                  Lodge Near Kharadi Bypass: For a more budget-friendly option,
                  consider local lodges that provide essential amenities and a
                  strategic location.
                </li>
                <li>
                  Holiday Inn Pune: Located near the Kharadi Bypass, this hotel
                  offers comfortable rooms and excellent service.
                </li>
              </ul>

              <h2>Hotels Near EON IT Park Kharadi</h2>
              <p>
                EON IT Park is a central business district in Kharadi. Hotels in
                the vicinity of this park offer quick access for business
                travelers and those attending events:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels : A top pick for its close proximity to
                  EON IT Park and comprehensive amenities.
                </li>
              </ul>

              <h2>Hotels Near Zensar Kharadi</h2>
              <p>
                Zensar IT Park is a significant business location in Kharadi.
                Hotels near Zensar offer convenience for those visiting the IT
                park:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels : Enjoy modern accommodations and
                  proximity to Zensar IT Park.
                </li>
              </ul>

              <h2>Top Hotels in Kharadi</h2>
              <p>
                Kharadi is a thriving area with a range of hotels to suit
                different preferences and budgets. Here are some top choices for
                a comfortable stay:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels : This hotel is a top pick for its
                  modern amenities, elegant rooms, and excellent service.
                  Located in the heart of Kharadi, it provides easy access to
                  local attractions and business hubs.
                </li>
              </ul>

              <h2>Kharadi Hotels for Stay</h2>
              <p>
                When looking for Kharadi hotels for stay, consider your needs
                and preferences. Whether you are traveling for business or
                leisure, there’s a hotel in Kharadi that fits your requirements:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels  : Ideal for those seeking a blend of
                  comfort and convenience. This hotel offers a range of
                  amenities and is centrally located in Kharadi.
                </li>
              </ul>

              <h2>Hotel Eon Inn Kharadi</h2>
              <p>
                If you're specifically interested in Hotel Eon Inn Kharadi,
                here's what you need to know:
              </p>
              <ul>
                <li>
                  Hotel Eon Inn: Located near the EON IT Park, this hotel is a
                  great choice for business travelers. It offers comfortable
                  rooms, a range of facilities, and easy access to the IT hub.
                </li>
                <li>
                  Features: The hotel provides amenities such as high-speed
                  internet, business services, and a restaurant, ensuring a
                  pleasant stay for professionals.
                </li>
              </ul>

              <h2>Hotel in Kharadi Bypass</h2>
              <p>
                For those looking for a hotel in the Kharadi Bypass area, you
                have several options:
              </p>
              <ul>
                <li>
                  Holiday Inn Pune: Situated close to the Kharadi Bypass, this
                  hotel offers a range of amenities including a swimming pool,
                  fitness center, and business facilities.
                </li>
              </ul>

              <h2>Lodge Near Kharadi Bypass</h2>
              <p>
                For a more budget-friendly option, consider local lodges that
                provide essential amenities and are conveniently located near
                the bypass.
              </p>

              <h2>Hotels in Kharadi Bypass</h2>
              <p>
                Hotels in the Kharadi Bypass area offer easy access to major
                roadways and various parts of Pune:
              </p>
              <ul>
                <li>
                  Grand Legacy inn hotels : A short distance from the Kharadi
                  Bypass, this hotel combines comfort and convenience, making it
                  an excellent choice for travelers.
                </li>
              </ul>

              <h2>Kharadi Hotel Booking: Tips and Options</h2>
              <p>
                When booking a hotel in Kharadi, keep these tips in mind to
                ensure a smooth and enjoyable stay:
              </p>
              <ul>
                <li>
                  Book Early: To secure the best rates and availability,
                  especially during peak seasons or business events, make your
                  reservations in advance.
                </li>
                <li>
                  Check Reviews: Look for guest reviews and ratings to gauge the
                  quality of service and amenities at the hotel.
                </li>
              </ul>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p> - Hotels Near Kharadi</p>
                      <p> - Hotels Near Kharadi EON IT Park</p>
                      <p> - Hotels Near Zensar Kharadi</p>
                      <p> - Hotels Near Kharadi Bypass</p>
                      <p> - Hotels Near EON IT Park Kharadi</p>
                      <p> - Lodge Near Kharadi Bypass</p>
                      <p> - Hotels Near Kharadi</p>
                      <p> - Hotels Near Zensar Kharadi</p>
                      <p> - Hotel in Kharadi</p>
                      <p> - Kharadi Hotels for Stay</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p> - Hotel EON Inn Kharadi</p>
                      <p> - Hotel in Kharadi Bypass</p>
                      <p> - Hotels in Kharadi Bypass</p>
                      <p> - Kharadi Hotel Booking</p>
                      <p> - Hotels Near Zensar Kharadi</p>
                      <p> - Hotel in Kharadi</p>
                      <p> - Kharadi Hotels for Stay</p>
                      <p> - Hotel EON Inn Kharadi</p>
                      <p> - Hotel in Kharadi Bypass</p>
                      <p> - Hotels in Kharadi Bypass</p>
                      <p> - Kharadi Hotel Booking</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>
                Why Choose Grand Legacy inn hotels   for Hotels Near Kharadi?
              </h2>

              <p>
                When searching for the perfect hotel near Kharadi, Grand
                Legacy Inn Hotels stands out as a premier choice. Here’s why this hotel
                is an excellent option for your stay:
              </p>

              <h3>1. Prime Location</h3>
              <ul>
                <li>
                  <strong>Convenient Access to Key Business Hubs:</strong>{" "}
                  Located in the heart of Kharadi, Grand Legacy inn hotels   is
                  strategically positioned close to major business centers such
                  as EON IT Park and Zensar IT Park. This prime location makes
                  it an ideal choice for business travelers who need to stay
                  near their workplace.
                </li>
                <li>
                  <strong>Proximity to Attractions:</strong> Beyond business,
                  Kharadi offers a vibrant array of shopping, dining, and
                  entertainment options. The hotel’s location ensures that
                  guests can easily explore local attractions and experience the
                  best of Pune’s offerings.
                </li>
              </ul>

              <h3>2. Modern and Comfortable Accommodations</h3>
              <ul>
                <li>
                  <strong>Elegant Rooms:</strong> The hotel features a variety
                  of room types, including Deluxe Rooms, Executive Rooms, and
                  Suites. Each room is designed with modern décor, comfortable
                  furnishings, and essential amenities to ensure a restful stay.
                </li>
                <li>
                  <strong>Luxurious Suites:</strong> For those seeking extra
                  comfort, the suites offer additional space, a separate living
                  area, and premium amenities, making them perfect for extended
                  stays or special occasions.
                </li>
              </ul>

              <h3>3. Exceptional Amenities</h3>
              <ul>
                <li>
                  <strong>Dining Options:</strong> Guests can enjoy a range of
                  culinary delights at the hotel’s in-house restaurant. Whether
                  you’re in the mood for local specialties or international
                  cuisine, the restaurant caters to diverse tastes.
                </li>
                <li>
                  <strong>Fitness and Wellness:</strong> The hotel boasts a
                  well-equipped fitness center and spa services, allowing you to
                  maintain your workout routine or unwind with a relaxing
                  treatment after a busy day.
                </li>
                <li>
                  <strong>Business Facilities:</strong> For corporate guests,
                  Grand Legacy inn hotels   provides fully-equipped meeting rooms
                  and conference facilities. High-speed Wi-Fi, audiovisual
                  equipment, and professional services are available to support
                  your business needs.
                </li>
              </ul>

              <h3>4. Outstanding Service</h3>
              <ul>
                <li>
                  <strong>Personalized Experience:</strong> The hotel is known
                  for its attentive and personalized service. The staff is
                  dedicated to ensuring that your stay is comfortable and
                  memorable, catering to both your personal and professional
                  needs.
                </li>
                <li>
                  <strong>Airport Transfers:</strong> Convenience is key, and
                  the hotel offers airport transfer services to streamline your
                  travel experience. Whether you’re arriving or departing, you
                  can arrange for pick-up and drop-off directly with the hotel.
                </li>
              </ul>

              <h3>5. Affordable Luxury</h3>
              <ul>
                <li>
                  <strong>Value for Money:</strong> Despite offering luxurious
                  amenities and a prime location, Grand Legacy inn hotels  
                  provides excellent value for money. With competitive rates and
                  various packages, you can enjoy a high standard of comfort
                  without breaking the bank.
                </li>
                <li>
                  <strong>Special Offers:</strong> The hotel frequently features
                  special promotions and packages. By booking directly or
                  checking their website, you can take advantage of exclusive
                  deals that enhance your stay.
                </li>
              </ul>

              <h3>6. Easy Accessibility</h3>
              <ul>
                <li>
                  <strong>Public Transportation:</strong> The hotel is
                  well-connected to public transportation options, including
                  taxis, auto-rickshaws, and buses. This makes it easy to
                  navigate the city and access other parts of Pune.
                </li>
                <li>
                  <strong>Parking Facilities:</strong> Complimentary parking is
                  available for guests, providing added convenience for those
                  traveling by car.
                </li>
              </ul>

              <h3>7. Positive Guest Reviews</h3>
              <ul>
                <li>
                  <strong>Highly Rated:</strong> Grand Legacy inn hotels 
                  consistently receives positive reviews from guests who
                  appreciate its quality, service, and location. Positive
                  feedback highlights the hotel’s commitment to providing an
                  excellent guest experience.
                </li>
              </ul>

              <div className="">
                  <h2>
                    FAQs for Hotel Near Kharadi, Pune - Grand Legacy inn hotels 
                  </h2>
                  {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className={`faq-question ${
                          activeIndex === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {index + 1}. {faq.question}
                      </div>
                      {activeIndex === index && (
                        <div className="faq-answer">{faq.answer}</div>
                      )}
                    </div>
                  ))}
                </div>


                  {/* ======================== */}
                  <div className="">
                  <h2>
                    Testimonials for Hotel in Viman Nagar Pune - Grand
                    Legacy Inn Hotels: -{" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                {/* ================= */}

                <div className=" contact-section">
                  <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                  <p>
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong>Mobile No:</strong>{" "}
                    <a href="tel:+917058638153">+91 7058638153</a>
                  </p>
                  <p>
                    <strong>Email Id:</strong>{" "}
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                     reservation@grandlegacyinnhotels.com
                    </a>
                  </p>
                </div>
            </div>
            <div className="col-12 col-md-4">
            <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
