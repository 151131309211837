import React, { useState } from "react";
import "./App.css";
import Slider from "react-slick";
// import { Helmet } from 'react-helmet';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";

export default function HotelInKalyaninagr() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    const faqs = [
      {
        question: "What types of rooms are available at Grand Legacy inn hotels  in Kalyani Nagar?",
        answer: "Grand Legacy inn hotels  offers a variety of room types to cater to different needs, including Standard Rooms, Deluxe Rooms, and Suites. Each room is designed with modern decor and equipped with amenities such as high-speed internet, air conditioning, flat-screen TVs, and comfortable bedding to ensure a pleasant stay."
      },
      {
        question: "Is Grand Legacy inn hotels  located near any popular attractions in Kalyani Nagar?",
        answer: "Yes, Grand Legacy inn hotels  is centrally located in Kalyani Nagar, giving you easy access to popular attractions and landmarks. Nearby attractions include local parks, shopping centers, dining options, and cultural sites. The hotel’s prime location makes it convenient for both leisure and business travelers."
      },
      {
        question: "What dining options are available at Grand Legacy inn hotels ?",
        answer: "The hotel features an in-house restaurant that offers a diverse menu, including local and international cuisines. Guests can enjoy a variety of dishes for breakfast, lunch, and dinner. Additionally, room service is available for guests who prefer to dine in the comfort of their rooms."
      },
      {
        question: "Does Grand Legacy inn hotels  offer amenities for business travelers?",
        answer: "Yes, Grand Legacy inn hotels  is equipped to cater to business travelers. The hotel provides high-speed internet access, meeting and conference facilities, and a business center. The staff is also available to assist with any additional business needs you may have during your stay."
      },
      {
        question: "Are there any special packages or offers available at Grand Legacy inn hotels ?",
        answer: "Grand Legacy inn hotels  frequently offers special packages and promotions. These may include discounts for extended stays, seasonal offers, or packages for special occasions. For the latest offers and to book a package, it’s best to check the hotel’s official website or contact the reservations team directly."
      },
      {
        question: "Is Grand Legacy inn hotels  a good choice for family stays?",
        answer: "Absolutely. Grand Legacy inn hotels  is a family-friendly hotel with spacious rooms and amenities designed to accommodate families. The hotel offers services such as extra beds upon request and kid-friendly menu options in the restaurant. The location in Kalyani Nagar also provides easy access to family-friendly activities and attractions."
      },
      {
        question: "What measures does Grand Legacy inn hotels  take to ensure guest safety and cleanliness?",
        answer: "The hotel prioritizes guest safety and cleanliness by following stringent hygiene protocols. This includes regular cleaning and sanitizing of rooms and common areas, maintaining high standards of cleanliness, and implementing safety measures to ensure a safe environment for all guests."
      },
      {
        question: "Does Grand Legacy inn hotels  offer parking facilities?",
        answer: "Yes, Grand Legacy inn hotels  provides on-site parking facilities for guests. There is ample space for both cars and other vehicles, ensuring that you have a convenient and secure place to park during your stay."
      },
      {
        question: "Can guests request special services or amenities at Grand Legacy inn hotels ?",
        answer: "Yes, guests can request special services and amenities at Grand Legacy inn hotels . Whether you need assistance with arranging special events, requiring extra amenities in your room, or have specific dietary preferences, the hotel’s staff is dedicated to accommodating your needs and ensuring a personalized experience."
      },
      {
        question: "What is the check-in and check-out policy at Grand Legacy inn hotels ?",
        answer: "The standard check-in time at Grand Legacy inn hotels  is 12:00 PM, and check-out time is 11:00 AM. If you require an early check-in or late check-out, please inform us in advance, and we will do our best to accommodate your request based on room availability. Additional charges may apply for extended stays or early arrivals.",
      }
    ];

    const testimonials = [
        {
          quote: "I recently stayed at Grand Legacy inn hotels  while visiting Pune, and I couldn’t have asked for a better experience. The location in Kalyani Nagar is fantastic—close to all the local attractions and dining options. The room was impeccably clean and comfortable, with all the modern amenities I needed. What truly stood out was the exceptional service from the staff. They were friendly, attentive, and went above and beyond to make my stay enjoyable. I highly recommend Grand Legacy inn hotels  for anyone looking for a comfortable and convenient stay in Pune!",
          author: "- Mrs. Priya Sharma"
        },
        {
          quote: "Grand Legacy inn hotels  exceeded my expectations during my recent business trip to Pune. The hotel’s location in Kalyani Nagar is perfect, offering easy access to business districts and local amenities. The room was well-appointed and provided a peaceful retreat after a busy day. I was particularly impressed by the hotel’s business facilities and the helpfulness of the staff. They made sure I had everything I needed for a productive stay. If you’re looking for a great place to stay in Kalyani Nagar, Grand Legacy inn hotels  is the way to go.",
          author: "— Mr. Raj Patel"
        }
      ];

      
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const hotelImages = [
    {
      place: "Hotel-Images/Hotel In Viman Nagar.jpg",
      text: "Hotel In Viman Nagar",
      path:'hotel-in-viman-nagar'
    },
    {
      place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
      text: "Hotels in Viman Nagar for Stay",
      path:'viman-nagar-hotels-for-stay'
    },
    {
      place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
      text: "Lodge in Viman Nagar Pune",
      path:'lodge-in-viman-nagar-pune'
    },
    {
      place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
      text: "Hotels Near Viman Nagar Airport Pune",
      path:'hotels-near-viman-nagar-airport-pune'
    },
    {
      place: "Hotel-Images/Pune Airport Hotel.jpg",
      text: "Pune Airport Hotel",
      path:'pune-airport-hotel'
    },
    {
      place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
      text: "Hotel in Wadgaon Sheri",
      path:'hotel-wadgaon-sheri'
    },
    {
      place: "Hotel-Images/Online hotel booking in pune.jpg",
      text: "Online Hotel Booking in Pune",
      path:'online-hotel-booking-pune'
    },
    {
      place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
      text: "Hotel For Stay in Lohegaon Airport",
      path:'hotel-for-stay-lohegaon-airport'
    }

  
  ];


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    "name": "Grand Legacy inn hotels ",
    "description": "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      "addressLocality": "Pune,Viman Nagar",
      "addressRegion": "MH",
      "postalCode": "411014",
      "addressCountry": "IN"
    },
    "telephone": "+91 7058638153",
    "image":"https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    "starRating": {
      "@type": "Rating",
      "bestRating": "5",
      "ratingValue": "3",
      "worstRating": "1"
    },
   
    "sameAs": [
       "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
          "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
          "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
          "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
          "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
          "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
          "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
          "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
          "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
          "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
          "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
          "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
          "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
          "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
          "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
          "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
          "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ]
  };

return(
    <>
    
    
 
     <HelmetProvider>
        <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">
      {JSON.stringify(schemaData)}
    </script>
        <title> Contact:+91 7058638153|Grand Legacy inn hotels | Hotels in Kalyani Nagar | Hotel in Viman Nagar |Hotels in Viman Nagar for Stay
        </title>
        <meta name="description" content="Grand Legacy inn hotels  is a standout choice among hotels in Kalyani Nagar, offering exceptional comfort, prime location, and top-notch amenities." />
        <meta name="keywords" content="Hotels in Kalyani Nagar | Hotel in Viman Nagar |Hotels in Viman Nagar for Stay" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Hotels in Kalyani Nagar - Contact:+91 7058638153|Grand Legacy inn hotels " />
        <meta property="og:description" content="This is a detailed description of the page content for SEO purposes." />
        <meta property="og:image" content="https://www.grandlegacyinnhotels.com/Hotel-Images/Hotels in Kalyani Nagar.jpg" />
        <meta property="og:url" content="https://www.grandlegacyinnhotels.com/hotel-in-kalyni-nagar" />
        <meta property="og:type" content="website" />
      </Helmet>
      
     
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner" 
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1> Hotels in Kalyani Nagar </h1>
                <div class="page__banner-title-menu">
                  <ul>
                  <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Hotels in Kalyani Nagar
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}

      <div class="accommodations__area section-padding keywordsection">
        <div className="container">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="./Hotel-Images/Hotels in Kalyani Nagar.jpg" className="img-fluid" alt="Hotels in Kalyani Nagar" />
              </div>
              <h2>
                Discover the Best Hotels in Kalyani Nagar: Experience Comfort at
                Grand Legacy inn hotels 
              </h2>
              <p>
                When visiting Pune, finding the right place to stay can
                significantly enhance your travel experience. Grand Legacy inn hotels 
               is a standout choice among hotels in Kalyani Nagar, offering
                exceptional comfort, prime location, and top-notch amenities.
                Here’s why Grand Legacy inn hotels  is the perfect choice for your
                stay in this vibrant Pune neighborhood.
              </p>

              <h2>
                Grand Legacy inn hotels : Your Ideal Choice Among Hotels in
                Kalyani Nagar
              </h2>
              <p>
                Grand Legacy inn hotels  is a premier hotel in Kalyani Nagar,
                offering a superior blend of luxury, comfort, and convenience.
                Here’s what makes it stand out:
              </p>

              <ul>
                <li>
                  <h3>Elegant Accommodations</h3>
                  <ul>
                    <li>
                      <strong>Stylish Rooms and Suites:</strong> Grand
                      Legacy Inn Hotels features a range of elegantly designed rooms
                      and suites, each thoughtfully furnished to provide maximum
                      comfort and relaxation. Guests can choose from
                      well-appointed rooms that offer modern amenities and a
                      serene ambiance, ideal for both short and extended stays.
                    </li>
                    <li>
                      <strong>Comfort and Convenience:</strong> Every room is
                      equipped with plush bedding, high-speed internet, and
                      essential amenities to ensure a pleasant stay. The hotel's
                      attention to detail ensures that all your needs are met.
                    </li>
                  </ul>
                </li>

                <li>
                  <h3>Prime Location in Kalyani Nagar</h3>
                  <ul>
                    <li>
                      <strong>Strategic Position:</strong> Located in the heart
                      of Kalyani Nagar, Grand Legacy inn hotels  provides easy
                      access to key destinations within Pune. Whether you’re
                      visiting for business or pleasure, the hotel's central
                      location offers convenience and connectivity.
                    </li>
                    <li>
                      <strong>Nearby Attractions:</strong> Explore nearby
                      attractions, such as local parks, shopping centers, and
                      dining options. The hotel’s proximity to major landmarks
                      makes it a great base for exploring the best of Pune.
                    </li>
                  </ul>
                </li>

                <li>
                  <h3>Exceptional Dining Experience</h3>
                  <ul>
                    <li>
                      <strong>In-House Restaurant:</strong> Enjoy delectable
                      meals at the hotel’s in-house restaurant, which serves a
                      variety of local and international cuisines. Whether
                      you’re starting your day with a hearty breakfast or
                      enjoying a leisurely dinner, the restaurant provides a
                      delightful dining experience.
                    </li>
                    <li>
                      <strong>Room Service:</strong> For added convenience, the
                      hotel offers room service, allowing you to enjoy delicious
                      meals in the comfort of your room.
                    </li>
                  </ul>
                </li>

                <li>
                  <h3>Top-Notch Amenities</h3>
                  <ul>
                    <li>
                      <strong>Fitness Center:</strong> Maintain your fitness
                      routine with access to the hotel’s well-equipped fitness
                      center. The gym provides state-of-the-art equipment for a
                      satisfying workout.
                    </li>
                    <li>
                      <strong>Business Facilities:</strong> For business
                      travelers, the hotel offers comprehensive business
                      facilities, including meeting rooms and high-speed
                      internet, ensuring that your work needs are met
                      efficiently.
                    </li>
                  </ul>
                </li>

                <li>
                  <h3>Outstanding Service</h3>
                  <ul>
                    <li>
                      <strong>Personalized Attention:</strong> Grand
                      Legacy Inn Hotels is renowned for its exceptional service. The
                      attentive staff are committed to ensuring that your stay
                      is as comfortable and enjoyable as possible. From
                      personalized service to prompt assistance, the hotel’s
                      team is dedicated to exceeding your expectations.
                    </li>
                    <li>
                      <strong>Special Requests:</strong> Whether you have
                      special requests or need assistance with travel
                      arrangements, the hotel’s staff are always ready to help.
                    </li>
                  </ul>
                </li>

                <li>
                  <h3>Affordable Luxury</h3>
                  <ul>
                    <li>
                      <strong>Competitive Rates:</strong> Despite offering
                      luxury accommodations and top-notch amenities, Grand
                      Legacy Inn Hotels provides competitive pricing, offering great
                      value for money.
                    </li>
                    <li>
                      <strong>Promotional Offers:</strong> Keep an eye out for
                      special promotions and packages that offer additional
                      value and enhance your stay.
                    </li>
                  </ul>
                </li>
              </ul>

              <h2>Cheap Hotels in Kalyani Nagar Near Pune</h2>
              <p>
                For travelers seeking affordable yet high-quality
                accommodations, Grand Legacy inn hotels  stands out among cheap
                hotels in Kalyani Nagar near Pune. The hotel offers competitive
                pricing without compromising on comfort or service.
              </p>
              <ul>
                <li>
                  <strong>Affordable Rates:</strong> Enjoy economical pricing
                  with great value for money.
                </li>
                <li>
                  <strong>Quality Amenities:</strong> Despite the low rates, the
                  hotel provides comfortable rooms and essential amenities,
                  including free Wi-Fi and a well-equipped fitness center.
                </li>
                <li>
                  <strong>Convenient Location:</strong> Centrally located in
                  Kalyani Nagar, making it easy to explore Pune’s attractions
                  without breaking the bank.
                </li>
              </ul>

              <h2>Accommodation in Kalyani Nagar</h2>
              <p>
                Grand Legacy inn hotels  offers exceptional accommodation in
                Kalyani Nagar, Pune, blending modern comfort with convenience.
              </p>
              <ul>
                <li>
                  <strong>Comfortable Rooms:</strong> Experience well-furnished
                  rooms with contemporary decor, designed to provide a relaxing
                  environment.
                </li>
                <li>
                  <strong>Convenient Amenities:</strong> Enjoy amenities such as
                  high-speed internet, an in-house restaurant, and 24-hour room
                  service.
                </li>
                <li>
                  <strong>Central Location:</strong> The hotel’s prime location
                  in Kalyani Nagar offers easy access to local attractions,
                  shopping areas, and dining options.
                </li>
              </ul>

              <h2>Lodge in Kalyani Nagar Pune</h2>
              <p>
                If you’re looking for a lodge in Kalyani Nagar, Pune,
                Grand Legacy Inn Hotels provides a superior option. The hotel combines
                the warmth and comfort of a lodge with the amenities of a modern
                hotel.
              </p>
              <ul>
                <li>
                  <strong>Home-Like Comfort:</strong> Enjoy the cozy and
                  inviting atmosphere of a lodge with the added benefits of
                  hotel services.
                </li>
                <li>
                  <strong>Personalized Service:</strong> Friendly staff are
                  dedicated to making your stay enjoyable and hassle-free.
                </li>
                <li>
                  <strong>Prime Location:</strong> Located in the heart of
                  Kalyani Nagar, you’ll be close to key attractions and
                  amenities.
                </li>
              </ul>

              <h2>Hotel for Couples in Kalyani Nagar</h2>
              <p>
                Grand Legacy inn hotels  is an excellent choice for couples
                looking for a romantic getaway in Kalyani Nagar.
              </p>
              <ul>
                <li>
                  <strong>Romantic Atmosphere:</strong> The hotel offers
                  elegantly designed rooms that create a romantic ambiance for
                  couples.
                </li>
                <li>
                  <strong>Special Amenities:</strong> Enjoy special services
                  like room service for intimate dining, and the option to book
                  packages for special occasions.
                </li>
                <li>
                  <strong>Convenient Location:</strong> Explore the romantic
                  spots in and around Kalyani Nagar, including cafes, parks, and
                  local attractions.
                </li>
              </ul>

              <h2>Hotel for Family in Kalyani Nagar</h2>
              <p>
                For families visiting Pune, Grand Legacy inn hotels  provides
                comfortable and convenient accommodations tailored to meet the
                needs of family travelers.
              </p>
              <ul>
                <li>
                  <strong>Family-Friendly Rooms:</strong> Spacious rooms with
                  extra beds or suites suitable for families.
                </li>
                <li>
                  <strong>Amenities for Families:</strong> Enjoy amenities like
                  a kid-friendly menu in the restaurant, and easy access to
                  local attractions.
                </li>
                <li>
                  <strong>Central Location:</strong> The hotel’s location in
                  Kalyani Nagar offers proximity to parks and family-friendly
                  destinations.
                </li>
              </ul>

              <h2>Grand Legacy inn hotels  in Pune Contact Number : </h2>
              <p>
                Contact Grand Legacy inn hotels  at +91 7058638153. Our hotel is
                strategically located in the heart of Viman Nagar, one of Pune's
                most vibrant and accessible neighborhoods.
              </p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Best hotels in Kalyani Nagar, Pune</p>
                      <p>- Cheap Hotels in Kalyani Nagar Near Pune</p>
                      <p>- Accommodation in Kalyani Nagar</p>
                      <p>- Lodge in Kalyani Nagar Pune</p>
                      <p>- Hotel for couples in Kalyani Nagar</p>
                      <p>- Hotel for family in Kalyani Nagar</p>
                    </td>
                    <td style={{ borderWidth: "0px" }}>
                      <p>- Cheap hotels in Kalyani Nagar Pune</p>
                      <p>- Hotels in Kalyani Nagar</p>
                      <p>- Hotel near Kalyani Nagar</p>
                      <p>- Hotels in Kalyaninagar</p>
                      <p>- Kalyani Nagar hotels for stay</p>
                      <p>- Service apartment in Kalyani Nagar</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>
                Why Choose Grand Legacy inn hotels  for Hotels in Kalyani Nagar?
              </h2>
              <p>
                When selecting a hotel in Kalyani Nagar, Pune,Grand
                Legacy Inn Hotels emerges as a top choice due to its combination of
                comfort, convenience, and exceptional service. Here’s why
                Grand Legacy Inn Hotels should be your go-to option when staying in
                this vibrant neighborhood.
              </p>

              <h3>1. Prime Location in Kalyani Nagar</h3>
              <ul>
                <li>
                  <strong>Strategic Position:</strong> Grand Legacy inn hotels  is
                  ideally situated in the heart of Kalyani Nagar, offering easy
                  access to the area’s best attractions, dining options, and
                  shopping centers. Its central location ensures that you are
                  never far from what you need, whether you're here for business
                  or leisure.
                </li>
                <li>
                  <strong>Proximity to Attractions:</strong> The hotel’s
                  location allows guests to explore nearby landmarks and
                  attractions effortlessly, including parks, cultural sites, and
                  local eateries. You’ll find everything you need right at your
                  doorstep.
                </li>
              </ul>

              <h3>2. Exceptional Comfort and Elegance</h3>
              <ul>
                <li>
                  <strong>Stylish Accommodations:</strong> Each room at
                  Grand Legacy Inn Hotels is designed with modern decor and
                  high-quality furnishings, providing a luxurious and
                  comfortable environment. From plush bedding to contemporary
                  design, every detail is crafted for relaxation.
                </li>
                <li>
                  <strong>Top-Notch Amenities:</strong> Enjoy a range of
                  amenities that enhance your stay, including high-speed
                  internet, air conditioning, a well-equipped fitness center,
                  and an on-site restaurant. The hotel is designed to cater to
                  all your needs, ensuring a comfortable and convenient stay.
                </li>
              </ul>

              <h3>3. Outstanding Service</h3>
              <ul>
                <li>
                  <strong>Attentive Staff:</strong> The hotel is renowned for
                  its exceptional customer service. The friendly and
                  professional staff are dedicated to making your stay as
                  enjoyable and stress-free as possible. Whether you need
                  assistance with travel arrangements or special requests, the
                  team is always ready to help.
                </li>
                <li>
                  <strong>Personalized Experience:</strong> Grand Legacy inn hotels 
                   takes pride in offering personalized service, ensuring
                  that every guest’s needs and preferences are met. From
                  arranging special amenities for couples to providing
                  family-friendly services, the hotel aims to create a memorable
                  experience for all.
                </li>
              </ul>

              <h3>4. Affordable Luxury</h3>
              <ul>
                <li>
                  <strong>Competitive Pricing:</strong> Despite offering luxury
                  accommodations and high-quality services, Grand Legacy inn hotels 
                   provides competitive rates, making it an excellent choice
                  for both budget-conscious travelers and those seeking a
                  premium experience.
                </li>
                <li>
                  <strong>Value for Money:</strong> The hotel’s combination of
                  comfort, amenities, and service ensures that you get great
                  value for your money. You can enjoy a high standard of
                  accommodation without exceeding your budget.
                </li>
              </ul>

              <h3>5. Versatile Accommodations</h3>
              <ul>
                <li>
                  <strong>Family-Friendly:</strong> For families, the hotel
                  offers spacious rooms and family-oriented services, ensuring a
                  comfortable stay for guests of all ages. With amenities such
                  as extra beds and kid-friendly dining options, the hotel
                  caters to family needs effectively.
                </li>
                <li>
                  <strong>Couples’ Retreat:</strong> Couples looking for a
                  romantic getaway will appreciate the hotel’s elegant rooms and
                  intimate atmosphere. Special services and packages designed
                  for couples make it an ideal choice for celebrating special
                  occasions.
                </li>
              </ul>

              <h3>6. Convenient Services</h3>
              <ul>
                <li>
                  <strong>In-House Dining:</strong> The hotel’s in-house
                  restaurant offers a diverse menu, featuring local and
                  international cuisine. Whether you’re enjoying a hearty
                  breakfast or a relaxing dinner, the dining experience at
                  Grand Legacy Inn Hotels is sure to satisfy.
                </li>
                <li>
                  <strong>Business Facilities:</strong> For business travelers,
                  the hotel provides comprehensive facilities, including meeting
                  rooms and high-speed internet, ensuring that your work needs
                  are well catered to.
                </li>
              </ul>

              <div className="">
                  <h2>
                    FAQs for Hotel in Viman Nagar, Pune - Grand Legacy inn hotels 
                  </h2>
                  {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className={`faq-question ${
                          activeIndex === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {index + 1}. {faq.question}
                      </div>
                      {activeIndex === index && (
                        <div className="faq-answer">{faq.answer}</div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="container">
                  <h2>
                  Testimonials for Grand Legacy inn hotels  with Hotels in Kalyani Nagar {" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" contact-section">
                  <h2>Hotel in Viman Nagar Pune Contact Number:</h2>
                  <p>
                    For booking inquiries or any assistance, please feel free to
                    contact us:
                  </p>
                  <p>
                    <strong>Mobile No:</strong>{" "}
                    <a href="tel:+917058638153">+91 7058638153</a>
                  </p>
                  <p>
                    <strong>Email Id:</strong>{" "}
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                     reservation@grandlegacyinnhotels.com
                    </a>
                  </p>
                </div>
            </div>
            <div className="col-12 col-md-4">
            <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 7058638153">+91 7058638153</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:reservation@grandlegacyinnhotels.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:reservation@grandlegacyinnhotels.com">
                          reservation@grandlegacyinnhotels.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Grand Legacy Inn hotels
                    Pune Nagar Road, Vimanagar Chowk Opp
                    Phoenix Market city, Pune - 411014
                    </p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </HelmetProvider>
    </>
  );
}
