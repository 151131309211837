import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import About from "./About";
import TermsandCondition from "./TermsandCondition"
import FAQ from "./FAQ";
import RoomStyle from "./RoomStyle";
import BlogStandard from "./BlogStandard";
import Contact from "./Contact";
import "./CSS/all.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/magnific-popup.css";
import "./CSS/meanmenu.min.css";
import "./CSS/nice-select.css";
import "./CSS/swiper-bundle.min.css";
import "./sass/style.css";
import Facilities from "./Facilities";
import Restaurants from "./Restaurants";
import Policy from "./Policy";
import HotelInVimanNagar from "./HotelInVimanNagar";
import HotelVimanNagarStay from "./HotelVimanNagarStay";
import HotelNearVimanNagar from "./HotelNearVimanNagar";
import LodgeInVimanNagar from "./LodgeInVimanNagar";
import HotelsVimanNagarAirport from "./HotelsVimanNagarAirport";
import PuneAirportHotel from "./PuneAirportHotel";
import HotelsNearInternationalPune from "./HotelsNearInternationalPune";
import PuneNearAirportHotel from "./PuneNearAirportHotel";
import PuneAirportHotels from "./PuneAirportHotels";
import HotelForStayInLohagaon from "./HotelForStayInLohagaon";
import HotelNearMePuneAirport from "./HotelNearMePuneAirport";
import HotelNearKharadi from "./HotelNearKharadi";
import HotelInKoregaonPark from "./HotelInKoregaonPark";
import BestHotelsInPuneForCouples from "./BestHotelsInPuneForCouples";
import HotelInKalyaninagr from "./HotelInKalyaniNagar";
import LuxuaryHotelInPuneNagarRoad from "./LuxuryHotelInPuneNagarRoad";
import HotelInWadgaonSheri from "./HotelInWadgaonSheri";
import OnlineHotelBookingInPune from "./OnlineHotelBookingInPune";
import HotelInKharadi from "./HotelInKharadi";
import HotelForBusinessStaysInVimanNagar from "./HotelForBusinessStaysInVimanNagar";
import HotelBookingForCorporateInVimanNagar from "./HotelBookingForCorporateInVimanNagar";
import BookingForm from "./BookingForm";
import ServicesSection from "./ServicesSection";
import TariffPlanCard from "./TariffPlanCard";
import Gallery from "./Gallery";
import AmenitiesSection from "./AmenitiesSection";
import Footer from './Footer';
import Header from "./Header";
import ScrollToTop from "./ScrollToTop";

function App(){
    return(
        <>
        <div>
           
        <Router>
            <ScrollToTop></ScrollToTop>
            <Header/>
             
             <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/room-style" element={<RoomStyle />} />
        <Route path="/blog-standard" element={<BlogStandard />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/facilities" element={<Facilities />}></Route>
        <Route path="/amenities" element={<AmenitiesSection></AmenitiesSection>}></Route>
        <Route path="/restaurants" element={<Restaurants />}></Route>
        <Route path="/policy" element={<Policy />}></Route>
        <Route path="/terms" element={<TermsandCondition></TermsandCondition>}></Route>
        <Route
          path="/hotel-in-viman-nagar"
          element={<HotelInVimanNagar />}
        ></Route>
        <Route
          path="/viman-nagar-hotels-for-stay"
          element={<HotelVimanNagarStay></HotelVimanNagarStay>}
        ></Route>
        <Route
          path="/hotels-near-viman-nagar"
          element={<HotelNearVimanNagar></HotelNearVimanNagar>}
        ></Route>
        <Route
          path="/lodge-in-viman-nagar-pune"
          element={<LodgeInVimanNagar></LodgeInVimanNagar>}
        ></Route>
        <Route
          path="/hotels-near-viman-nagar-airport-pune"
          element={<HotelsVimanNagarAirport></HotelsVimanNagarAirport>}
        ></Route>
        <Route
          path="/pune-airport-hotel"
          element={<PuneAirportHotel></PuneAirportHotel>}
        ></Route>
        <Route
          path="hotels-near-pune-international-airport"
          element={<HotelsNearInternationalPune></HotelsNearInternationalPune>}
        ></Route>
        <Route
          path="/pune-near-airport-hotel"
          element={<PuneNearAirportHotel></PuneNearAirportHotel>}
        ></Route>
        <Route
          path="/pune-airport-hotels"
          element={<PuneAirportHotels></PuneAirportHotels>}
        ></Route>
        <Route
          path="/hotel-for-stay-lohegaon-airport"
          element={<HotelForStayInLohagaon></HotelForStayInLohagaon>}
        ></Route>
        <Route
          path="/hotel-near-me-pune-airport"
          element={<HotelNearMePuneAirport></HotelNearMePuneAirport>}
        ></Route>
        <Route
          path="/hotels-near-kharadi"
          element={<HotelNearKharadi></HotelNearKharadi>}
        ></Route>
        <Route
          path="/hotel-in-koregaon-park"
          element={<HotelInKoregaonPark></HotelInKoregaonPark>}
        ></Route>
        <Route
          path="/best-hotels-in-pune-for-couples"
          element={<BestHotelsInPuneForCouples></BestHotelsInPuneForCouples>}
        ></Route>
        <Route
          path="/hotel-in-kalyni-nagar"
          element={<HotelInKalyaninagr></HotelInKalyaninagr>}
        ></Route>
        <Route
          path="/luxury-hotel-pune-nagar-road"
          element={<LuxuaryHotelInPuneNagarRoad></LuxuaryHotelInPuneNagarRoad>}
        ></Route>
        <Route
          path="/hotel-wadgaon-sheri"
          element={<HotelInWadgaonSheri></HotelInWadgaonSheri>}
        ></Route>
        <Route
          path="/online-hotel-booking-pune"
          element={<OnlineHotelBookingInPune></OnlineHotelBookingInPune>}
        ></Route>
        <Route
          path="/hotels-in-kharadi"
          element={<HotelInKharadi></HotelInKharadi>}
        ></Route>
        <Route
          path="/business-stays-in-viman-nagar"
          element={
            <HotelForBusinessStaysInVimanNagar></HotelForBusinessStaysInVimanNagar>
          }
        ></Route>
        <Route
          path="/corporate-hotel-booking-in-viman-nagar"
          element={
            <HotelBookingForCorporateInVimanNagar></HotelBookingForCorporateInVimanNagar>
          }
        ></Route>

        <Route path="/BookingForm" element={<BookingForm></BookingForm>}></Route>
        <Route path="/service" element={<ServicesSection></ServicesSection>}></Route>
        <Route path="/tariff-plan-card" element={<TariffPlanCard></TariffPlanCard>}></Route>
        <Route path="/gallery" element={<Gallery></Gallery>}></Route>
         <Route path='/amenities' element={<AmenitiesSection></AmenitiesSection>}></Route>
         <Route path='/faq' element={<FAQ></FAQ>}></Route>
      </Routes>
      <Footer/>
             </Router>
        </div>
         
        </>
    )
}

export default App;