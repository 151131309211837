import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
export default function Restaurants() {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Grand Legacy inn hotels ",
    description:
      "Book your stay at Grand Legacy inn hotels  in Viman Nagar, Pune. Enjoy comfortable and luxurious rooms with amenities for both business and leisure travelers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Nanshrinava Somanath Mandir, Vadgaon Sheri, pune-411014",
      addressLocality: "Pune,Viman Nagar",
      addressRegion: "MH",
      postalCode: "411014",
      addressCountry: "IN",
    },
    telephone: "+91 7058638153",
    image: "https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: "3",
      worstRating: "1",
    },

    sameAs: [
      "https://www.grandlegacyinnhotels.com/corporate-hotel-booking-in-viman-nagar",
      "https://www.grandlegacyinnhotels.com/hotels-near-kharadi",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar",
      "https://www.grandlegacyinnhotels.com/lodge-in-viman-nagar-pune",
      "https://www.grandlegacyinnhotels.com/hotels-near-viman-nagar-airport-pune",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Pune International Airport",
      "https://www.grandlegacyinnhotels.com/Pune Near Airport Hotel",
      "https://www.grandlegacyinnhotels.com/Pune Airport Hotels",
      "https://www.grandlegacyinnhotels.com/Hotel in Wadgaon Sheri",
      "https://www.grandlegacyinnhotels.com/Online Hotel Booking in Pune",
      "https://www.grandlegacyinnhotels.com/Hotel For Stay in Lohegaon Airport",
      "https://www.grandlegacyinnhotels.com/Hotel Near Me Pune Airport",
      "https://www.grandlegacyinnhotels.com/Hotels Near Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel in Koregaon Park",
      "https://www.grandlegacyinnhotels.com/Best Hotels in Pune for Couples",
      "https://www.grandlegacyinnhotels.com/Hotels in Kalyani Nagar",
      "https://www.grandlegacyinnhotels.com/Luxury Hotel on Pune Nagar Road",
      "https://www.grandlegacyinnhotels.com/Hotel in Kharadi",
      "https://www.grandlegacyinnhotels.com/Hotel for Business Stays in Viman", 
      "https://www.grandlegacyinnhotels.com/Hotel Booking for Corporate in Viman Nagar"
    ],
  };

  const menuStyle = {
    maxWidth: "500px",
    margin: "50px auto",
    borderRadius: "15px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    backgroundColor: "#fdfdfd",
    padding: "20px",
  };

  const titleStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#2d3436",
    borderBottom: "3px solid var(--green)",
    paddingBottom: "10px",
    textTransform: "uppercase",
  };

  const menuListStyle = {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  };

  const menuItemStyle = {
    padding: "15px",
    fontSize: "18px",
    fontWeight: "500",
    color: "#34495e",
    borderBottom: "1px solid #ecf0f1",
    transition: "background-color 0.3s ease, transform 0.2s ease",
  };

  const menuItemHoverStyle = {
    backgroundColor: "#f7c169",
    cursor: "pointer",
    transform: "scale(1.02)",
  };

  const categoryStyle = {
    fontSize: "22px",
    color: "var(--green)",
    fontWeight: "600",
    marginTop: "20px",
    textTransform: "uppercase",
  };

  const handleMouseOver = (e) => {
    Object.assign(e.target.style, menuItemHoverStyle);
  };

  const handleMouseOut = (e) => {
    Object.assign(e.target.style, {
      backgroundColor: "transparent",
      transform: "scale(1)",
    });
  };

  return (
    <>
      <Helmet> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16781943959"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16781943959');
        `}
      </script>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <title>
          Contact:+91 7058638153 | Grand Legacy inn hotels  |Restaurant | Viman
          Nagar Hotel Booking and Stay Options| Hotel Near Me Pune Airport |
          Hotels Near Kharadi | Hotel in Koregaon Park | Best Hotels in Pune for
          Couples
        </title>
        <meta
          name="description"
          content="Book the best hotels in Viman Nagar, Pune for your stay. Find couple-friendly hotels, business hotels, 3-star and 4-star hotels near Pune International Airport. Call +91 7058638153 for bookings."
        />
        <meta
          name="keywords"
          content="Viman Nagar hotel booking, Viman Nagar hotels for stay, Viman Nagar hotel room, Business hotels in Viman Nagar, Best hotels in Viman Nagar Pune, Couple-friendly hotels in Viman Nagar, 3-star hotels in Viman Nagar, Hotels near Pune International Airport, 3-star hotels near Pune airport, Hotels near Pune domestic airport, 4-star hotels near Pune airport, Best hourly hotels near Pune International Airport"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="+91 7058638153 | Grand Legacy inn hotels  |Restaurant | Viman Nagar Hotel Booking | Hotel Near Me Pune Airport
         Hotels Near Kharadi"
        />
        <meta
          property="og:description"
          content="Book your stay at Grand Legacy inn hotels , one of the best hotels in Viman Nagar. We offer couple-friendly and business hotel rooms, as well as 3-star and 4-star hotel options near Pune Airport. Call +91 7058638153."
        />
        <meta
          property="og:image"
          content="https://www.grandlegacyinnhotels.com/Hotel-Images/OG-Image-02.jpg"
        />
        <meta
          property="og:url"
          content="https://www.grandlegacyinnhotels.com/restaurants"
        />
        <meta property="og:type" content="website" />
        <meta property="contact:phone_number" content="+91 7058638153" />
      </Helmet>
      {/* <!-- Page Banner Start --> */}
      <div
        class="page__banner"
        data-background="./img/banner/page-banner-5.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__banner-title">
                <h1>Restaurants</h1>
                <div class="page__banner-title-menu">
                  <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Restaurants
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Banner End --> */}
      <div className="service-section section-padding">
        <div className="container ">
          <div className="row d-flex justify-content-between menubox">
            <div className="col-12 col-md-6">
              <h3>Breakfast</h3>
              <p>
                We will be so proud to be our guest. Enjoy a delicious breakfast
                filled with nourishing foods and refreshing drinks.
              </p>
              <div style={menuStyle} className="mymenulist">
                <div style={titleStyle}>Our Menu</div>
                <ul style={menuListStyle}>
                  {[
                    "PURI BHAJI",
                    "IDLI",
                    "WADA",
                    "DOSSA",
                    "SHIRA",
                    "UPAMA",
                    "POHA",
                  ].map((item, index) => (
                    <li
                      key={index}
                      style={menuItemStyle}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                src="./Hotel-Images/breakfast food, Lunch & Dinner menu-03.jpg"
                alt="breakfast"
              />
            </div>
          </div>

          {/* ================ */}
          <div className="row d-flex justify-content-between  menubox">
            <div className="col-12 col-md-6 ">
              <img
                src="./Hotel-Images/breakfast food, Lunch & Dinner menu-01.jpg"
                alt="Lunch"
              />
            </div>
            <div className="col-12 col-md-6">
              <h3>Lunch & Dinner</h3>
              <p>
                For lunch, we offer a wide variety of delicious dishes, ranging
                from light salads to hearty sandwiches. For dinner, we invite
                you to savor our creative culinary masterpieces, from seafood to
                steak, and everything in between.
              </p>

              <div style={menuStyle} className="mymenulist">
                <div style={titleStyle}>Our Menu</div>

                {/* Rice Section */}
                <div style={categoryStyle}>Rice Dishes</div>
                <ul style={menuListStyle}>
                  {["PLAIN RICE", "JIRA RICE", "BIRYANI"].map((item, index) => (
                    <li
                      key={index}
                      style={menuItemStyle}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </li>
                  ))}
                </ul>

                {/* Bread Section */}
                <div style={categoryStyle}>Breads</div>
                <ul style={menuListStyle}>
                  {["ROTI", "LACHHA PARATHA"].map((item, index) => (
                    <li
                      key={index}
                      style={menuItemStyle}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </li>
                  ))}
                </ul>

                {/* Vegetarian Section */}
                <div style={categoryStyle}>Vegetarian Dishes</div>
                <ul style={menuListStyle}>
                  {["DAL FRY", "PLAIN DAL", "MIX VEGETABLES", "BHENDI FRY"].map(
                    (item, index) => (
                      <li
                        key={index}
                        style={menuItemStyle}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                      >
                        {item}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>

          {/* ===================== */}
          <div className="row d-flex justify-content-between  menubox">
            <div className="col-12 col-md-6">
              <h3>Lunch & Dinner</h3>
              <p>
                For lunch, we offer a wide variety of delicious dishes. For dinner, we invite
                you to savor our creative culinary masterpieces, from seafood to
                steak, and everything in between.
              </p>
              <div style={menuStyle} className="mymenulist">
              <div style={titleStyle}>Our Menu</div>
                {/* Chicken Section */}
                <div style={categoryStyle}>Chicken Dishes</div>
                <ul style={menuListStyle}>
                  {[
                    "WHITE CHICKEN",
                    "GREEN CHICKEN",
                    "RED CHICKEN",
                    "CHICKEN MASSALA",
                    "CHICKEN CHILLI",
                    "CHICKEN LOLIPOP",
                    "BUTTER CHICKEN",
                    "MUTTON CHIKEN",
                    "MUTTON CHILLI",
                    "MUTTON LOLIPOP",
                     "MUTTON MASSALA"
                  ].map((item, index) => (
                    <li
                      key={index}
                      style={menuItemStyle}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <img
                src="./Hotel-Images/breakfast food, Lunch & Dinner menu-02.jpg"
                alt="Dinner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
