import React from 'react';
import './App.css'
import { Link } from 'react-router-dom';

import { useState } from "react";



const RoomCard = ({ title, price, description, extras, bgColor }) => {
  const [hovered, setHovered] = useState(false);

  const cardStyle = {
    width: '320px',
    borderRadius: '15px',
    overflow: 'hidden',
    boxShadow: hovered ? '0 12px 20px rgba(0, 0, 0, 0.2)' : '0 4px 10px rgba(0, 0, 0, 0.1)',
    background: ` ${bgColor}`,
    color: '#fff',
    margin: '20px auto',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    transform: hovered ? 'scale(1.05)' : 'scale(1)',
    transition: 'all 0.3s ease-in-out',
  };

  const headerStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    padding: '15px 20px',
    fontSize: '26px',
    fontWeight: '600',
  };

  const priceStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '15px 0',
    color: '#fff',
  };

  const descriptionStyle = {
    padding: '15px 20px',
    fontSize: '18px',
    color: '#fff',
  };

  const extraStyle = {
    fontSize: '16px',
    color: '#fff',
    marginTop: '15px',
    padding: '15px 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  };

  const footerStyle = {
    padding: '15px',
    backgroundColor: '#000',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div style={headerStyle}>{title}</div>
      <div style={priceStyle}>₹{price} + Taxes</div>
      <div style={descriptionStyle}>{description}</div>
      <div style={extraStyle}>{extras}</div>
      <div style={footerStyle}>Check-In: 12 PM | Check-Out: 11 AM</div>
    </div>
  );
};

// Main Component to display all three cards
const TariffPlanCard = () => {
  return (
    <>
      {/* Page Banner */}
      <div
        className="page__banner"
        style={{ backgroundImage: "url('./img/banner/page-banner-5.jpg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page__banner-title">
                <h1>Tariff Plans</h1>
                <div className="page__banner-title-menu">
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><span>-</span>Tariff Plans</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tariff Cards */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          flexWrap: 'wrap',
          padding: '40px 0',
        }}
        className="section-padding"
      >
        {/* Executive Room Card */}
        <RoomCard
          title="Executive Room"
          price="2750"
          description="Complimentary First Drink, Breakfast"
          extras="Extra Mattress: ₹1000"
          bgColor="#117193"
        />

        {/* Standard Room Card */}
        <RoomCard
          title="Standard Room"
          price="1800"
          description="Complimentary First Drink, Breakfast"
          extras="Extra Mattress: ₹1000"
          bgColor="#f6c727"
        />

        {/* Suite Room Card */}
        <RoomCard
          title="Suite Room"
          price="3500"
          description="Complimentary First Drink, Breakfast"
          extras="Extra Mattress: ₹1000"
          bgColor="#117193"
        />
      </div>
    </>
  );
};

export default TariffPlanCard;